export const translations = {
  "app_names": {
    "EN": {
      "Home": "Home",
      "secure_chat": "Secure Chat",
      "prompt_drive": "Promptopedia",
      "document_translator": "Document Translator",
      "sentiment_analysis": "MultiPrompter",
      "document_question": "Document Q&A"
    },
    "FR": {
      "Home": "Accueil",
      "secure_chat": "Chat Sécurisé",
      "prompt_drive": "Promptopédie",
      "document_translator": "Traducteur",
      "sentiment_analysis": "MultiPrompter",
      "document_question": "Document Q&R"
    },
    "ES": {
      "Home": "Inicio",
      "secure_chat": "Chat Seguro",
      "prompt_drive": "Promptopedia",
      "document_translator": "Traductor",
      "sentiment_analysis": "MultiPrompter",
      "document_question": "Documentos P&R"
    }
  },
  "home_page": {
    "EN": {
      "welcome_message": "WELCOME",
      "company_description": "European Digital Group is the specialist in digital transformation for businesses. Thanks to our experts, we master every digital lever to offer you tailor-made support and accelerate your growth.",
      "ai_hello": "Hello",
      "ai_help": "! How can I help you today?",
      "placeholder_securechat": "Message Secure Chat...",
      "logout_text": "Log Out",
      "languages_text": "Languages",
      "chat_history": "Chat History"
    },
    "FR": {
      "welcome_message": "BIENVENUE",
      "company_description": "European Digital Group est le spécialiste de la transformation digitale des entreprises. Grâce à nos experts, nous maîtrisons chaque levier du digital pour vous offrir un accompagnement sur-mesure et accélérer votre croissance.",
      "ai_hello": "Bonjour",
      "ai_help": "! Comment puis-je vous aider aujourd'hui ?",
      "placeholder_securechat": "Message Chat Sécurisé...",
      "logout_text": "Déconnexion",
      "languages_text": "Langues",
      "chat_history": "Historique des Chats"
    },
    "ES": {
      "welcome_message": "BIENVENIDO",
      "company_description": "European Digital Group es el especialista en transformación digital para empresas. Gracias a nuestros expertos, dominamos cada palanca digital para ofrecerte un soporte a medida y acelerar tu crecimiento.",
      "ai_hello": "Hola",
      "ai_help": "! ¿Cómo puedo ayudarte hoy?",
      "placeholder_securechat": "Mensaje Chat Seguro...",
      "logout_text": "Cerrar sesión",
      "languages_text": "Idiomas",
      "chat_history": "Historial de Chats"
    }
  },
  "secure_chat": {
    "EN": {
      "help_message": "How can I help you today?",
      "square_prompt_1": "What is the AI?",
      "square_prompt_2": "Help me to compose an email to...",
      "square_prompt_3": "Help me to solve this problem: ...",
      "square_prompt_4": "Provide an explanation for...",
      "model_1": "Mistral",
      "model_2": "Secured GPT3.5",
      "model_3": "Secured GPT4",
      "model_4": "Gemini",
      "options_text": "Options",
      "reset_chat": "Reset Chat",
      "placeholder_securechatInput": "Ask something",
      "modal_context": "Specify Chat GPT context",
      "modal_context_description": "The 'system' context of an LLM (Large Language Model) refers to the initial instructions that guide the model during text generation. Modifying this context can be advantageous to influence the tone, style, and content of the generated text, enabling customization of responses or adaptation of the model for specific tasks.",
      "modal_prompts": "Expand to see optimized prompts",
      "loader_context": "Setting up context...",
      "submit_button": "Submit",
      "close_button": "Close"
    },
    "FR": {
      "help_message": "Comment puis-je vous aider aujourd'hui ?",
      "square_prompt_1": "Qu'est-ce que l'IA ?",
      "square_prompt_2": "Aidez-moi à rédiger un email à...",
      "square_prompt_3": "Aidez-moi à résoudre ce problème : ...",
      "square_prompt_4": "Fournir une explication pour...",
      "model_1": "Mistral",
      "model_2": "GPT3.5 Sécurisé",
      "model_3": "GPT4 Sécurisé",
      "model_4": "Gemini",
      "options_text": "Options",
      "reset_chat": "Réinitialiser le chat",
      "placeholder_securechatInput": "Demandez quelque chose",
      "modal_context": "Spécifier le contexte de Chat GPT",
      "modal_context_description": "Le contexte 'système' d'un LLM (modèle de langage large) fait référence aux instructions initiales qui guident le modèle lors de la génération de texte. Modifier ce contexte peut être avantageux pour influencer le ton, le style et le contenu du texte généré, permettant la personnalisation des réponses ou l'adaptation du modèle à des tâches spécifiques.",
      "loader_context": "Setting up context...",
      "modal_prompts": "Développer pour voir les prompts optimisées",
      "submit_button": "Soumettre",
      "close_button": "Fermer"
    },
    "ES": {
      "help_message": "¿Cómo puedo ayudarte hoy?",
      "square_prompt_1": "¿Qué es la IA?",
      "square_prompt_2": "Ayúdame a redactar un correo electrónico a...",
      "square_prompt_3": "Ayúdame a resolver este problema: ...",
      "square_prompt_4": "Proporcionar una explicación para...",
      "model_1": "Mistral",
      "model_2": "GPT3.5 Seguro",
      "model_3": "GPT4 Seguro",
      "model_4": "Gemini",
      "options_text": "Opciones",
      "reset_chat": "Restablecer chat",
      "placeholder_securechatInput": "Pregunta algo",
      "modal_context": "Especificar el contexto de Chat GPT",
      "modal_context_description": "El contexto 'sistema' de un LLM (modelo de lenguaje grande) se refiere a las instrucciones iniciales que guían al modelo durante la generación de texto. Modificar este contexto puede ser ventajoso para influir en el tono, el estilo y el contenido del texto generado, permitiendo la personalización de las respuestas o la adaptación del modelo para tareas específicas.",
      "loader_context": "Setting up context...",
      "modal_prompts": "Expandir para ver los prompts optimizados",
      "submit_button": "Enviar",
      "close_button": "Cerrar"
    }
  },
  "secure_chat_new": {
    "EN": {
      "model_text": "Models",
      "GPT 3.5": "Faster for every day tasks",
      "GPT 4": "Ideal for complex tasks",
      "Mistral": "Efficient and precise for in-depth analyses",
      "Gemini": "Versatile and powerful for innovative solutions",
      "options_text": "Options",
      "optimised_prompts": "Optimised Prompts",
      "optimised_prompts_des": "Find optimized prompt for your needs",
      "context": "Context",
      "context_des": "Update your context to guide Secured Chat as you wish",
      "context_submit": "Submit",
      "context_template": "Context Templates",
      "context_template_des": "Some ideas about context you can use: Copy Paste your prefered one in the Context Tab",
      "secure_warning_message": "Secure Chat may make mistakes. Consider checking important information.",
      "chat_input_placeholder": "Message Secured Chat",
      "translator": "Translator",
      "translator_des": "Translate pdf/pptx/docx documents",
      "translator_des_2": "Simply upload your files, and our automated system will handle the translation process seamlessly.",
      "translator_des_3": "Please be aware that the current document size limit is set to 20 pages.",
      "multi_doc_des": "Load documents and start asking questions about them",
      "multi_docs": "Multi Docs",
      "documents": "Documents",
      "multi_doc_mode": "Multi Docs Mode",
      "settings": "Settings",
      "parameters": "Parameters"
    },
    "FR": {
      "model_text": "Modèles",
      "GPT 3.5": "Plus rapide pour les tâches quotidiennes",
      "GPT 4": "Idéal pour les tâches complexes",
      "Mistral": "Efficace et précis pour des analyses approfondies",
      "Gemini": "Polyvalent et puissant pour des solutions innovantes",
      "options_text": "Options",
      "optimised_prompts": "Prompts optimisés",
      "optimised_prompts_des": "Trouvez des prompts optimisés pour vos besoins",
      "context": "Contexte",
      "context_des": "Mettez à jour votre contexte pour guider Secure Chat selon vos souhaits",
      "context_submit": "Soumettre",
      "context_template": "Modèle de Contexte",
      "context_template_des": "Voici quelques idées de contexte que vous pouvez utiliser : Copiez-collez votre préféré dans l'onglet Contexte",
      "secure_warning_message": "Secure Chat peut faire des erreurs. Pensez à vérifier les informations importantes.",
      "chat_input_placeholder": "Message à Secured Chat",
      "translator": "Traducteur",
      "translator_des": "Traduire des documents pdf/pptx/docx",
      "translator_des_2": "Il vous suffit de télécharger vos fichiers, et notre système automatisé se chargera du processus de traduction sans effort.",
      "translator_des_3": "Veuillez noter que la limite actuelle de taille de document est fixée à 20 pages.",
      "multi_doc_des": "Chargez des documents et commencez à poser des questions à leur sujet",
      "multi_docs": "Multi Docs",
      "documents": "Documents",
      "multi_doc_mode": "Mode Multi Docs",
      "settings": "Paramètres",
      "parameters": "Paramètres"
    },
    "ES": {
      "model_text": "Modelos",
      "GPT 3.5": "Más rápido para tareas diarias",
      "GPT 4": "Ideal para tareas complejas",
      "Mistral": "Eficiente y preciso para análisis en profundidad",
      "Gemini": "Versátil y potente para soluciones innovadoras",
      "options_text": "Opciones",
      "optimised_prompts": "Prompts optimizados",
      "optimised_prompts_des": "Encuentra prompts optimizados para tus necesidades",
      "context": "Contexto",
      "context_des": "Actualiza tu contexto para guiar a Secure Chat como desees",
      "context_submit": "Enviar",
      "context_template": "Plantilla de Contexto",
      "context_template_des": "Aquí tienes algunas ideas sobre el contexto que puedes usar: Copia y pega tu favorito en la pestaña Contexto",
      "secure_warning_message": "Secure Chat puede cometer errores. Considera verificar la información importante.",
      "chat_input_placeholder": "Mensaje a Secured Chat",
      "translator": "Traductor",
      "translator_des": "Traducir documentos pdf/pptx/docx",
      "translator_des_2": "Simplemente carga tus archivos, y nuestro sistema automatizado se encargará del proceso de traducción sin problemas.",
      "translator_des_3": "Tenga en cuenta que el límite de tamaño de documento actual está establecido en 20 páginas.",
      "multi_doc_des": "Carga documentos y comienza a hacer preguntas sobre ellos",
      "multi_docs": "Multi Docs",
      "documents": "Documentos",
      "multi_doc_mode": "Modo Multi Docs",
      "settings": "Configuraciones",
      "parameters": "Parámetros"
    }
  },
  "promptopedia": {
    "EN": {
      "welcome_message": "Welcome to our Prompt Sharing app",
      "description_app": "Effortlessly exchange writing prompts with fellow users, fostering creativity and collaboration. Say goodbye to writer's block and hello to endless inspiration. Start sharing prompts now and elevate your writing experience!",
      "folder_text": "Folders",
      "selected_folder": "Selected Folder:",
      "search_text": "Search",
      "title_search": "by Title",
      "tag_search": "by Tag",
      "placeholder_search": "Search by title...",
      "add_prompt_button": "Add New Prompt +",
      "created_by": "Created by:",
      "copy_button": "Copy",
      "edit_button": "Edit",
      "delete_button": "Delete",
      "modal_error_prompt": "A prompt with this title already exists.",
      "modal_title": "Title",
      "modal_prompt": "Prompt",
      "modal_prompt_explanation": "You can use variables like {{variable}} above. You'll fill them in when you copy the prompt.",
      "modal_access": "Access Level",
      "modal_read_only": "Read only",
      "modal_only_me": "Only me",
      "language_model": "Language Model",
      "modal_tags": "Tags",
      "modal_notes": "Notes",
      "modal_save": "Save",
      "modal_loader": "Saving...",
      "add_folder_modal_placeholder": "Folder name...",
      "add_folder_modal_button": "Add Folder",
      "add_folder_loader": "Creating Folder...",
      "add_folder_error": "Folder Already Exists",
      "copy_folder_modal": "Copy Content",
      "copy_done_modal": "Content Copied ✅",
      "send_copy_modal": "Send to Secure Chat",
      "modal_edit_button": "Edit Prompt",
      "loader_modal_edit_button": "Saving Prompt...",
      "modal_delete_yes": "Yes",
      "modal_delete_no": "No",
      "modal_delete_validation_message": "Are you certain you want to delete this prompt?",
      "modal_delete_loader": "Deleting...",
      "modal_delete_folder_warning": "Warning! If you delete a folder all prompts contained in that folder will also be deleted.",
      "modal_delete_folder_loader": "Deleting Folder...",
      "modal_delete_folder_text": "Delete Folder"
    },
    "FR": {
      "welcome_message": "Bienvenue dans notre application de partage de prompts",
      "description_app": "Échangez facilement des idées d'écriture avec d'autres utilisateurs, favorisant la créativité et la collaboration. Dites adieu au blocage de l'écrivain et bonjour à l'inspiration infinie. Commencez à partager des prompts maintenant et élevez votre expérience d'écriture !",
      "folder_text": "Dossiers",
      "selected_folder": "Dossier sélectionné :",
      "search_text": "Recherche",
      "title_search": "par Titre",
      "tag_search": "par Tag",
      "placeholder_search": "Recherche par titre...",
      "add_prompt_button": "Ajouter un nouveau Prompt +",
      "created_by": "Créé par :",
      "copy_button": "Copier",
      "edit_button": "Modifier",
      "delete_button": "Supprimer",
      "modal_error_prompt": "Un prompt avec ce titre existe déjà.",
      "modal_title": "Titre",
      "modal_prompt": "Prompt",
      "modal_prompt_explanation": "Vous pouvez utiliser des variables comme {{variable}} ci-dessus. Vous les remplirez lorsque vous copierez le prompt.",
      "modal_access": "Niveau d'accès",
      "modal_read_only": "Lecture seule",
      "modal_only_me": "Uniquement moi",
      "language_model": "Modèle de Langue",
      "modal_tags": "Tags",
      "modal_notes": "Notes",
      "modal_save": "Enregistrer",
      "modal_loader": "Enregistrement...",
      "add_folder_modal_placeholder": "Nom du dossier...",
      "add_folder_modal_button": "Ajouter un Dossier",
      "add_folder_loader": "Création du Dossier...",
      "add_folder_error": "Le dossier existe déjà",
      "copy_folder_modal": "Copier le Contenu",
      "copy_done_modal": "Contenu Copié ✅",
      "send_copy_modal": "Envoyer au Chat Sécurisé",
      "modal_edit_button": "Modifier le Prompt",
      "loader_modal_edit_button": "Enregistrement du Prompt...",
      "modal_delete_yes": "Oui",
      "modal_delete_no": "Non",
      "modal_delete_validation_message": "Êtes-vous certain de vouloir supprimer ce prompt ?",
      "modal_delete_loader": "Suppression...",
      "modal_delete_folder_warning": "Attention ! Si vous supprimez un dossier, tous les prompts qu'il contient seront également supprimés.",
      "modal_delete_folder_loader": "Suppression du Dossier...",
      "modal_delete_folder_text": "Supprimer le Dossier"
    },
    "ES": {
      "welcome_message": "Bienvenido a nuestra aplicación de intercambio de prompts",
      "description_app": "Intercambia ideas de escritura fácilmente con otros usuarios, fomentando la creatividad y la colaboración. Di adiós al bloqueo del escritor y hola a la inspiración infinita. ¡Comienza a compartir prompts ahora y eleva tu experiencia de escritura!",
      "folder_text": "Carpetas",
      "selected_folder": "Carpeta seleccionada :",
      "search_text": "Buscar",
      "title_search": "por Título",
      "tag_search": "por Etiqueta",
      "placeholder_search": "Buscar por título...",
      "add_prompt_button": "Agregar nuevo Prompt +",
      "created_by": "Creado por :",
      "copy_button": "Copiar",
      "edit_button": "Editar",
      "delete_button": "Eliminar",
      "modal_error_prompt": "Ya existe un prompt con este título.",
      "modal_title": "Título",
      "modal_prompt": "Prompt",
      "modal_prompt_explanation": "Puedes usar variables como {{variable}} arriba. Las llenarás cuando copies el prompt.",
      "modal_access": "Nivel de acceso",
      "modal_read_only": "Solo lectura",
      "modal_only_me": "Solo yo",
      "language_model": "Modelo de Lenguaje",
      "modal_tags": "Etiquetas",
      "modal_notes": "Notas",
      "modal_save": "Guardar",
      "modal_loader": "Guardando...",
      "add_folder_modal_placeholder": "Nombre de la carpeta...",
      "add_folder_modal_button": "Agregar Carpeta",
      "add_folder_loader": "Creando Carpeta...",
      "add_folder_error": "La carpeta ya existe",
      "copy_folder_modal": "Copiar Contenido",
      "copy_done_modal": "Contenido Copiado ✅",
      "send_copy_modal": "Enviar al Chat Seguro",
      "modal_edit_button": "Editar Prompt",
      "loader_modal_edit_button": "Guardando Prompt...",
      "modal_delete_yes": "Sí",
      "modal_delete_no": "No",
      "modal_delete_validation_message": "¿Estás seguro de que deseas eliminar este prompt?",
      "modal_delete_loader": "Eliminando...",
      "modal_delete_folder_warning": "¡Advertencia! Si eliminas una carpeta, todos los prompts contenidos en esa carpeta también serán eliminados.",
      "modal_delete_folder_loader": "Eliminando Carpeta...",
      "modal_delete_folder_text": "Eliminar Carpeta"
    }
  },
  "doc_translator": {
    "EN": {
      "welcome_message": "Welcome to our document translation interface!",
      "description_app": "Simply upload your files, and our automated system will handle the translation process seamlessly. Please be aware that the current document size limit is set to 20 pages.",
      "description_app2": "We're pleased to offer two translation models tailored to your needs:",
      "description_app3": "• Premium Translation Model (5/5 Rating) using 'translation pro Hub' from Google:",
      "description_app4": "Ideal for critical and strategic presentations, ensuring accurate and high-quality translations.",
      "description_app5": "• Standard Translation Model (2/5 Rating) using GPT 3.5 from Azure Open AI:",
      "description_app6": "Recommended for non-strategic or less critical content, providing a balance between accuracy and efficiency.",
      "dropzone_text": "Drag and drop your file here, or click to select your file",
      "dropzone_accepted_file": "Accepted File:",
      "dropzone_selected_file": "Selected File:",
      "select_method": "Choose a method",
      "select_language": "Select translation language",
      "select_original_language": "Select original language",
      "submit_button": "Translate",
      "credit_text": "credit",
      "error_toomuch_pages": "Too much page in file",
      "loader_translation": "Loading..."
    },
    "FR": {
      "welcome_message": "Bienvenue dans notre interface de traduction de documents !",
      "description_app": "Il vous suffit de télécharger vos fichiers et notre système automatisé se chargera du processus de traduction en toute transparence. Veuillez noter que la taille actuelle des documents est limitée à 20 pages.",
      "description_app2": "Nous sommes heureux de vous proposer deux modèles de traduction adaptés à vos besoins :",
      "description_app3": "• Modèle de traduction premium (5/5 Note) utilisant 'translation pro Hub' de Google :",
      "description_app4": "Idéal pour des présentations critiques et stratégiques, garantissant des traductions précises et de haute qualité.",
      "description_app5": "• Modèle de traduction standard (2/5 Note) utilisant GPT 3.5 d'Azure Open AI :",
      "description_app6": "Recommandé pour du contenu non stratégique ou moins critique, offrant un équilibre entre précision et efficacité.",
      "dropzone_text": "Faites glisser et déposez votre fichier ici, ou cliquez pour sélectionner votre fichier",
      "dropzone_accepted_file": "Fichier accepté :",
      "dropzone_selected_file": "Fichier sélectionné :",
      "select_method": "Choisissez une méthode",
      "select_language": "Sélectionnez la langue de traduction",
      "select_original_language": "Sélectionnez la langue d'origine",
      "submit_button": "Traduire",
      "credit_text": "crédit",
      "error_toomuch_pages": "Trop de pages dans le fichier",
      "loader_translation": "Chargement..."
    },
    "ES": {
      "welcome_message": "¡Bienvenido a nuestra interfaz de traducción de documentos!",
      "description_app": "Simplemente cargue sus archivos, y nuestro sistema automatizado manejará el proceso de traducción sin problemas. Tenga en cuenta que el tamaño actual del documento está limitado a 20 páginas.",
      "description_app2": "Nos complace ofrecer dos modelos de traducción adaptados a sus necesidades:",
      "description_app3": "• Modelo de traducción premium (5/5 Clasificación) utilizando 'translation pro Hub' de Google:",
      "description_app4": "Ideal para presentaciones críticas y estratégicas, garantizando traducciones precisas y de alta calidad.",
      "description_app5": "• Modelo de traducción estándar (2/5 Clasificación) utilizando GPT 3.5 de Azure Open AI:",
      "description_app6": "Recomendado para contenido no estratégico o menos crítico, proporcionando un equilibrio entre precisión y eficiencia.",
      "dropzone_text": "Arrastra y suelta tu archivo aquí, o haz clic para seleccionar tu archivo",
      "dropzone_accepted_file": "Archivo aceptado:",
      "dropzone_selected_file": "Archivo seleccionado:",
      "select_method": "Elige un método",
      "select_language": "Selecciona el idioma de traducción",
      "select_original_language": "Selecciona el idioma original",
      "submit_button": "Traducir",
      "credit_text": "crédito",
      "error_toomuch_pages": "Demasiadas páginas en el archivo",
      "loader_translation": "Cargando..."
    }
  },
  "sentiment_analysis": {
    "EN": {
      "welcome_message": "MultiPrompter",
      "description_app": "Execute prompts on excel file cells.",
      "how_use": "How to use",
      "instructions": [
        {
          "title": "Download and fill the template",
          "content": "To get started, you can download the provided Excel template. The first column of your Excel file should contain the texts to be analyzed. Each column in your Excel file, except the first one, represents a theme for the sentiment analysis. The header of each column should clearly state the theme."
        },
        {
          "title": "Upload your Excel file",
          "content": "Once you have filled the template, upload the file. It should be in .xlsx format. The AI will perform sentiment analysis on the text data (from the first column) in the context of each theme."
        },
        {
          "title": "Set the number of rows to analyze",
          "content": "You can set the number of rows to analyze from your data. The default is the total number of rows in your data."
        },
        {
          "title": "Submit",
          "content": "Click the 'Submit' button to start the sentiment analysis. The analysis might take some time depending on the size of your data and the number of themes."
        }
      ],
      "excel_template": "Download this Excel file template if needed",
      "download_button": "Download",
      "scroll_setup": "Scroll for setup",
      "upload_excel": "Please Upload an Excel File",
      "dropzone_file": "Drag and drop your file here, or click to select your file.",
      "accepted_file": "Accepted file: xlsx",
      "selected_file": "File selected:",
      "set_up": "Set up",
      "choosen_excel": "Choosen Excel File:",
      "num_rows": "Number of rows:",
      "num_col": "Number of columns:",
      "num_req": "Number of requests (max: 300)",
      "loader_submit": "Generating file...",
      "too_much_req": "Too many requests",
      "submit_button": "Submit"
    },
    "FR": {
      "welcome_message": "MultiPrompter",
      "description_app": "Exécutez des prompts sur les cellules d'un fichier Excel.",
      "how_use": "Comment utiliser",
      "instructions": [
        {
          "title": "Téléchargez et remplissez le modèle",
          "content": "Pour commencer, vous pouvez télécharger le modèle Excel fourni. La première colonne de votre fichier Excel doit contenir les textes à analyser. Chaque colonne de votre fichier Excel, sauf la première, représente un thème pour l'analyse de sentiment. L'en-tête de chaque colonne doit indiquer clairement le thème."
        },
        {
          "title": "Téléchargez votre fichier Excel",
          "content": "Une fois que vous avez rempli le modèle, téléchargez le fichier. Il doit être au format .xlsx. L'IA effectuera une analyse de sentiment sur les données textuelles (à partir de la première colonne) dans le contexte de chaque thème."
        },
        {
          "title": "Définir le nombre de lignes à analyser",
          "content": "Vous pouvez définir le nombre de lignes à analyser à partir de vos données. Par défaut, le nombre total de lignes de vos données est utilisé."
        },
        {
          "title": "Soumettre",
          "content": "Cliquez sur le bouton 'Soumettre' pour commencer l'analyse de sentiment. L'analyse peut prendre un certain temps en fonction de la taille de vos données et du nombre de thèmes."
        }
      ],
      "excel_template": "Téléchargez ce modèle de fichier Excel si nécessaire",
      "download_button": "Télécharger",
      "scroll_setup": "Défilez pour configurer",
      "upload_excel": "Veuillez télécharger un fichier Excel",
      "dropzone_file": "Faites glisser et déposez votre fichier ici, ou cliquez pour sélectionner votre fichier.",
      "accepted_file": "Fichier accepté : xlsx",
      "selected_file": "Fichier sélectionné :",
      "set_up": "Configurer",
      "choosen_excel": "Fichier Excel choisi :",
      "num_rows": "Nombre de lignes :",
      "num_col": "Nombre de colonnes :",
      "num_req": "Nombre de demandes (max : 300)",
      "loader_submit": "Génération du fichier...",
      "too_much_req": "Trop de demandes",
      "submit_button": "Soumettre"
    },
    "ES": {
      "welcome_message": "MultiPrompter",
      "description_app": "Ejecute prompts en las celdas del archivo de Excel.",
      "how_use": "Cómo usar",
      "instructions": [
        {
          "title": "Descargue y complete la plantilla",
          "content": "Para comenzar, puede descargar la plantilla de Excel proporcionada. La primera columna de su archivo de Excel debe contener los textos a analizar. Cada columna de su archivo de Excel, excepto la primera, representa un tema para el análisis de sentimientos. El encabezado de cada columna debe indicar claramente el tema."
        },
        {
          "title": "Cargue su archivo de Excel",
          "content": "Una vez que haya completado la plantilla, cargue el archivo. Debe estar en formato .xlsx. La IA realizará un análisis de sentimientos en los datos de texto (de la primera columna) en el contexto de cada tema."
        },
        {
          "title": "Establecer el número de filas a analizar",
          "content": "Puede establecer el número de filas a analizar de sus datos. El valor predeterminado es el número total de filas en sus datos."
        },
        {
          "title": "Enviar",
          "content": "Haga clic en el botón 'Enviar' para comenzar el análisis de sentimientos. El análisis puede tardar algún tiempo dependiendo del tamaño de sus datos y del número de temas."
        }
      ],
      "excel_template": "Descargue esta plantilla de archivo Excel si es necesario",
      "download_button": "Descargar",
      "scroll_setup": "Desplácese para configurar",
      "upload_excel": "Por favor, cargue un archivo Excel",
      "dropzone_file": "Arrastra y suelta tu archivo aquí, o haz clic para seleccionar tu archivo.",
      "accepted_file": "Archivo aceptado: xlsx",
      "selected_file": "Archivo seleccionado:",
      "set_up": "Configurar",
      "choosen_excel": "Archivo Excel elegido:",
      "num_rows": "Número de filas:",
      "num_col": "Número de columnas:",
      "num_req": "Número de solicitudes (máx: 300)",
      "loader_submit": "Generando archivo...",
      "too_much_req": "Demasiadas solicitudes",
      "submit_button": "Enviar"
    }
  },
  "multi_doc": {
    "EN": {
      "welcome_message": "Documents Q&A",
      "description_app": "Simply upload your files and ask any questions about its content.",
      "dropzone_text": "Drag and drop your file here, or click to select your file",
      "dropzone_accepted_file": "Accepted File:",
      "dropzone_selected_file": "Selected File:",
      "dropzone_file_error": "Some files have been rejected because they exceed the maximum size of 200MB.",
      "input_placeholder": "URL of the document",
      "generate_title_summary": "Generate document titles and summaries",
      "upload_docs": "Upload documents",
      "loader_upload1": "Currently Uploading",
      "loader_upload2": "file",
      "timeout_error1": "A Timeout occurred for the following",
      "timeout_error2": "Please try with",
      "timeout_error3": "smaller file",
      "placeholder_chat_input_general": "Ask something general about your documents...",
      "placeholder_chat_input": "Ask something about your documents...",
      "reset_text": "Reset Q&A",
      "ask_qa": "Here is the title and summary I built for your documents:",
      "summary_indication": "Use the check box in the top right corner to generate a summary.",
      "delete_doc": "Delete documents"
    },
    "FR": {
      "welcome_message": "Questions et Réponses sur les Documents",
      "description_app": "Il suffit de télécharger vos fichiers et de poser toutes vos questions sur leur contenu.",
      "dropzone_text": "Glissez et déposez votre fichier ici, ou cliquez pour sélectionner votre fichier",
      "dropzone_accepted_file": "Fichier accepté :",
      "dropzone_selected_file": "Fichier sélectionné :",
      "dropzone_file_error": "Certains fichiers ont été rejetés car ils dépassent la taille maximale de 200 Mo.",
      "input_placeholder": "URL du document",
      "generate_title_summary": "Générer des titres et des résumés de documents",
      "upload_docs": "Télécharger des documents",
      "loader_upload1": "Téléchargement en cours",
      "loader_upload2": "fichier",
      "timeout_error1": "Un délai d'attente est survenu pour le fichier suivant",
      "timeout_error2": "Veuillez essayer avec",
      "timeout_error3": "un fichier plus petit",
      "placeholder_chat_input_general": "Posez une question générale sur vos documents...",
      "placeholder_chat_input": "Posez une question sur vos documents...",
      "reset_text": "Réinitialiser Q&R",
      "ask_qa": "Voici le titre et le résumé que j'ai construits pour vos documents :",
      "summary_indication": "Utilisez la case à cocher en haut à droite pour générer un résumé.",
      "delete_doc": "Supprimer les documents"
    },
    "ES": {
      "welcome_message": "Preguntas y Respuestas sobre Documentos",
      "description_app": "Simplemente sube tus archivos y haz cualquier pregunta sobre su contenido.",
      "dropzone_text": "Arrastra y suelta tu archivo aquí, o haz clic para seleccionar tu archivo",
      "dropzone_accepted_file": "Archivo aceptado:",
      "dropzone_selected_file": "Archivo seleccionado:",
      "dropzone_file_error": "Algunos archivos han sido rechazados porque superan el tamaño máximo de 200 MB.",
      "input_placeholder": "URL del documento",
      "generate_title_summary": "Generar títulos y resúmenes de documentos",
      "upload_docs": "Subir documentos",
      "loader_upload1": "Subiendo actualmente",
      "loader_upload2": "archivo",
      "timeout_error1": "Se produjo un tiempo de espera para el siguiente archivo",
      "timeout_error2": "Por favor, intente con",
      "timeout_error3": "un archivo más pequeño",
      "placeholder_chat_input_general": "Haz una pregunta general sobre tus documentos...",
      "placeholder_chat_input": "Haz una pregunta sobre tus documentos...",
      "reset_text": "Restablecer P&R",
      "ask_qa": "Aquí está el título y el resumen que construí para tus documentos:",
      "summary_indication": "Usa la casilla de verificación en la esquina superior derecha para generar un resumen.",
      "delete_doc": "Eliminar documentos"
    }
  }
};