import { useState, useEffect } from "react";
import { useAuth } from "../../providers";
import { ChangeEvent } from "react";
import { DropzoneExcel } from "./DropzoneExcel";
import { useTypingEffect } from "../../hooks/typingEffect";
import { config } from '../../config';
import { translations } from "../../translations";
import { SVGIcon } from "../../assets";

const excelOptions = [
    { label: "Sample_template_for_multiprompter.xlsx", value: "HR" },
];

export const SentimentAnalysis = () => {
    const { token,language } = useAuth()
    const [visibleSection, setVisibleSection] = useState<number | null>(null);
    const [fileToDownload, setFileToDownload] = useState<string>('HR');
    const [excelUploadedSucces, setExcelUploadedSucces] = useState<boolean>(false)
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(true)
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>("");
    const [rowNumbers, setRowNumbers] = useState<string>('');
    const [excelData, setExcelData] = useState<any[]>([]);
    const [totalRows, setTotalRows] = useState<string>('')
    const [totaColumns, setTotalColumns] = useState<string>('')
    const [maxTotalRows, setMaxTotalRows] = useState<number>(0)
    const [questionTemplate, setQuestionTemplate] = useState<string>("Analyze the sentiment of the statement {cell_content} within the specific context of the {column_name} theme, related to our business of selling products online'")
    const [context, setContext] = useState<string>("You are an expert in sentiment analysis scoring.\n- Assign a sentiment score from -1 (negative) to 1 (positive), ensuring that the score reflects the statement's direct relevance and impact on the theme.\n- If the statement is positive or negative but not related to the theme, assign a 0 score to indicate neutrality.\n- Focus on the thematic connection to ensure the score accurately represents sentiment concerning the specific theme, among all of these themes: {list_of_themes}.\n- ONLY answer with the score and nothing else.")
    const [loaderSubmit, setLoaderSubmit] = useState<boolean>(false)
    const [tooMuchRowsError, setTooMuchRowsError] = useState<boolean>(false)
    const [totalCells, setTotalCells] = useState<number>(0)    
    const translationTexts = translations['sentiment_analysis'][language as keyof typeof translations['sentiment_analysis']];
    const [typedText] = useTypingEffect(translationTexts.welcome_message , 40)

    const [openIndex, setOpenIndex] = useState<number | null>(null);


    const handleCellsChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
        setTotalCells(parseInt(e.target.value))
    }

    useEffect(()=>{
        if(excelData.length > 0){
            setTotalColumns(excelData[0].length)
        }
    },[excelData])

    useEffect(()=>{
        setTotalCells(parseInt(totalRows) * parseInt(totaColumns))
    },[totalRows,totaColumns])

    useEffect(()=>{
        if(totalCells > 300) setTooMuchRowsError(true)
        else setTooMuchRowsError(false)
    },[totalCells])


    const toggleSection = (index: number) => {
        setVisibleSection(prevState => (prevState === index ? null : index));
    };

    const handleChangeFileDownload = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFileToDownload(e.target.value);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const toggleDropdownHowWork = (index: number) => {
        if (openIndex === index) {
            setOpenIndex(null);
        } else {
            setOpenIndex(index);
        }
    };

    const downloadFile = async () => {
        try {
            const response = await fetch(`/api/sentiment/template/${fileToDownload}`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error("Erreur de telechargement.");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${fileToDownload == "HR" ? "input_template.xlsx" : "Website_template.xlsx"}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);

            //onSuccess();
        } catch (error) {
            //onError(error);
            console.log(error);
        }
    };

    const generateSentiment = async () => {
        if(parseInt(totalRows) > maxTotalRows) return
        setLoaderSubmit(true)
        if (file) {
            const formData = new FormData();
            //console.log(questionTemplate, context, totalRows)
            formData.append("file", file);
            formData.append("question_template", questionTemplate);
            formData.append("context", context);
            formData.append("limit", totalRows);

            try {
                const response = await fetch(`/api/sentiment/document`, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error("Erreur de telechargement.");
                }

                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `output.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);

                //onSuccess();
            } catch (error) {
                //onError(error);
                console.log(error);
            }
        }
        setLoaderSubmit(false)
    };

    const colors = ['#232323', '#2100a5', '#fe5e08', '#016df0','#016df0'];
    const hoverColors = ['#232323', '#2100a5', '#fe5e08', '#016df0','#016df0'];

    return (
        <div className="flex flex-col items-center gap-2 pb-20">
             <div className="flex flex-col w-full bg-welcomeBackground  rounded-bl-[250px] rounded-br-[250px] p-5">
                    <div className="flex flex-row w-full justify-center">
                        <p className="text-textPrimary font-secondary text-4xl tracking-wide">{typedText}</p>
                    </div>
                    <div className="flex gap-1 flex-col bg-transparent p-2 w-full rounded-lg justify-center items-center text-sm font-bold text-textPrimary text-center overflow-hidden">
                        <p className="w-[70%]">
                            {translationTexts.description_app}
                        </p>
                    </div>
                </div>  
            <div className="flex flex-col bg-transparent rounded-md w-full p-3 justify-center items-center">
                <h1 className="w-full text-center text-4xl text-textSecondary font-secondary underline">{translationTexts.how_use}</h1>
                <div className="flex flex-col gap-4 w-full min-[1600px]:w-[80%] p-5">
                    {translationTexts.instructions.map((instruction, index) => {
                        const textColor = [0, 1, 2, 3, 4, 5].includes(index) ? 'text-textPrimary' : 'text-textSecondary';
                        return (
                            <div key={index} className={`font-bold border border-gray-400 rounded-xl text-black flex flex-col items-center w-full relative cursor-pointer ${textColor}`}>
                                <div 
                                    onClick={() => toggleDropdownHowWork(index)}
                                    className="tracking-normal text-center flex w-full text-xl z-10 cursor-pointer p-3.5 transition-colors duration-300 ease-in-out"
                                >
                                    <span className="text-black w-full flex items-start text-sm">
                                        <strong className="">{index + 1}.</strong>&nbsp;{instruction.title}
                                    </span>

                                    <div className="rotate-90 px-2 flex items-center justify-end">
                                        <SVGIcon icon="ARROW_BLACK_ICON"/>
                                    </div>
                                </div>
                                {openIndex === index && (
                                    <div className="h-full rounded-br-xl rounded-bl-xl text-black text-center text-sm p-5 w-full bg-gray-100 rounded-md transition-all duration-500 ease-in-out">
                                        <p>{instruction.content}</p>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 w-[70%] rounded-tr-[100px] rounded-tl-[100px] border border-[#232323] p-4 rounded-lg shadow-lg">
                <div className="flex flex-col w-[60%] ">
                    <label className="text-md p-1 w-full">{translationTexts.excel_template}</label>
                    <select className="w-full p-2 bg-selectBg text-selectText border border-selectBorder rounded-lg focus:outline-none focus:ring focus:ring-focusRing focus:ring-opacity-75 max-w-3xl" value={fileToDownload} onChange={handleChangeFileDownload}>
                        {excelOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <button onClick={() => downloadFile()} className="cursor-pointer flex items-center justify-center gap-3 w-[20%] text-sm px-4 py-2 bg-buttonBg text-buttonText rounded-lg border hover:border-buttonHoverBorder hover:text-buttonHoverText hover:bg-buttonHoverBg focus:outline-none">{translationTexts.download_button}</button>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 w-[70%] rounded-br-[100px] rounded-bl-[100px] border border-[#232323] p-6 rounded-lg shadow-lg">
                <div className="flex flex-col">
                    <p>{excelUploadedSucces ? translationTexts.scroll_setup : translationTexts.upload_excel}</p>
                </div>
                <div className="flex flex-col">
                    <DropzoneExcel language={language} onExcelParsed={setExcelData} setFile={setFile} setFileName={setFileName} fileName={fileName} setExcelUploadedSucces={setExcelUploadedSucces} setTotalRows={setTotalRows} setMaxTotalRows={setMaxTotalRows} />
                </div>
            </div>
            {excelUploadedSucces &&
                <div className="flex flex-col justify-center items-center gap-2 w-[80%] rounded-[100px] border border-dropdownBorder p-4 rounded-lg shadow-lg mt-5 overflow-hidden">
                    <button onClick={toggleDropdown} className="text-dropdownText flex justify-between items-center w-full px-4 py-2 text-left bg-dropdownBg rounded-lg shadow border border-dropdownBorder focus:outline-none">
                        <span className="w-full">{translationTexts.set_up}&nbsp;</span>
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={dropdownOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}></path>
                        </svg>
                    </button>
                    <div className={`w-full bg-dropdownBg relative transition-all overflow-hidden ${dropdownOpen ? 'border border-dropdownBorder' : ''} ${dropdownOpen ? 'max-h-150' : 'max-h-0'}`}>
                        <div className="w-full p-3 flex flex-col gap-4">
                            <div className="flex flex-col">
                                <label className="text-md p-1">{translationTexts.choosen_excel} {fileName}</label>
                                <div className="overflow-auto max-h-96">
                                    <table className="min-w-full">
                                        <tbody>
                                            {excelData.map((row, rowIndex) => (
                                                <tr key={rowIndex} className={`${rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                                                    {Object.values(row).map((value, valueIndex) => (
                                                        <td key={valueIndex} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-textSecondary">
                                                            {valueIndex === 0 && rowIndex !== 0 ? String(value).substring(0,50) + "..." : String(value)}                                                        
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex flex-col w-2/5">
                                <label className="text-md p-1">{translationTexts.num_rows}</label>
                                <input className="w-full p-2 bg-inputBg border border-inputBorder text-inputText rounded-lg focus:outline-none focus:ring focus:ring-focusRing focus:ring-opacity-75 resize-none"
                                    onChange={(e)=> setTotalRows(e.target.value)}
                                    value={totalRows}
                                    max={300}
                                    min={1}
                                    type="number"
                                />
                            </div>
                            <label className="text-md p-1">{translationTexts.num_col} {totaColumns}</label>
                            <label className="text-md p-1">{translationTexts.num_req} {totalCells ? totalCells : 0} </label>
                            <div className="flex flex-col">
                                {/* <button onClick={generateSentiment} disabled={loaderSubmit || tooMuchRowsError || totalRows.length === 0} className="cursor-pointer w-full py-2 bg-transparent text-black rounded-lg border border-gray-300 hover:text-emerald-500 hover:border-emerald-500 focus:outline-none">{loaderSubmit ? 'Generating file...' : (tooMuchRowsError ? `Too many cells in file you have currently ${parseInt(totalRows) * parseInt(totaColumns)} cells (${parseInt(totalRows)} rows and ${parseInt(totaColumns)} column${parseInt(totaColumns) > 1 ? 's' : '' })` : 'Submit')}</button> */}
                                <button onClick={generateSentiment} disabled={loaderSubmit || tooMuchRowsError || totalRows.length === 0} className={`cursor-pointer w-full py-2 rounded-lg ${tooMuchRowsError ?  'hover:text-red-500 hover:border-red-500 bg-transparent text-black border border-gray-300' : 'bg-buttonBg hover:text-buttonHoverText text-buttonText rounded-lg border hover:border-buttonHoverBorder hover:bg-buttonHoverBg'} focus:outline-none`}>{loaderSubmit ? translationTexts.loader_submit : (tooMuchRowsError ? translationTexts.too_much_req : translationTexts.submit_button)}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}