import { SVGIcon, Section } from "../../assets";

export const SideBarSectionBtn = ({
  section,
  isSidebarOpen,
  isHover,
  isSectionOpen,
  toggleIsSectionOpen,
}: {
  section: Section;
  isSidebarOpen: Boolean;
  isHover: Boolean;
  isSectionOpen: Boolean;
  toggleIsSectionOpen: (newValue?: boolean | undefined) => void;
}) => {
  const classic = "flex items-center px-2 py-1 rounded-lg";
  const sidebarOpt = isSidebarOpen ? "w-full" : "justify-center";
  const hoverAndSectionOpt =
    isHover || isSectionOpen ? "opacity-100 bg-[#2100a5]" : "";
  const sectionBtnClassName = `${classic} ${sidebarOpt} ${hoverAndSectionOpt} text-text-secondary-light`;
  const opacity = isHover || isSectionOpen ? "opacity-100" : "opacity-50"
  
  return (
    <button
      className={sectionBtnClassName}
      onClick={() => {
        isSidebarOpen && toggleIsSectionOpen(!isSectionOpen);
      }}
    >
      <span>{section.icon}</span>
      {isSidebarOpen && (
        <>
          <span className={` text-sm ${opacity} flex-grow pl-3`} style={{ textAlign: 'left' }}>{section.title}</span>
          {isSectionOpen ? (
            <SVGIcon icon="TOP_CHEVRON_ICON" className={`${opacity}`} />
          ) : (
            <SVGIcon icon="DOWN_CHEVRON_ICON" className={`${opacity}`} />
          )}
        </>
      )}
    </button>
  );
};
