import { useEffect, useState } from "react";
import "./App.css";
import { Layout } from "./layout";
import { CreditProvider, useAuth } from "./providers";
import { HomeAppPreview } from "./components/HomeAppPreview";
import { config } from './config';
import { loadFonts } from './utils/loadFonts';
import { ChatGPTProvider } from "./pages/ChatGPT/providers";



function App() {
  const { isAuthenticated, login, updateToken, token, fetchJWT, getGlobalLanguage } = useAuth();

  useEffect(() => {
    fetchJWT()
    loadFonts(config.fonts);
  }, []);

  useEffect(() => {
    if (token) {
      const intervalId = setInterval(() => {
        updateToken();
      }, 4 * 60 * 60 * 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [token]);

  useEffect(()=>{
    if(token) getGlobalLanguage()
  },[token])

  if (!isAuthenticated) {
    return <div className="flex flex-col justify-center items-center h-screen bg-textSecondary">
      <div className="flex w-full justify-center h-full">
        <HomeAppPreview showTitle={true} titleOpacity={100} isAbsolute={false} />
      </div>
    </div>
  } else {
    return (
      <CreditProvider>
        <ChatGPTProvider>
          <Layout />
        </ChatGPTProvider>
      </CreditProvider>
    )

  }
}

export default App;
