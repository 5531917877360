import React, { useState } from "react";
import { usePromptDrive } from "../../hooks/promptDrive";
import { translations } from "../../translations";

interface ModalCreateFolderProps {
    setAddFolderInput: (value: string) => void;
    setShowFolderModal: (value: boolean) => void;
    addFolder: () => void;
    loader: boolean;
    addFolderError: boolean;
    language: string;
}


export const ModalCreateFolder: React.FC<ModalCreateFolderProps> = ({ setAddFolderInput, setShowFolderModal, addFolder,loader,addFolderError,language }) => {
    const translationTexts = translations['promptopedia'][language as keyof typeof translations['promptopedia']];


    return (
        <div className="absolute backdrop-blur-md inset-0 z-9999 flex justify-center items-center" style={{ zIndex: 9 }}>
            <div className="flex flex-col gap-4 w-[50%] h-fit items-center inset-0 border border-dropdownBorder bg-welcomeBackground bg-opacity-70 rounded-xl p-8 relative">
                <div 
                    className="p-1 absolute top-0 right-1 cursor-pointer transform transition-transform hover:rotate-180"
                    onClick={() => setShowFolderModal(false)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="white"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </div>
                <input
                    className="w-full p-2 rounded-md border border-inputBorder placeholder-inputText focus:outline-none"
                    placeholder={translationTexts.add_folder_modal_placeholder}
                    onChange={(e) => setAddFolderInput(e.target.value)}
                ></input>
                <button
                    className={`w-full bg-buttonBg text-buttonText rounded-md p-1 hover:bg-buttonHoverBg hover:text-buttonHoverText ${addFolderError ? 'cursor-not-allowed' : ''}`}
                    onClick={addFolder}
                    disabled={loader || addFolderError}
                >
                    {loader ? translationTexts.add_folder_loader : addFolderError ? translationTexts.add_folder_error : translationTexts.add_folder_modal_button}
                </button>
            </div>
        </div>
    );
}