import { RefObject, useState } from 'react'

import { useEventListener } from 'usehooks-ts'

export function useHover<T extends HTMLElement = HTMLElement>(
  elementRef: RefObject<T>,
): { isHover: boolean; forceHandleHover: (value: boolean) => void; } {
  const [isHover, setIsHover] = useState<boolean>(false)

  const handleMouseEnter = () => setIsHover(true)
  const handleMouseLeave = () => setIsHover(false)

  useEventListener('mouseenter', handleMouseEnter, elementRef)
  useEventListener('mouseleave', handleMouseLeave, elementRef)

  const forceHandleHover = (value: boolean):void => {
    setIsHover(value)
  }

  return {isHover, forceHandleHover}
}