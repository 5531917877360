import { useActiveTabs, useAuth } from "../../../providers";
import { ChatModel } from "../hooks";
import { useChatGPT } from "../providers";
import { TypeToIcon } from "./Answer";
import { ArrowUpIcon, PaperclipIcon, StreamIcon } from "./Icon";
import { translations } from "../../../translations";
import { useEffect } from "react";
import { useChatGPTConfig } from "../hooks";

export const ChatGPTBottom = () => {
    const {
        modalStatus,
        setModalStatus,
        gptConfig,
        gptInput,
        gptModel,
        chatGPTAsk,
        gptGesture,
    } = useChatGPT();

    const {language} = useAuth()
    const langKey = language as keyof typeof translations['secure_chat_new'];
    const { returnFav } = useActiveTabs()
    const fav = returnFav([3, 7])
    const {fetchOngoingTranslations,onGoingTranslation} = useChatGPTConfig()

    useEffect(()=>{
        fetchOngoingTranslations()
    },[])

    const handleSubmit = () => {
        gptInput.askGPTStream(
            chatGPTAsk.setErrorRequestChpt,
            gptConfig.selectedChatId,
            gptInput.text,
            gptModel.selectedChatModel,
            chatGPTAsk.setLoadingGPT,
            gptConfig
        )
        gptInput.setText("")
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Enter" && !event.shiftKey && gptInput.text.length > 0) {
            event.preventDefault();
            handleSubmit()
        }
    };

    const btn = (
        <button onClick={gptGesture.scrollToEndOfMessages} className="cursor-pointer absolute z-20 rounded-full border right-1/2 translate-x-1/2 bg-white"
            style={{ bottom: `calc(${Math.max(gptInput.chatGPTInputPosition.height, 80)}px + 10px)` }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="icon-md m-1 text-token-text-primary">
                <path fill="currentColor" fill-rule="evenodd" d="M12 21a1 1 0 0 1-.707-.293l-7-7a1 1 0 1 1 1.414-1.414L11 17.586V4a1 1 0 1 1 2 0v13.586l5.293-5.293a1 1 0 0 1 1.414 1.414l-7 7A1 1 0 0 1 12 21" clip-rule="evenodd"></path>
            </svg>
        </button>
    );

    return (
        <div className="md:pt-0 dark:border-white/20 md:border-transparent md:dark:border-transparent w-full">
            {!gptGesture.isAtBottom && btn}
            <div ref={gptInput.inputRef} className="mx-auto flex flex-col flex-1 gap-4 text-base md:gap-5 lg:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
                <div className="flex flex-col items-center">
                    <div className="flex items-end w-full p-2 bg-gray-100 rounded-[26px]">
                        {fav.length > 0 && <div onClick={() => { setModalStatus("DOCUMENT_OPT") }} className="cursor-pointer flex items-center justify-center w-8 h-8 "><PaperclipIcon className="w-5 h-5 text-black" /></div> }
                        <textarea
                            ref={gptInput.textareaRef}
                            placeholder={translations['secure_chat_new'][langKey]['chat_input_placeholder']}
                            className="flex-1 px-2 mb-1 bg-transparent border-none outline-none max-h-52 overflow-y-auto focus:ring-0 focus-visible:ring-0 m-0 resize-none border-0"
                            value={gptInput.text}
                            onChange={(e) => gptInput.setText(e.target.value)}
                            onKeyDown={handleKeyDown}
                            rows={1}
                        />
                        {
                            chatGPTAsk.loadingGPT ?
                                <button className={`flex items-center justify-center w-8 h-8 bg-black text-[#F4F4F4] rounded-full`}>
                                    <StreamIcon />
                                </button> :
                                <button onClick={handleSubmit} className={`flex items-center justify-center w-8 h-8 ${gptInput.text.length == 0 ? "bg-gray-300 text-[#F4F4F4]" : "bg-black text-[#F4F4F4]"} rounded-full`}>
                                    <ArrowUpIcon />
                                </button>
                        }
                    </div>
                    <div className="relative px-2 py-2 text-center text-xs text-token-text-secondary md:px-[60px]">
                        <span>{translations['secure_chat_new'][langKey]['secure_warning_message']}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}


