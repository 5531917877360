import { ReactComponent as CHARTS_ICON } from './Charts.svg';
import { ReactComponent as DOCUMENT_ICON } from './Document.svg';
import { ReactComponent as HOME_ICON } from './Home.svg';
import { ReactComponent as LABEL_ICON } from './Label.svg';
import { ReactComponent as PLANE_ICON } from './Plane.svg';
import { ReactComponent as PAPER_PLANE_ICON } from './PaperPlane.svg';
import { ReactComponent as RIGHT_CHEVRON_ICON } from "./RightChevron.svg";
import { ReactComponent as LEFT_CHEVRON_ICON } from "./LeftChevron.svg";
import { ReactComponent as TOP_CHEVRON_ICON } from "./TopChevron.svg";
import { ReactComponent as DOWN_CHEVRON_ICON } from "./DownChevron.svg";
import { ReactComponent as CLOSE_ICON } from "./Close.svg";
import { ReactComponent as ROSE_ICON } from "./Logo SDH BU Rose.svg"
import { ReactComponent as VERT_ICON } from "./Logo SDH BU Vert.svg"
import { ReactComponent as BLEU_ICON } from "./Logo SDH HQ Bleu.svg"
import { ReactComponent as IA_CHAT_ICON } from "./ChatIconIA.svg"
import { ReactComponent as IA_CHAT_HOME_ICON } from "./ChatIconIAHome.svg"
import { ReactComponent as IA_CHAT_MULTIDOC } from "./ChatIconIAMultiDoc.svg"
import { ReactComponent as IA_CHAT_OAG } from "./ChatIconOAG.svg"
import { ReactComponent as GPT_CHAT_ICON } from "./ChatIconGPT.svg"
import { ReactComponent as GPT_CHAT_ICON_XL } from "./ChatIconGPTXL.svg"
import { ReactComponent as USER_CHAT_ICON } from "./ChatIconUser.svg"
import { ReactComponent as REFRESH_ICON } from "./Refresh.svg"
import { ReactComponent as MENU_DOTS_ICON } from "./MenuDots.svg"
import { ReactComponent as MENU_RADIUS_ICON } from "./Radius.svg"
import { ReactComponent as MENU_LINE_ICON } from "./Line.svg"
import { ReactComponent as DASHBOARD_HQ_ICON } from "./DashboardHQ.svg"
import { ReactComponent as DASHBOARD_BU_ICON } from "./DashboardBU.svg"
import { ReactComponent as TRAFFIC_BU_ICON } from "./HomeSDHBU1.svg"
import { ReactComponent as RETAIL_BU_ICON } from "./HomeSDHBU2.svg"
import { ReactComponent as FINANCE_BU_ICON } from "./HomeSDHBU3.svg"
import { ReactComponent as AIRLINEID_BU_ICON } from "./HomeSDHBU4.svg"
import { ReactComponent as SECURE_CHAT_ICON } from "./secureChat.svg"
import { ReactComponent as NEWSLETTER_ICON } from "./newsletter.svg"
import { ReactComponent as TRANSLATOR_ICON } from "./translator.svg"
import { ReactComponent as SENTIMENT_ANALYSIS_ICON } from "./sentimentAnalysis.svg"
import { ReactComponent as DATA_ROOM_ICON } from "./dataRoom.svg"
import { ReactComponent as CUSTOM_TRAFFIC_ICON } from "./customTraffic.svg"
import { ReactComponent as CUSTOM_FINANCE_ICON } from "./customFinance.svg"
import { ReactComponent as COMPETITION_ANALYSIS_ICON } from "./competitionAnalysis.svg"
import { ReactComponent as ARROW_BLACK_ICON } from "./arrowBlack.svg"
import { ReactComponent as ARROW_WHITE_ICON } from "./arrowWhite.svg"
import { ReactComponent as LOGOUT_ICON } from "./logout.svg"
import { ReactComponent as ENGLISH_ICON } from "./english.svg"
import { ReactComponent as LANGUAGE_ARROW_ICON } from "./languageArrow.svg"
import { ReactComponent as CAR_PARKS_ICON } from "./Parking.svg"
import { ReactComponent as COMMUNICATION_ICON } from "./Communication.svg"
import { ReactComponent as ARROW_SMALL_ICON } from "./arrowSmall.svg"
import { ReactComponent as SIZE_SCREEN_ICON } from "./sizeScreen.svg"
import { ReactComponent as DOWNLOAD_ICON } from "./download.svg"
import { ReactComponent as LINKS_ICON } from "./linksOAG.svg"
import { ReactComponent as UPLOAD_ICON } from "./uploadOAG.svg"
import { ReactComponent as SHAREPOINT_ICON } from "./Sharepoint.svg"
import { ReactComponent as HELP_ICON } from "./help-circle.svg"
import { ReactComponent as GEMINI_CHAT_ICON } from "./geminiChatIcon.svg"
import { ReactComponent as GPT4_CHAT_ICON } from "./ChatIconGPT4.svg"
import { ReactComponent as MISTRAL_CHAT_ICON } from "./ChatIconMistral.svg"
import { ReactComponent as EDG_ICON } from "./EDGIcon.svg"
import { ReactComponent as EDG_LONG_ICON } from "./EDGLong.svg"
import { ReactComponent as EDG_LIGNE_ICON } from "./EDGLigne.svg"
import { ReactComponent as FOLDED_NAV_ICON } from "./FoldedNav.svg"
import { ReactComponent as EDG_TEXTNOIR_ICON } from "./EDG-texte-noir.svg"
import { ReactComponent as LOGO_ICON } from "./Logo.svg"
import { ReactComponent as STAR_ICON } from "./star.svg"
import { ReactComponent as CIRCLE_PATTERN_ICON } from "./circle-pattern.svg"
import { ReactComponent as FOLDERS_ICON } from "./folderIcons.svg"
import { ReactComponent as CLOSE_SIDEBAR_ICON } from "./closeSidebar.svg"
import { ReactComponent as DOCQA_ICON } from "./Document Q&A.svg"
import { ReactComponent as DOCTRANS_ICON } from "./Document Translator.svg"
import { ReactComponent as PROMPTOPEDIA_ICON } from "./Promptopedia.svg"
import { ReactComponent as SECURECHAT_ICON } from "./Secure Chat.svg"
import { ReactComponent as SENTIMENT_ICON } from "./Sentiment Analysis.svg"
import { ReactComponent as OPEN_SIDEBAR_ICON } from "./openSidebar.svg"
import { ReactComponent as HOM3_ICON } from './Home3.svg';
import { ReactComponent as CREDIT_ICON } from './Credit.svg';
import { ReactComponent as FRENCH_FLAG_ICON } from './FrenchFlag.svg';
import { ReactComponent as SPAIN_FLAG_ICON } from './SpanishFlag.svg';
import { ReactComponent as ENGLAND_FLAG_ICON } from './EnglandFlag.svg';
import { ReactComponent as TRADUCTION_ICON } from './traduction.svg';
import { ReactComponent as FACTORY_ICON } from './factory_icon.svg';
import { ReactComponent as TRANSLATOR_APP_ICON } from "./translate_icon.svg"
import { ReactComponent as PIN_FAV_ICON } from "./pin-fav-icon.svg"





export const SVGIcons = {
    CHARTS_ICON: CHARTS_ICON,
    DOCUMENT_ICON: DOCUMENT_ICON,
    HOME_ICON: HOME_ICON,
    LABEL_ICON: LABEL_ICON,
    PLANE_ICON: PLANE_ICON,
    PAPER_PLANE_ICON: PAPER_PLANE_ICON,
    RIGHT_CHEVRON_ICON: RIGHT_CHEVRON_ICON,
    LEFT_CHEVRON_ICON: LEFT_CHEVRON_ICON,
    TOP_CHEVRON_ICON: TOP_CHEVRON_ICON,
    DOWN_CHEVRON_ICON: DOWN_CHEVRON_ICON,
    CLOSE_ICON: CLOSE_ICON,
    ROSE_ICON: ROSE_ICON,
    VERT_ICON: VERT_ICON,
    BLEU_ICON: BLEU_ICON,
    REFRESH_ICON: REFRESH_ICON,
    MENU_DOTS_ICON: MENU_DOTS_ICON,
    MENU_RADIUS_ICON: MENU_RADIUS_ICON,
    MENU_LINE_ICON: MENU_LINE_ICON,
    DASHBOARD_HQ_ICON: DASHBOARD_HQ_ICON,
    DASHBOARD_BU_ICON: DASHBOARD_BU_ICON,
    TRAFFIC_BU_ICON : TRAFFIC_BU_ICON,
    RETAIL_BU_ICON: RETAIL_BU_ICON,
    FINANCE_BU_ICON: FINANCE_BU_ICON,
    AIRLINEID_BU_ICON: AIRLINEID_BU_ICON,
    SECURE_CHAT_ICON: SECURE_CHAT_ICON,
    NEWSLETTER_ICON: NEWSLETTER_ICON,
    TRANSLATOR_ICON: TRANSLATOR_ICON,
    SENTIMENT_ANALYSIS_ICON: SENTIMENT_ANALYSIS_ICON,
    DATA_ROOM_ICON: DATA_ROOM_ICON,
    CUSTOM_TRAFFIC_ICON: CUSTOM_TRAFFIC_ICON,
    CUSTOM_FINANCE_ICON: CUSTOM_FINANCE_ICON,
    COMPETITION_ANALYSIS_ICON: COMPETITION_ANALYSIS_ICON,
    ARROW_BLACK_ICON: ARROW_BLACK_ICON,
    ARROW_WHITE_ICON: ARROW_WHITE_ICON,
    LOGOUT_ICON: LOGOUT_ICON,
    ENGLISH_ICON: ENGLISH_ICON,
    LANGUAGE_ARROW_ICON: LANGUAGE_ARROW_ICON,
    CAR_PARKS_ICON: CAR_PARKS_ICON,
    COMMUNICATION_ICON: COMMUNICATION_ICON,
    ARROW_SMALL_ICON: ARROW_SMALL_ICON,
    SIZE_SCREEN_ICON: SIZE_SCREEN_ICON,
    DOWNLOAD_ICON: DOWNLOAD_ICON,
    LINKS_ICON: LINKS_ICON,
    UPLOAD_ICON: UPLOAD_ICON,
    SHAREPOINT_ICON: SHAREPOINT_ICON,
    HELP_ICON: HELP_ICON,
    EDG_ICON: EDG_ICON,
    EDG_LONG_ICON: EDG_LONG_ICON,
    EDG_LIGNE_ICON: EDG_LIGNE_ICON,
    FOLDED_NAV_ICON: FOLDED_NAV_ICON,
    EDG_TEXTNOIR_ICON: EDG_TEXTNOIR_ICON,
    STAR_ICON: STAR_ICON,
    CIRCLE_PATTERN_ICON: CIRCLE_PATTERN_ICON,
    FOLDERS_ICON: FOLDERS_ICON,
    LOGO_ICON: LOGO_ICON,
    CLOSE_SIDEBAR_ICON: CLOSE_SIDEBAR_ICON,
    OPEN_SIDEBAR_ICON: OPEN_SIDEBAR_ICON,
    DOCQA_ICON : DOCQA_ICON,
    DOCTRANS_ICON: DOCTRANS_ICON,
    PROMPTOPEDIA_ICON: PROMPTOPEDIA_ICON, 
    SECURECHAT_ICON : SECURECHAT_ICON,
    SENTIMENT_ICON : SENTIMENT_ICON,
    HOM3_ICON: HOM3_ICON,
    CREDIT_ICON: CREDIT_ICON,
    FRENCH_FLAG_ICON: FRENCH_FLAG_ICON,
    SPAIN_FLAG_ICON:SPAIN_FLAG_ICON,
    ENGLAND_FLAG_ICON: ENGLAND_FLAG_ICON,
    TRADUCTION_ICON: TRADUCTION_ICON,
    FACTORY_ICON: FACTORY_ICON,
    TRANSLATOR_APP_ICON: TRANSLATOR_APP_ICON,
    PIN_FAV_ICON: PIN_FAV_ICON,
}

export const ChatLogoIcon = {
    IA_CHAT_ICON: IA_CHAT_ICON,
    IA_CHAT_HOME_ICON: IA_CHAT_HOME_ICON,
    IA_CHAT_MULTIDOC: IA_CHAT_MULTIDOC,
    IA_CHAT_OAG: IA_CHAT_OAG,
    GPT_CHAT_ICON: GPT_CHAT_ICON,
    GPT_CHAT_ICON_XL: GPT_CHAT_ICON_XL,
    USER_CHAT_ICON: USER_CHAT_ICON,
    GEMINI_CHAT_ICON: GEMINI_CHAT_ICON,
    GPT4_CHAT_ICON: GPT4_CHAT_ICON,
    MISTRAL_CHAT_ICON: MISTRAL_CHAT_ICON
}