import DOMPurify from "dompurify";
import { useEffect, useState, useRef } from "react";
import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ChatLogo, ChatLogoType } from "../../../assets/SVGIcon/ChatLogo";
import { useTypingEffect } from "../../../hooks/typingEffect";
import { ButtonRedirectSDH } from "../../../components/ButtonRedirectSDH";
import { SVGIcon } from "../../../assets";
import { useHover } from "../hooks";
import { SavingPromptButton } from "../../../components/SavingPromptButton";
import { PromptProfile } from "../../../hooks/savingPromptProfile";

interface TypeToIconProps {
    IA: ChatLogoType;
    GPT: ChatLogoType;
    GPT4: ChatLogoType;
    MISTRAL: ChatLogoType;
    GEMINI: ChatLogoType;
    IA_HOME: ChatLogoType;
    IA_MULTIDOC: ChatLogoType;
}

export const TypeToIcon: TypeToIconProps = {
    IA: 'IA_CHAT_ICON',
    GPT: 'GPT_CHAT_ICON',
    GPT4: 'GPT4_CHAT_ICON',
    MISTRAL: 'MISTRAL_CHAT_ICON',
    GEMINI: 'GEMINI_CHAT_ICON',
    IA_HOME: 'IA_CHAT_HOME_ICON',
    IA_MULTIDOC: 'IA_CHAT_MULTIDOC',
}

export interface AnswerProps {
    type: keyof typeof TypeToIcon
    text: string;
    typed?: boolean
    loading?: boolean;
    pluginActive?: string;
}

export interface AnswerPropsError {
    type: keyof typeof TypeToIcon
}

export interface HTMLAnswerProps {
    type: keyof typeof TypeToIcon
    text: string;
    typed?: boolean
    pluginActive: string;
}

interface LoaderTextAnswerProps {
    type: keyof typeof TypeToIcon;
}

const createMarkup = (htmlString: string) => {
    return { __html: DOMPurify.sanitize(htmlString) };
};




export const TextAnswer: React.FC<AnswerProps> = ({ type, text, typed, loading, pluginActive }) => {
    const icon = TypeToIcon[type]
    const [typedText, isLoading] = useTypingEffect(text, 10)
    const [linksLoading, setLinksLoading] = useState(true);
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [hoverRef, isHovered] = useHover<HTMLDivElement>();

    useEffect(() => {
        const timer = setTimeout(() => {
            setLinksLoading(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, [typedText, text]);
    const syntaxHighlighterRef = useRef<SyntaxHighlighter>(null);



    function extractCleanTextFromHtml(htmlString: string): string {
        // Créer un élément div temporaire pour charger le HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;

        // Supprimer tous les éléments <style>
        tempDiv.querySelectorAll('style').forEach(style => style.remove());

        // Supprimer tous les attributs de style
        Array.from(tempDiv.querySelectorAll('*')).forEach(el => {
            el.removeAttribute('style'); // Retire les attributs de style
            el.removeAttribute('class'); // Retire les attributs de classe
        });

        // Ajouter des sauts de ligne pour les paragraphes et les éléments de liste
        Array.from(tempDiv.querySelectorAll('p, li')).forEach(element => {
            element.textContent += '\n'; // Ajoute un saut de ligne après le texte de chaque paragraphe ou élément de liste
        });

        // Extraire et retourner le texte nettoyé avec les sauts de ligne ajoutés
        return tempDiv.textContent || tempDiv.innerText || "";
    }

    const copyTextToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(extractCleanTextFromHtml(text));
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
            setIsCopied(false);
        }
    };


    return (

        <div ref={hoverRef} className="w-full text-token-text-primary" dir="auto" data-testid="conversation-turn-3" data-scroll-anchor="true">
            <div className="text-base py-[18px] px-3 md:px-4 m-auto md:px-5 lg:px-1 xl:px-5">
                <div className="mx-auto flex flex-1 gap-4 text-base md:gap-5 lg:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
                    <div className="flex-shrink-0 flex flex-col relative">
                        <div className="pt-0">
                            <div className={` flex h-8 w-8 items-center justify-center overflow-hidden ${type != "MISTRAL" && "rounded-full"}`}>
                                <div className="relative flex items-center justify-center bg-token-main-surface-primary text-token-text-primary h-8 w-8">
                                    <ChatLogo icon={icon} className={`${type != "MISTRAL" && "rounded-full"}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="group/conversation-turn relative flex w-full min-w-0 flex-col agent-turn">
                        <div className="flex-col gap-1 md:gap-3">

                            <div className="flex flex-grow flex-col max-w-full">
                                <div data-message-author-role="assistant" data-message-id="22ba3404-1774-4849-bfa0-619b8bd721e4" dir="auto" className="min-h-[20px] text-message flex w-full flex-col items-end gap-2 whitespace-pre-wrap break-words overflow-x-auto">
                                    <div className="flex w-full flex-col gap-1 empty:hidden first:pt-[3px]">

                                        <div className="markdown prose w-full break-words dark:prose-invert dark m-0">
                                            {typed ? (
                                                <ReactMarkdown
                                                    children={typedText}
                                                    remarkPlugins={[remarkGfm]}
                                                    rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                                    components={{
                                                        a: ({ node, ...props }) => <a {...props} target="_blank" rel="noopener noreferrer" style={{ color: linksLoading ? 'grey' : '', pointerEvents: linksLoading ? 'none' : 'auto' }} />
                                                    }}
                                                />
                                            ) : (
                                                <ReactMarkdown
                                                    children={text.replace(/<br>/g, '\n\n')}
                                                    remarkPlugins={[remarkGfm]}
                                                    rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                                    components={{
                                                        a: ({ node, ...props }) => <a {...props} target="_blank" rel="noopener noreferrer" style={{ color: linksLoading ? 'grey' : '', pointerEvents: linksLoading ? 'none' : 'auto' }} />,
                                                        p: ({ node, ...props }) => (
                                                            <p
                                                                {...props}
                                                                style={{ margin: '0' }}
                                                            />
                                                        ),
                                                        code(props) {
                                                            const { children, className, node, ...rest } = props
                                                            const match = /language-(\w+)/.exec(className || '')
                                                            return match ? (
                                                                <SyntaxHighlighter
                                                                    {...rest}
                                                                    PreTag="div"
                                                                    children={String(children).replace(/\n$/, '')}
                                                                    language={match[1]}
                                                                    style={oneDark}
                                                                    ref={syntaxHighlighterRef}
                                                                    showLineNumbers={true}
                                                                    wrapLines={true}
                                                                />
                                                            ) : (
                                                                <code {...rest} className={className}>
                                                                    {children}
                                                                </code>
                                                            )
                                                        }
                                                    }}
                                                />
                                            )}
                                            {
                                                loading &&
                                                <div className="">
                                                    <span className="relative flex h-3 w-3">
                                                        <span className="animate-ping absolute z-50 inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                                        <span className="relative inline-flex rounded-full h-3 w-3 bg-black"></span>
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                        <div className="w-fit p-2 min-h-8 cursor-pointer hover:bg-gray-100 rounded-md">
                                            {isHovered && (
                                                isCopied ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" color="#6b7280" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" >
                                                        <polyline points="20 6 9 17 4 12"></polyline> {/* Checked icon */}
                                                    </svg>
                                                ) : (
                                                    <svg onClick={copyTextToClipboard} xmlns="http://www.w3.org/2000/svg" color="#6b7280" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path> {/* Copy icon */}
                                                    </svg>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >


    );
};

export const TextAnswerHomeSDHPage: React.FC<AnswerProps> = ({ type, text, typed, loading }) => {
    const icon = TypeToIcon[type]
    const [typedText, isLoading] = useTypingEffect(text, 25)

    return (
        <div className="mb-12 flex p-1 mb-8 shadow-md rounded-xl bg-[#0D3D620D]">
            <div className="flex items-start mt-2 justify-center">
                <ChatLogo icon={icon} className="rounded-full" />
            </div>
            <div className="markdown flex flex-col text-sm pl-3 text-[#4A4A4A]">
                {typed ? (
                    <Markdown remarkPlugins={[remarkGfm]}>{typedText}</Markdown>
                ) : (
                    <Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown>
                )}
            </div>
        </div>

    );
};

export const TextAnswerError: React.FC<AnswerPropsError> = ({ type }) => {
    const icon = TypeToIcon[type]

    return (
        <div className="w-full text-token-text-primary" dir="auto" data-testid="conversation-turn-3" data-scroll-anchor="true">
            <div className="text-base py-[18px] px-3 md:px-4 m-auto lg:px-1 xl:px-5">
                <div className="mx-auto flex flex-1 gap-4 text-base md:gap-5 lg:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
                    <div className="flex-shrink-0 flex flex-col relative">
                        <div className="pt-0">
                            <div className={` flex h-8 w-8 items-center justify-center overflow-hidden ${type != "MISTRAL" && "rounded-full"}`}>
                                <div className="relative flex items-center justify-center bg-token-main-surface-primary text-token-text-primary h-8 w-8">
                                    <ChatLogo icon={icon} className={`${type != "MISTRAL" && "rounded-full"}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-1">
                        <p className="text-red-500" style={{ margin: '0' }}>An error has occurred</p>
                    </div>
                </div>
            </div>
        </div>
    );
};


export const LoaderTextAnswer: React.FC<LoaderTextAnswerProps> = ({ type }) => {
    const icon = TypeToIcon[type];

    return (
        <div className="mb-12 flex items-center p-5 mb-8 shadow-md rounded-xl bg-background-light">
            <ChatLogo icon={icon} className={`${type != "MISTRAL" ? "rounded-full" : "mt-1"}`} />
            <div className="ml-2">
                <span className="relative flex h-3 w-3">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-black"></span>
                </span>
            </div>
        </div>
    );
};

export const HTMLAnswer: React.FC<HTMLAnswerProps> = ({ type, text, pluginActive }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hovered, setHovered] = useState(false);
    const icon = TypeToIcon[type];
    const [typedTextHTML, isLoading] = useTypingEffect('Here is the newsletter! Click on the content to enlarge', 10)

    // useEffect(()=>{
    //     console.log('the text', text)
    // },[text])


    const handleDownloadPDF = () => {
        const input = document.getElementById('down');

        const customWidth = 1000;
        const customHeight = 1000;

        html2canvas(input!, { scale: 2, width: customWidth, height: customHeight }) // Use a higher scale for better resolution
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/jpeg', 0.8); // Adjust the quality here (0.0 - 1.0)
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'mm',
                    format: 'a4',
                });

                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
                const padding = 10;

                // Calculate positioning with padding
                const x = padding;
                const y = padding;
                const contentWidth = pdfWidth - padding;
                const contentHeight = pdfHeight - padding;

                // Calculate the best fit for the image in the PDF
                const imgAspectRatio = customWidth / customHeight;
                const pdfContentAspectRatio = contentWidth / contentHeight;
                let finalImgWidth, finalImgHeight;

                if (imgAspectRatio > pdfContentAspectRatio) {
                    // Image is wider than PDF content area
                    finalImgWidth = contentWidth;
                    finalImgHeight = contentWidth / imgAspectRatio;
                } else {
                    // Image is taller than PDF content area or same aspect ratio
                    finalImgWidth = contentHeight * imgAspectRatio;
                    finalImgHeight = contentHeight;
                }

                // Adjust x and y based on the final image size to center it
                const adjustedX = x + (contentWidth - finalImgWidth) / 6;
                const adjustedY = y + (contentHeight - finalImgHeight) / 6;

                pdf.addImage(imgData, 'JPEG', adjustedX, adjustedY, finalImgWidth, finalImgHeight);

                pdf.save('newsletter.pdf');
            });
    };


    const createMarkup = () => {
        return { __html: text };
    };

    const handleMouseOver = () => {
        setHovered(true);
    };

    const handleMouseOut = () => {
        setHovered(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <div
                className={`mb-4 flex flex-col px-6 py-4 shadow-md rounded-xl bg-[#0D3D62] bg-opacity-5`}
            >
                <div className="flex items-center justify-start">
                    <ChatLogo icon={icon} />
                    <p className="flex text-sm pl-3 text-[#4A4A4A]">{typedTextHTML}</p>
                </div>
                <div className={`relative cursor-pointer pb-14 items-center bg-white my-2 rounded-xl max-h-48 overflow-hidden ${hovered && ' shadow-2xl'}`}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    onClick={handleOpenModal}
                >
                    <div className="p-5" dangerouslySetInnerHTML={createMarkup()} />
                    <div className="absolute bottom-0 right-0 p-1.5">
                        <SVGIcon size="20px" icon="SIZE_SCREEN_ICON" />
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <div
                    className="cursor-pointer fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 transition-all duration-300 ease-in-out pt-6 pb-6"
                    onClick={handleCloseModal}
                >
                    <div
                        className="cursor-text bg-white p-16 max-w-5xl max-h-full overflow-auto transform transition-transform duration-300 ease-in-out rounded rounded-xl"
                        style={{
                            transform: isModalOpen ? 'scale(1)' : 'scale(0.5)'
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div id="down" dangerouslySetInnerHTML={createMarkup()} />
                        {/* <button className="mt-4 p-2 text-white bg-blue-500 hover:bg-blue-700 rounded" onClick={handleDownloadPDF}>
                            Download Newsletter as PDF
                        </button> */}
                    </div>
                </div>
            )}
        </div>
    );
};

interface AskProps {
    text: string;
    savedPrompts: PromptProfile[];
}

export const Ask: React.FC<AskProps> = ({ text,savedPrompts }) => {

    return (
        <div className="w-full text-token-text-primary" dir="auto" data-testid="conversation-turn-2" data-scroll-anchor="false">
            <div className="text-base py-[18px] px-3 md:px-4 m-auto md:px-5 lg:px-1 xl:px-5">
                <div className="mx-auto flex flex-1 gap-4 text-base md:gap-5 lg:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
                    <div className="group/conversation-turn relative flex w-full min-w-0 flex-col">
                        <div className="flex-col gap-1 md:gap-3">
                            <div className="flex flex-grow flex-col max-w-full">
                                <div className="relative min-h-[20px] text-message flex w-full flex-col items-end gap-2 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5">
                                    <div className="relative flex p-8 w-full flex-col gap-1 empty:hidden items-end rtl:items-start">
                                        <div className="relative max-w-[70%] rounded-3xl bg-[#f4f4f4] px-9 py-2.5 dark:bg-token-main-surface-secondary">
                                            <div>
                                                {text}
                                            </div>
                                            <div className="absolute bottom-0 right-full top-0 -mr-3.5 hidden pr-5 pt-1 [.group\/conversation-turn:hover_&amp;]:block">
                                                <span className="" data-state="closed">
                                                    <button className="flex h-9 w-9 items-center justify-center rounded-full text-token-text-secondary transition hover:bg-token-main-surface-tertiary">
                                                        <ChatLogo icon="USER_CHAT_ICON" />
                                                    </button>
                                                </span>
                                            </div>
                                            <div className="absolute left-2 px-2 py-3">
                                                <SavingPromptButton prompt={text} savedPrompts={savedPrompts}/>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

