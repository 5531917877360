import React, { useState, useEffect } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useAuth } from "../../providers";
import { LoaderComponent } from "../../components";

interface EmbedConfig {
  type: "report" | "dashboard";
  id: string;
  embedUrl: string;
  accessToken: string;
  tokenType: models.TokenType;
  settings: {
    filterPaneEnabled: boolean;
    navContentPaneEnabled: boolean;
  };
}

export const PowerBI = ({ report }: { report: string }) => {
  const [embedConfig, setEmbedConfig] = useState<EmbedConfig | null>(null);
  const { token } = useAuth()
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`/api/dashboard/${report}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setEmbedConfig({
          type: "report",
          id: data.reports[0].id,
          embedUrl: data.reports[0].embedUrl,
          accessToken: data.token,
          tokenType: models.TokenType.Embed,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
          },
        });
      })
      .catch((error) => console.error("Error fetching data:", error))
  }, [report, token]);

  return (
    <>
      <LoaderComponent loadingConfig={loading}>
        <></>
      </LoaderComponent>
      {embedConfig && (
        <div className={`flex flex-col h-full max-w-full items-center`} id="capture-area">
          <PowerBIEmbed
            
            embedConfig={embedConfig}
            eventHandlers={
              new Map([
                ["loaded", function () { if (report == "finance"){setLoading(false);};console.log("Report loaded"); }],
                ["rendered", function () { setLoading(false); console.log("Report rendered"); }],
                ["error", function (event) { console.error(event && event.detail); }],
              ])
            }
            cssClassName={"report-style-class"}
          />
        </div>
      )}
    </>

  );
};

export default PowerBI;
