import { useRef } from "react";
import { SVGIcon, Section, Tabs, isSection } from "../../assets";
import { useActiveTabs } from "../../providers";
import { SideBarSection } from "./SideBarSection";
import { SideBarTabs } from "./SideBarTabs";
import { useHover } from "../../hooks";

export const SideBarMenu = ({ isSidebarOpen }: { isSidebarOpen: Boolean }) => {

  const { menu } = useActiveTabs()

  return (
    <nav className={`${isSidebarOpen}`}>
      <ul>
        {menu?.filter((elem: Section | Tabs) => {
            if (!isSection(elem) && elem.id === 3) {
              return false;
            }
            return true;
          }).map((elem: Section | Tabs, elemIndex) => (
          <>
          <div key={elemIndex} className="relative w-full px-3 group hover:bg-welcomeBackground">
            {isSection(elem) ? (
              <SideBarSection
                section={elem}
                isSidebarOpen={isSidebarOpen}
              />
            ) : (
                <SideBarTabs
                  tabs={elem}
                  isSidebarOpen={isSidebarOpen}
                  isBelongToSection={false}
                />
            )}
          </div>
          </>
        ))}
      </ul>
    </nav>
  );
};
