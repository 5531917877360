export const color = {
  dark: {
    primaryColor: "#343540",
    secondaryColor: "#343540",
    backgroundColor: "#202123",
    primaryText: "#FFFFFF",
    secondaryText: "#FFFFFF"
  },
  light: {
    primaryColor: "#C6DEF0",
    secondaryColor: "#1A4384",
    backgroundColor: "#FFFFFF",
    primaryText: "#1A4283",
    secondaryText: "#FFFFFF"
  },
};

export type Palette = (typeof color)[keyof typeof color];

export type Theme =  keyof typeof color;
