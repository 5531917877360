import DOMPurify from "dompurify";
import { ChatLogo, ChatLogoType } from "../../assets/SVGIcon/ChatLogo";
import { useEffect, useState, useRef } from "react";
import { useTypingEffect } from "../../hooks/typingEffect";
import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import { ButtonRedirectSDH } from "../ButtonRedirectSDH";
import { SVGIcon } from "../../assets";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import ReactMarkdown from 'react-markdown';
import { SavingPromptButton } from "../SavingPromptButton";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";



interface TypeToIconProps {
    IA: ChatLogoType;
    GPT: ChatLogoType;
    IA_HOME: ChatLogoType;
    IA_MULTIDOC: ChatLogoType;
    GPT4: ChatLogoType;
    GEMINI: ChatLogoType;
    MISTRAL: ChatLogoType;

}

export const TypeToIcon: TypeToIconProps = {
    IA: 'IA_CHAT_ICON',
    GPT: 'GPT_CHAT_ICON',
    IA_HOME: 'IA_CHAT_HOME_ICON',
    IA_MULTIDOC : 'IA_CHAT_MULTIDOC',
    GPT4: 'GPT4_CHAT_ICON',
    GEMINI: 'GEMINI_CHAT_ICON',
    MISTRAL : 'MISTRAL_CHAT_ICON',
}

export interface AnswerProps {
    type: keyof typeof TypeToIcon
    text: string;
    typed?: boolean
    loading?: boolean;
    pluginActive? : string;
    model: string;
}

export interface AnswerPropsError {
    type: keyof typeof TypeToIcon
    model: string;
}

export interface HTMLAnswerProps {
    type: keyof typeof TypeToIcon
    text: string;
    typed?: boolean
    pluginActive: string;
}

interface LoaderTextAnswerProps {
    type: keyof typeof TypeToIcon;
    model: string;
}

const createMarkup = (htmlString: string) => {
    return { __html: DOMPurify.sanitize(htmlString) };
};

type ToolIdMapping = {
    [key: string]: number[];
};

const toolIdMapping: ToolIdMapping = {
    get_traffic_report: [11, 5],
    get_finance_report: [13, 6],
    get_news: [], 
    competition_analysis: [11, 5],
    predict_analyst: [11, 5],
};


export const TextAnswer: React.FC<AnswerProps> = ({ type, text, typed, loading,pluginActive, model }) => {
    const iconKey = model.toUpperCase() as keyof typeof TypeToIcon;
    const icon = TypeToIcon[iconKey]
    // const [typedText,isLoading] = useTypingEffect(text, 10)
    const ids = useRef<number[]>(toolIdMapping[pluginActive!] || []);
    const [linksLoading, setLinksLoading] = useState(true);
    const [isCopied, setIsCopied] = useState<boolean>(false);
    
    const copyTextToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(text);
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 1000);
        } catch (err) {
          console.error('Failed to copy text: ', err);
          setIsCopied(false);
        }
    }

    // useEffect(()=>{
    //     if(pluginActive){
    //         setIds(toolIdMapping[pluginActive] || [])
    //     }
    // },[pluginActive])

    useEffect(() => {
        const timer = setTimeout(() => {
            setLinksLoading(false);
        }, 6000);  

        return () => clearTimeout(timer);
    }, [text]); 

    const syntaxHighlighterRef = useRef<SyntaxHighlighter>(null);

    return (
        <div className="mb-12 flex flex-col p-4 mb-10 shadow-md rounded-xl bg-background-light">
            <div className="flex flex-row relative">
                <div className="flex items-start mt-2 justify-center">
                    <ChatLogo icon={icon} className="rounded-full" />
                </div>
                <div className={`markdown flex flex-col text-sm pl-3 text-[#4A4A4A]`}>
                {typed ? (
                        <ReactMarkdown
                          children={text}
                          remarkPlugins={[remarkGfm]}
                          rehypePlugins={[rehypeRaw, rehypeSanitize]}
                          components={{
                            a: ({node, ...props}) => <a {...props} target="_blank" rel="noopener noreferrer" style={{ color: linksLoading ? 'grey' : 'blue', pointerEvents: linksLoading ? 'none' : 'auto' }} />
                          }}
                        />
                    ) : (
                        <ReactMarkdown
                            children={text.replace(/<br>/g, '\n\n')}
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw, rehypeSanitize]}
                            components={{
                                a: ({ node, ...props }) => <a {...props} target="_blank" rel="noopener noreferrer" style={{ color: linksLoading ? 'grey' : '', pointerEvents: linksLoading ? 'none' : 'auto' }} />,
                                code(props) {
                                    const { children, className, node, ...rest } = props
                                    const match = /language-(\w+)/.exec(className || '')
                                    return match ? (
                                        <SyntaxHighlighter
                                            {...rest}
                                            PreTag="div"
                                            children={String(children).replace(/\n$/, '')}
                                            language={match[1]}
                                            style={oneDark}
                                            ref={syntaxHighlighterRef}
                                            showLineNumbers={true}
                                            wrapLines={true}
                                        />
                                    ) : (
                                        <code {...rest} className={className}>
                                            {children}
                                        </code>
                                    )
                                }
                            }}
                        />

                    )}
                    {
                        loading &&
                        <div className="">
                            <span className="relative flex h-3 w-3">
                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                <span className="relative inline-flex rounded-full h-3 w-3 bg-black"></span>
                            </span>
                        </div>
                    }
                </div>
                <div className="text-sm absolute flex flex-row gap-1 items-center bottom-0 right-0 p-3.5">
                {isCopied ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="absolute top-0 right-0 m-2 cursor-pointer">
                            <polyline points="20 6 9 17 4 12"></polyline> {/* Checked icon */}
                        </svg>
                    ) : (
                        <svg onClick={copyTextToClipboard} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="absolute top-0 right-0 m-2 cursor-pointer">
                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path> {/* Copy icon */}
                        </svg>
                )}
            </div> 
            </div>
            {ids.current.length > 0 && 
                <div className="flex flex-row w-full jusitfy-between items-start mt-2.5 gap-4">
                    {ids.current.map((id)=>(
                        <ButtonRedirectSDH id={id}/>
                    ))}
                </div>
            }
        </div>

    );
};

export const TextAnswerHomeSDHPage: React.FC<AnswerProps> = ({ type, text, typed, loading }) => {
    const icon = TypeToIcon[type]
    const [typedText,isLoading] = useTypingEffect(text, 25)

    return (
        <div className="flex p-1 rounded-xl bg-homeGPTCardBg text-homeGPTCardText">
            <div className="flex items-start mt-2 justify-center">
                <ChatLogo icon={icon} className="rounded-full" />
            </div>
            <div className="markdown flex flex-col text-sm pl-3">
                {typed ? (
                    <Markdown remarkPlugins={[remarkGfm]}>{typedText}</Markdown>
                ) : (
                    <Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown>
                )}
            </div>
        </div>

    );
};

export const TextAnswerError: React.FC<AnswerPropsError> = ({ type, model }) => {
    const iconKey = model.toUpperCase() as keyof typeof TypeToIcon;
    const icon = TypeToIcon[iconKey]

    return (
        <div className="mb-12 flex p-5 mb-8 shadow-md rounded-xl bg-background-light">
            <div className="flex items-start">
                <ChatLogo icon={icon} className="rounded-full" />
            </div>
            <p className="flex text-sm pl-3 text-red-500 mt-1">An error has occurred</p>
        </div>

    );
};


 export const LoaderTextAnswer: React.FC<LoaderTextAnswerProps> = ({ type,model }) => {
    const iconKey = model.toUpperCase() as keyof typeof TypeToIcon;
    const icon = TypeToIcon[iconKey];
    
    return (
        <div className="mb-12 flex items-center p-5 mb-8 shadow-md rounded-xl bg-background-light">
            <ChatLogo icon={icon} className="rounded-full" />
            <div className="ml-2">
                <span className="relative flex h-3 w-3">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-black"></span>
                </span>            
            </div>
        </div>
    );
};

export const HTMLAnswer: React.FC<HTMLAnswerProps> = ({ type, text,pluginActive }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hovered, setHovered] = useState(false);
    const icon = TypeToIcon[type];
    const [typedTextHTML,isLoading] = useTypingEffect('Here is the newsletter! Click on the content to enlarge', 10)
    const ids = useRef(toolIdMapping[pluginActive] || [])


    // useEffect(()=>{
    //     console.log('the text', text)
    // },[text])

    
    const handleDownloadPDF = () => {
        const input = document.getElementById('down');
      
        const customWidth = 1000;
        const customHeight = 1000;
      
        html2canvas(input!, { scale: 2, width: customWidth, height: customHeight }) // Use a higher scale for better resolution
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 0.8); // Adjust the quality here (0.0 - 1.0)
            const pdf = new jsPDF({
              orientation: 'portrait',
              unit: 'mm',
              format: 'a4',
            });
      
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const padding = 10; 
      
            // Calculate positioning with padding
            const x = padding;
            const y = padding;
            const contentWidth = pdfWidth - padding;
            const contentHeight = pdfHeight - padding;
      
            // Calculate the best fit for the image in the PDF
            const imgAspectRatio = customWidth / customHeight;
            const pdfContentAspectRatio = contentWidth / contentHeight;
            let finalImgWidth, finalImgHeight;
      
            if (imgAspectRatio > pdfContentAspectRatio) {
              // Image is wider than PDF content area
              finalImgWidth = contentWidth;
              finalImgHeight = contentWidth / imgAspectRatio;
            } else {
              // Image is taller than PDF content area or same aspect ratio
              finalImgWidth = contentHeight * imgAspectRatio;
              finalImgHeight = contentHeight;
            }
      
            // Adjust x and y based on the final image size to center it
            const adjustedX = x + (contentWidth - finalImgWidth) / 6;
            const adjustedY = y + (contentHeight - finalImgHeight) / 6;
      
            pdf.addImage(imgData, 'JPEG', adjustedX, adjustedY, finalImgWidth, finalImgHeight);
      
            pdf.save('newsletter.pdf');
          });
      };
      

    const createMarkup = () => {
        return { __html: text };
    };

    const handleMouseOver = () => {
        setHovered(true);
    };

    const handleMouseOut = () => {
        setHovered(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <div 
                className={`mb-4 flex flex-col px-6 py-4 shadow-md rounded-xl bg-[#0D3D62] bg-opacity-5`}
            >
                <div className="flex items-center justify-start">
                    <ChatLogo icon={icon} />
                    <p className="flex text-sm pl-3 text-[#4A4A4A]">{typedTextHTML}</p>
                </div>
                <div className={`relative cursor-pointer pb-14 items-center bg-white my-2 rounded-xl max-h-48 overflow-hidden ${hovered && ' shadow-2xl'}`}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    onClick={handleOpenModal}
                >
                    <div className="p-5" dangerouslySetInnerHTML={createMarkup()} />
                    <div className="absolute bottom-0 right-0 p-1.5">
                        <SVGIcon size="20px" icon="SIZE_SCREEN_ICON"/>
                    </div>
                </div>
                {ids.current.length > 0 && 
                    <div className="flex flex-row w-full jusitfy-between items-start mt-2.5 gap-4">
                        {ids.current.map((id)=>(
                            <ButtonRedirectSDH id={id}/>
                        ))}
                    </div>
                }
            </div>

            {isModalOpen && (
                <div 
                    className="cursor-pointer fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 transition-all duration-300 ease-in-out pt-6 pb-6"
                    onClick={handleCloseModal}
                >
                    <div 
                        className="cursor-text bg-white p-16 max-w-5xl max-h-full overflow-auto transform transition-transform duration-300 ease-in-out rounded rounded-xl"
                        style={{
                            transform: isModalOpen ? 'scale(1)' : 'scale(0.5)'
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div id="down" dangerouslySetInnerHTML={createMarkup()} />
                        {/* <button className="mt-4 p-2 text-white bg-blue-500 hover:bg-blue-700 rounded" onClick={handleDownloadPDF}>
                            Download Newsletter as PDF
                        </button> */}
                    </div>
                </div>
            )}
        </div>
    );
};