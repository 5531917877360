import { SVGIcon } from "../SVGIcon";
import { DocumentTranslator, PowerBI } from "../../pages";
import { SecureChat } from "../../pages/SecureChat";
import { Home } from "../../pages/Home";
import { HomeSDH } from "../../pages/HomeSDH";
import { SentimentAnalysis } from "../../pages/SentimentAnalysis";
import { LargeDoc } from "../../pages/LargeDoc";
import { useRef } from "react";
import { useHover } from "../../hooks";
import { PromptDrive } from "../../pages/PromptDrive";
import { translations } from "../../translations";
import { useAuth } from "../../providers";
import { StreamlitApp } from "../../pages/StreamlitApp";
import { ChatGPT } from "../../pages/ChatGPT";

export type Tabs = {
  id: number;
  appId: string;
  title: string;
  icon: JSX.Element;
  description?: string[];
  app: () => JSX.Element;
};

export type Section = {
  title: string;
  icon: JSX.Element;
  tabs: Tabs[];
};

export type Menu = (Section | Tabs)[];

export function isSection(menuItem: Section | Tabs): menuItem is Section {
  return (menuItem as Section).tabs !== undefined;
}

export const homeTab: Tabs = {
  id: 0,
  appId: "Home",
  title: "Home",
  icon: <SVGIcon size="44px" icon="HOM3_ICON" />,
  app: () => <HomeSDH/>,
}

export const homeTabFR: Tabs = {
  id: 0,
  appId: "Home",
  title: "Accueil",
  icon: <SVGIcon size="44px" icon="HOM3_ICON" />,
  app: () => <HomeSDH/>,
}
export const homeTabES: Tabs = {
  id: 0,
  appId: "Home",
  title: "Inicio",
  icon: <SVGIcon size="44px" icon="HOM3_ICON" />,
  app: () => <HomeSDH/>,
}

export const dashboardsTabsBU: Section = {
  title: "Dashboards BU",
  icon: <SVGIcon size="18px" icon="DASHBOARD_BU_ICON" />,
  tabs: [
    {
      id: 10,
      appId: "Dashboard/Executive",
      title: "Executive",
      icon: <SVGIcon size="16px" icon="AIRLINEID_BU_ICON" />,
      description:
      ["Monthly P&L", "Budgets", "Customer Revenues", "Account receivables"],     
      app: () => <PowerBI report="executive" />,
    },
    {
      id: 11,
      appId: "Dashboard/Traffic",
      title: "Traffic",
      icon: <SVGIcon size="16px" icon="PLANE_ICON" />,
      description:["Daily traffic data", "Budgets", "OAG", "Traffic Predict"],      
      app: () => <PowerBI report="traffic" />,
    },
    {
      id: 12,
      appId: "Dashboard/Retail",
      title: "Retail",
      icon: <SVGIcon size="16px" icon="LABEL_ICON" />,
      description:
      ["Monthly retail data", "EPOS"],     
      app: () => <PowerBI report="retail" />,
    },
    {
      id: 13,
      appId: "Dashboard/Finance",
      title: "Finance",
      icon: <SVGIcon size="16px" icon="FINANCE_BU_ICON" />,
      description:
      ["Monthly P&L", "Budgets", "Customer Revenues", "Account receivables"],     
      app: () => <PowerBI report="finance" />,
    },
    {
      id: 14,
      appId: "Dashboard/AirlineID",
      title: "Airline ID",
      icon: <SVGIcon size="16px" icon="AIRLINEID_BU_ICON" />,
      description:
      ["Airline Data", "Traffic KPIs", "OPS KPIs", "Finance KPIs"],
      app: () => <PowerBI report="airline" />,
    },
    {
      id: 15,
      appId: "Dashboard/Car Parks",
      title: "Car Parks",
      icon: <SVGIcon size="16px" icon="CAR_PARKS_ICON" />,   
      app: () => <PowerBI report="carparks" />,
    },
    {
      id: 16,
      appId: "Dashboard/HR",
      title: "Human Resources",
      icon: <SVGIcon size="16px" icon="PLANE_ICON" />,   
      app: () => <PowerBI report="hr" />,
    },
    {
      id: 17,
      appId: "Dashboard/Operations",
      title: "Operations",
      icon: <SVGIcon size="16px" icon="PLANE_ICON" />,   
      app: () => <PowerBI report="operations" />,
    },
    {
      id: 18,
      appId: "Dashboard/Communication",
      title: "Communication",
      icon: <SVGIcon size="18px" icon="COMMUNICATION_ICON" />,   
      app: () => <PowerBI report="communication" />,
    },
  ],
}



export const getAppMenu = (appNames: string[] | null, language: string): Menu => {
  if (!appNames) { return [homeTab] }

  const localizedMenuData: Menu = [
    // {
    //   id: 1,
    //   appId: "secure_chat",
    //   title: translations.app_names[language as keyof typeof translations.app_names].secure_chat,
    //   description: ["Same ChatGPT as public model but on a private and secure environment feed."],
    //   icon: <SVGIcon size="40px" icon="SECURECHAT_ICON" />,
    //   app: () => <SecureChat />,
    // },
    {
      id: 10,
      appId: "secure_chat",
      title: translations.app_names[language as keyof typeof translations.app_names].secure_chat,
      description: ["Same ChatGPT as public model but on a private and secure environment feed."],
      icon: <SVGIcon size="40px" icon="SECURECHAT_ICON" />,
      app: () => <ChatGPT />,
    },
    {
      id: 9,
      appId: "prompt_drive",
      title: translations.app_names[language as keyof typeof translations.app_names].prompt_drive,
      icon: <SVGIcon size="40px" icon="PROMPTOPEDIA_ICON" />,
      app: () => <PromptDrive />,
    },
    {
      id: 3,
      appId: "document_translator",
      title: translations.app_names[language as keyof typeof translations.app_names].document_translator,
      icon: <SVGIcon size="40px" icon="DOCTRANS_ICON" />,
      app: () => <DocumentTranslator />,
    },
    {
      id: 4,
      appId: "sentiment_analysis",
      title: translations.app_names[language as keyof typeof translations.app_names].sentiment_analysis,
      icon: <SVGIcon size="40px" icon="SENTIMENT_ICON" />,
      app: () => <SentimentAnalysis />,
    },
    {
      id: 7,
      appId: "document_question",
      title: translations.app_names[language as keyof typeof translations.app_names].document_question,
      icon: <SVGIcon size="40px" icon="DOCQA_ICON" />,
      app: () => <LargeDoc />,
    },
    // {
    //   id: 1000,
    //   appId: "document_question",
    //   title: "Streamlit",
    //   icon: <SVGIcon size="40px" icon="SENTIMENT_ICON" />,   
    //   app: () => <StreamlitApp />,
    // },

  ];

  return localizedMenuData.reduce((filteredMenu, item) => {
    if ('appId' in item && appNames.includes(item.appId)) {
      filteredMenu.push(item);
    } else if ('tabs' in item) {
      const filteredTabs = item.tabs.filter(tab => appNames.includes(tab.appId));
      if (filteredTabs.length > 0) {
        filteredMenu.push({ ...item, tabs: filteredTabs });
      }
    }
    return filteredMenu;
  }, [homeTab] as Menu);
};