import React, { useEffect, useState } from "react";
import { PromptData } from "./PromptDrive";
import { SVGIcon, Tabs } from "../../assets";
import { SecureChat } from "../SecureChat";
import { useActiveTabs } from "../../providers";
import { translations } from "../../translations";
import { ChatGPT } from "../ChatGPT";
import { useChatGPT } from "../ChatGPT/providers";
import { ChatModel } from "../ChatGPT/hooks";

interface ModalCopyPromptProps {
    closeCopyPromptModal: () => void
    data: PromptData;
    language: string;
}

export const ModalCopyPrompt: React.FC<ModalCopyPromptProps> = ({ data, closeCopyPromptModal,language }) => {
    const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
    const [contentCopied, setContentCopied] = useState<boolean>(false)
    const {openTab, closeTab} = useActiveTabs()
    const translationTexts = translations['promptopedia'][language as keyof typeof translations['promptopedia']];
    const {gptInput,chatGPTAsk,gptConfig,gptModel} = useChatGPT()

    const openInSecureChat = () =>{
        const content = assembleContentWithValues(data.promptContent); 
        const secureGPTTab : Tabs = {
            id: 10,
            appId: "secure_chat",
            title: "Secure Chat",
            description: ["Same ChatGPT as public model but on a private and secure environment."],
            icon: <SVGIcon size="20px" icon="SECURE_CHAT_ICON" />,
            app: () => <ChatGPT/>,
        }
        closeTab(10)
            openTab(secureGPTTab)
            gptInput.askGPTStream(
                chatGPTAsk.setErrorRequestChpt,
                gptConfig.selectedChatId,
                content,
                data.model as ChatModel,
                chatGPTAsk.setLoadingGPT,
                gptConfig,
            )
            gptModel.setSelectedChatModel(data.model! as ChatModel)
            setInputValues({})
            closeCopyPromptModal()
    }

    useEffect(() => {
        const variableRegex = /{{(.*?)}}/g;
        const initialValues: { [key: string]: string } = {};
        let match;

        while ((match = variableRegex.exec(data.promptContent)) !== null) {
            const varName = match[1];
            initialValues[varName] = '';
        }

        setInputValues(initialValues);
    }, [data.promptContent]);

    const handleChange = (key: string, value: string) => {
        setInputValues({ ...inputValues, [key]: value });
    };
    
    const assembleContentWithValues = (content: string) => {
        return content.replace(/{{(.*?)}}/g, (_, varName) => inputValues[varName] || '');
    };

    const handleCopy = () => {
        const content = assembleContentWithValues(data.promptContent);
        navigator.clipboard.writeText(content).then(() => {
            setContentCopied(true)
            setTimeout(()=>{
                setContentCopied(false)
            },1000)
        }, (err) => {
            console.error('Failed to copy text: ', err);
        });
    };

    const allInputsFilled = Object.values(inputValues).every(value => value.trim() !== '');

    const renderPromptContent = (content: string) => {
        const variableRegex = /{{(.*?)}}/g;
        let match;
        const elements = [];
        let lastIndex = 0;

        while ((match = variableRegex.exec(content)) !== null) {
            const [fullMatch, varName] = match;
            const index = match.index;

            if (index > lastIndex) {
                elements.push(content.substring(lastIndex, index));
            }

            elements.push(
                <input
                    key={varName}
                    type="text"
                    className="border border-inputBorder text-textSecondary rounded-md px-2 py-1 m-1 focus:outline-none"
                    value={inputValues[varName] || ''}
                    onChange={(e) => handleChange(varName, e.target.value)}
                    placeholder={varName}
                />
            );

            lastIndex = index + fullMatch.length;
        }

        if (lastIndex < content.length) {
            elements.push(content.substring(lastIndex));
        }

        return elements;
    };

    return (
        <div className="absolute backdrop-blur-md inset-0 z-9999 flex justify-center items-center" style={{ zIndex: 9 }}>
            <div className="flex flex-col gap-4 w-[70%] max-h-[70%] items-start inset-0 border border-dropdownBorder bg-welcomeBackground bg-opacity-70 rounded-xl p-6 relative overflow-scroll no-scrollbar">
                <div className="p-1 absolute top-0 right-1 cursor-pointer transform transition-transform hover:rotate-180" onClick={closeCopyPromptModal}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="white"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </div>
                <div className="w-full overflow-scroll min-h-[60%] text-textPrimary">
                    {renderPromptContent(data.promptContent)}
                </div>
                <div className="flex flex-row items-end gap-3">
                    <button
                        onClick={handleCopy}
                        disabled={!allInputsFilled}
                        className={`border px-4 py-1.5 text-buttonText border-black rounded-md bg-buttonBg hover:shadow-xl ${!allInputsFilled ? 'bg-gray-600 cursor-not-allowed text-opacity-60' : ''}`}
                    >
                        {contentCopied ? translationTexts.copy_done_modal: translationTexts.copy_folder_modal}
                    </button>
                    <button
                        onClick={openInSecureChat}
                        disabled={!allInputsFilled}
                        className={`border px-4 py-1.5 text-buttonText border-buttonBorder rounded-md bg-buttonBg hover:shadow-xl ${!allInputsFilled ? 'bg-gray-600 cursor-not-allowed text-opacity-60' : ''}`}
                    >
                        {translationTexts.send_copy_modal}
                    </button>
                </div>
            </div>
        </div>
    );
};