import { useActiveTabs, useAuth } from "../../../providers";
import { useChatGPT } from "../providers";
import { BookIcon, ParamsIcon } from "./Icon";
import { translations } from "../../../translations";
import { SVGIcon } from "../../../assets";

export const ChatGPTModalDocument = ({ }: {}) => {
    const {
        modalStatus,
        setModalStatus
    } = useChatGPT();

    const {language} = useAuth()
    const langKey = language as keyof typeof translations['secure_chat_new'];
    const {returnFav} = useActiveTabs()

    const fav = returnFav([3])

    const apps = [
        {id:3, title: translations['secure_chat_new'][langKey]['translator'], description: translations['secure_chat_new'][langKey]['translator_des'], icon: <SVGIcon icon="TRANSLATOR_APP_ICON"/>, action: () => { setModalStatus("TRANSLATOR") } },
        {id:7, title: translations['secure_chat_new'][langKey]['multi_docs'], description: translations['secure_chat_new'][langKey]['multi_doc_des'], icon: <BookIcon /> },
    ]

    return (
        <div className="">
            <div className="flex items-center w-full p-2.5 text-sm cursor-pointer focus-visible:outline-0 radix-disabled:pointer-events-none radix-disabled:opacity-50 group relative focus-visible:bg-[#f5f5f5] rounded-md my-0 px-3 mx-2 radix-state-open:bg-[#f5f5f5] gap-2.5 py-3 !pr-3">
                <span className="text-sm text-gray-400">{translations['secure_chat_new'][langKey]['parameters']}</span>
            </div>
            {
                fav.map((e, i) => {
                    const app = apps.find(item => item.id === e.id)
                    return (
                        <div className="flex items-center w-full text-sm cursor-pointer focus-visible:outline-0 hover:bg-gray-50 focus-visible:bg-[#f5f5f5] rounded-md gap-2.5 py-3 px-6" onClick={app?.action} >
                            <div className="flex grow items-center justify-between gap-2">
                                <div>
                                    <div className="flex items-center gap-3">
                                        <span className="flex scale-[1.5] h-10 w-10 flex-shrink-0 items-center justify-center text-[#7d7d7d]">
                                            {app?.icon}
                                        </span>
                                        <div className="text-sm">
                                            {app?.title}
                                            <div className="text-[#7d7d7d] text-xs">{app?.description}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }

        </div>
    )
}