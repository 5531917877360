import { ChatGPTBottom, ChatGPTModalParams, ChatGPTModalChoiceModal, ChatGPTTalk, ChatGPTTop, ChatGPTModalDocument, ChatGPTModalParameters } from "./components";
import { ChatGPTProvider, useChatGPT } from "./providers"
import { DocumentTranslator } from "../DocumentTranslator";
import { useEffect } from "react";
import { ChatGPTMiddleModalDocuments } from "./components/ChatGPTMiddleModalDocuments";
import { ChatGPTModal } from "./components/ChatGPTModal";

export const ChatGPT = () => {
  return (
    <ChatGPTContent />
  );
};

const ChatGPTContent = ({ }: {}) => {

  const {
    gptInput,
    gptGesture,
  } = useChatGPT();

  useEffect(() => {
    gptGesture.setIsMounted(true)
    gptInput.setIsMounted(true)
  }, [])

  return (
    <>
    {/*  <leftModal.Modal position="left-3 top-14"><ChatGPTModalChoiceModal /></leftModal.Modal>
      <rightModal.Modal position="right-3 top-14" ><ChatGPTModalParams /></rightModal.Modal>
      <documentModal.Modal style={{ left: `${gptInput.chatGPTInputPosition.x}px`, bottom: `${gptInput.chatGPTInputPosition.height}px`, }} ><ChatGPTModalDocument /></documentModal.Modal>
      <middleModal.Modal position="inset-0 flex justify-center items-center" style={{background: "rgba(0, 0, 0, 0.25)"}}><ChatGPTModalParameters /></middleModal.Modal>
      <documentMiddleModal.Modal position="inset-0 flex justify-center items-center" style={{background: "rgba(0, 0, 0, 0.25)"}}><ChatGPTMiddleModalDocuments /></documentMiddleModal.Modal>*/}
      <ChatGPTModal />
      <main className="relative h-full w-full flex-1 overflow-auto transition-width bg-white">
        <div className="flex h-full flex-col focus-visible:outline-0 bbg-red-400">
          <div className="flex-1 overflow-hidden h-full">
            <div className="h-full overflow-auto">
              <div className="flex flex-col text-sm md:pb-9">
                <ChatGPTTop />
                <ChatGPTTalk />
              </div>
            </div>
          </div>
          <ChatGPTBottom />
        </div>
      </main>
    </>
  );
};

export default ChatGPT;