import React, { useEffect, useState } from "react";
import { PromptData } from "../pages/PromptDrive/PromptDrive";
import { useAuth } from "../providers";

export interface Folder {
    name: string;
    created_by: string;
}

export const usePromptDrive = (token: string | null) => {
    const { tokenInfo } = useAuth();
    const email = tokenInfo?.email || ""
    const name = tokenInfo?.name || ""
    const [prompts, setPrompts] = useState<PromptData[]>([]);
    const [folders, setFolders] = useState<Folder[]>([]);
    const [loaderDeleteFolder, setLoaderDeleteFolder] = useState(false);
    const [loadingAddPrompt, setLoadingAddPrompt] = useState(false);
    const [loadingUpdatePrompt, setLoadingUpdatePrompt] = useState(false);
    const [loadingDeletePrompt, setLoadingDeletePrompt] = useState(false);
    const [loadingGetAllPrompts, setLoadingGetAllPrompts] = useState(true);
    const [loadingCreateFolder, setLoadingCreateFolder] = useState(true);
    const [loadingGetAllFolders, setLoadingGetAllFolders] = useState(false);
    const [loadingGetPromptsByFolder, setLoadingGetPromptsByFolder] = useState(false);


    const addPrompt = async (prompt: PromptData) => {
        setLoadingAddPrompt(true);
        const response = await fetch('/api/prompt_drive/add_prompt', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: "include",
            body: JSON.stringify(prompt),
        });
        if (response.ok) {
            const responseData = await response.json();
        }
        setLoadingAddPrompt(false);
    };

    const updatePrompt = async (prompt: PromptData) => {
        setLoadingUpdatePrompt(true);
        const response = await fetch('/api/prompt_drive/update_promptdrive', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: "include",
            body: JSON.stringify(prompt),
        });
        if (response.ok) {
            const responseData = await response.json();
        }
        setLoadingUpdatePrompt(false);
    };

    const deletePrompt = async (id: string) => {
        setLoadingDeletePrompt(true);
        const response = await fetch('/api/prompt_drive/delete_promptdrive', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: "include",
            body: JSON.stringify({ id }),
        });
        if (response.ok) {
            const responseData = await response.json();
        }
        setLoadingDeletePrompt(false);
    };

    const getAllPrompts = async () => {
        setLoadingGetAllPrompts(true);
        const response = await fetch('/api/prompt_drive/prompts', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: "include"
        });
        if (response.ok) {
            const responseData = await response.json();
            setPrompts(responseData);
        }
        setLoadingGetAllPrompts(false);
    };

    const createFolder = async (name: string) => {
        setLoadingCreateFolder(true);
        const response = await fetch('/api/prompt_drive/add_folder', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: "include",
            body: JSON.stringify({ name, created_by: email }),
        });
        if (response.ok) {
            const responseData = await response.json();
        }
        setLoadingCreateFolder(false);
    };

    const getAllFolders = async () => {
        setLoadingGetAllFolders(true);
        try {
            const response = await fetch('/api/prompt_drive/folders', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: "include"
            });

            if (response.ok) {
                const responseData = await response.json();
                setFolders(responseData);
            } else {
                console.error('Failed to fetch folders:', response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching folders:', error);
        } finally {
            setLoadingGetAllFolders(false);
        }
    };

    const getPromptsByFolder = async (folder: string) => {
        setLoadingGetPromptsByFolder(true);
        const response = await fetch(`/api/prompt_drive/folders/${folder}/prompts`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: "include"
        });
        if (response.ok) {
            const responseData = await response.json();
            setPrompts(responseData);
        }
        setLoadingGetPromptsByFolder(false);
    };

    const deleteFolder = async (folderName: string) => {
        setLoaderDeleteFolder(true);
        try {
            const response = await fetch(`/api/prompt_drive/delete_folder/${folderName}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: "include",
            });
    
            if (response.ok) {
                const responseData = await response.json();
            } else {
                console.error('Failed to delete folder:', response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while deleting the folder:', error);
        } finally {
            setLoaderDeleteFolder(false);
        }
    };    

    return {
        addPrompt,
        getAllPrompts,
        createFolder,
        deletePrompt,
        updatePrompt,
        getAllFolders,
        getPromptsByFolder,
        deleteFolder,
        setPrompts,
        prompts,
        folders,
        loaderDeleteFolder,
        loadingAddPrompt,
        loadingUpdatePrompt,
        loadingDeletePrompt,
        loadingGetAllPrompts,
        loadingCreateFolder,
        loadingGetAllFolders,
        loadingGetPromptsByFolder
    };
};