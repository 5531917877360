import React, { useEffect, useState } from "react";
import { ChatLogo, ChatLogoType } from "../../assets/SVGIcon/ChatLogo";
import { ModalDelete } from "./ModalDelete";
import { PromptData } from "./PromptDrive";
import { translations } from "../../translations";
import { useAuth } from "../../providers";
interface PromptProps {
    data: PromptData;
    startEditModal: (data:PromptData) => void;
    startCopyModal: (data:PromptData) => void;
    setNewPromptAdded: (value: boolean) => void;
    loader: boolean;
    language: string;
}

export const Prompt:React.FC<PromptProps> = ({data,startEditModal,startCopyModal,setNewPromptAdded,loader,language}) => {
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
    const [iconKey, setIconKey] = useState((data.model.toUpperCase() + "_CHAT_ICON") as ChatLogoType);
    const translationTexts = translations['promptopedia'][language as keyof typeof translations['promptopedia']];
    const {tokenInfo} = useAuth()
    const email = tokenInfo?.email


    useEffect(() => {
        setIconKey((data.model.toUpperCase() + "_CHAT_ICON") as ChatLogoType);
    }, [data.model]);
    

    return(
        <div className={`flex flex-row w-full items-center h-20 ${showModalDelete ? '' : ' px-3 py-4 gap-3 rounded-md relative border border-dropdownBorder overflow-hidden overflow-x-scroll no-scrollbar'}`}>
            {!showModalDelete && 
            <> 
            <div className="flex flex-row">
                <ChatLogo icon={iconKey}/>
            </div>
            <div className="flex w-full  flex-col justify-center items-start">
                <p className="text-textSecondary text-md p-1">{data.title}</p>
                <div className="flex flex-row gap-2">
                    {data.tags.map((tag)=>(
                        <div className="flex flex-row justify-center gap-1 border-black bg-gray-200 text-textSecondary rounded-md px-2">
                            <p className="text-sm">#{tag}</p>
                        </div>
                    ))}
                </div>
                {data.created_by && <p className="text-xs p-1">{translationTexts.created_by} {data.created_by}</p>}
            </div>
            <div className="flex flex-row gap-3 p-5">
                <button className="border px-4 py-1.5 border-welcomeBackground text-buttonText rounded-md bg-buttonBg hover:shadow-xl" onClick={()=> startCopyModal(data)}>{translationTexts.copy_button}</button>
                {(data.access_level === 'read_only' ||  data.access_level === 'only_me') && email && email === data.created_by_email && <button className="border px-4 py-1.5 border-buttonBorder rounded-md bg-buttonBg bg-opacity-70 text-buttonText hover:shadow-xl"onClick={()=> startEditModal(data)}>{translationTexts.edit_button}</button>}
                {(data.access_level === 'read_only' ||  data.access_level === 'only_me') && email && email === data.created_by_email && <button className="border px-4 py-1.5 border-white text-buttonText rounded-md bg-red-400 hover:shadow-xl" onClick={()=>setShowModalDelete(true)}>{translationTexts.delete_button}</button>}
            </div>
            </>
           }
            {showModalDelete && <ModalDelete language={language} setShowDeletetModal={setShowModalDelete} id={data.id} setNewPromptAdded={setNewPromptAdded} loader={loader}/>}
        </div>
    )
}