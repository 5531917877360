import React from 'react';
import { useState, useEffect, ReactElement } from 'react';
import { useAuth } from '../../../providers';
import { TypeToIcon } from '../components/Answer';
import { v4 as uuidv4 } from 'uuid';
import { ChatModel } from './chatGPTModel';

interface PromptDefinition {
    definition: string;
    prompt_template: string;
}

export interface ThemePrompts {
    [theme: string]: {
        [key: string]: PromptDefinition;
    };
}

export type LanguageCode = 'FR' | 'EN' | 'ES' ;
export type ModelGPT = 'gpt' | 'gpt4' | 'mistral' | 'gemini';

const modelName: any = {
    'gpt': 'GPT',
    'gpt4': 'GPT4',
    'mistral': 'MISTRAL',
    'gemini': 'GEMINI'
}

export type ConversationMessage = {
    user?: string;
    assistant?: string;
    error?: string;
    model: ChatModel | null;
    loading? :boolean
};

export type SessionData = {
    session_id: string;
    data_name: string | null;
    data_type: string;
    conversation: ConversationMessage[];
    timestamp: string;
    title: string
};

export type DataHistory = {
    [key: string]: SessionData;
};

export interface SecuredGPTConfig {
    errorGPT: Error | null;
    suggestedPrompts: ThemePrompts;
    contextGPT: string;
    error: Error | null;
    languageGPT: LanguageCode;
    fetchConfig: (url: string) => Promise<void>;
    securedGPTHistory: DataHistory;
    selectedChatId: string;
    setSelectedChatId: (chatId: string) => void;
    setSecuredGPTHistory: React.Dispatch<React.SetStateAction<DataHistory>>;
    setContext: (context: string) => Promise<null | undefined>;
    onGoingTranslation: string[];
    fetchOngoingTranslations: () => Promise<void>;
    contextGPTTemplate: { [key: string]: string };

}

export const useChatGPTConfig = (): SecuredGPTConfig => {
    const { token,language } = useAuth()
    const [errorGPT, setErrorGPT] = useState<Error | null>(null);

    const [contextGPT, setContextGPT] = useState<string>('')
    const [contextGPTTemplate, setContextGPTTemplate] = useState({})
    const [error, setError] = useState<Error | null>(null);
    const [suggestedPrompts, setSuggestedPrompts] = useState<ThemePrompts>({});
    const [securedGPTHistory, setSecuredGPTHistory] = useState<DataHistory>({});
    const [newChatId, setNewChatId] = useState<string>('');
    const [selectedChatId, setSelectedChatId] = useState<string>('');

    const [languageGPT, setLanguageGPT] = useState<LanguageCode>('EN')
    const [onGoingTranslation, setOnGoingTranslation] = useState<string[]>([])

    const fetchConfig = async (url: string) => {
        try {
            if (token === null) {
                setErrorGPT(null);
                return;
            }
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }

            const data = await response.json();
            setContextGPT(data.context)
            setSuggestedPrompts(data.suggested_prompts)
            setLanguageGPT(data.language)
            if (data.conv)
                setSecuredGPTHistory(prevHistory => ({ ...prevHistory, ...data.conv }));
        } catch (error) {
            console.error('Erreur lors de la récupération de la configuration', error);
            setError(error instanceof Error ? error : new Error('Erreur inconnue'));
        }
    };

    const fetchContextTemplate = async (url: string) => {
        try {
            if (token === null) {
                setErrorGPT(null);
                return;
            }
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }

            const data = await response.json();
            setContextGPTTemplate(data.context)
            console.log(data.context)
        } catch (error) {
            console.error('Erreur lors de la récupération de la configuration', error);
            setError(error instanceof Error ? error : new Error('Erreur inconnue'));
        }
    };



    const fetchOngoingTranslations = async()=>{
        try{
            const response = await fetch('/api/translation/ongoing_config', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }

            const data = await response.json();
            setOnGoingTranslation(data.ongoing_translations.filter((elem:string) => elem !== null))

        }
        catch(e){
            console.error('Erreur', error);
            setError(error instanceof Error ? error : new Error('Erreur inconnue'));
        }
    }

    const setContext = async (context: string) => {
        //setContextGPTLoader(true)
        try {
            const response = await fetch('/api/secured_gpt/context', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: "include",
                body: JSON.stringify({
                    context: context,
                }),
            })

            const data = await response.json()
            setContextGPT(data.context)
           // setContextGPTLoader(false)
        }
        catch (error) {
            console.log(error)
          //  setContextGPTLoader(false)
            return null
        }
    }

    useEffect(() => {
        const newSessionId = uuidv4();
        setNewChatId(newSessionId);
        const date = new Date();
        const isoString = date.toISOString();
        const formattedDate = isoString.replace(/\.\d{3}Z$/, '+00:00');
        setSecuredGPTHistory(prevHistory => ({
            ...prevHistory,
            [newSessionId]: {
                session_id: newSessionId,
                title: "New Chat...",
                data_name: null,
                data_type: 'text',
                conversation: [],
                timestamp: formattedDate
            }
        }));
        setSelectedChatId(newSessionId)
        fetchConfig(`/api/secured_gpt/config`);
    }, [])

    useEffect(() => {
        fetchConfig(`/api/secured_gpt/change/config/${language}`);
        fetchContextTemplate(`/api/secured_gpt/get_context_template/${language}`)
    }, [language])

    return { 
        errorGPT, 
        suggestedPrompts, 
        contextGPT, 
        error, 
        languageGPT, 
        fetchConfig, 
        securedGPTHistory,
        selectedChatId, 
        setSelectedChatId, 
        setSecuredGPTHistory,
        setContext,
        fetchOngoingTranslations,
        onGoingTranslation,
        contextGPTTemplate,
    };
};