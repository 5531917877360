import { useState, useEffect, useRef } from "react";
import { useChatGPT } from "../providers";

interface PromptDefinition {
    definition: string;
    prompt_template: string;
}

export interface OptimizedPrompts {
    [theme: string]: {
        [key: string]: PromptDefinition;
    };
}

interface DropdownProps {
    //isOpen: boolean;
   // toggleOpen: () => void;
    //suggestedPrompts: OptimizedPrompts
}

export const ChatGPTOptimizedPrompts: React.FC<DropdownProps> = ({ }) => {
    const { gptConfig } = useChatGPT()

    const [isNestedDropdownOneOpen, setIsNestedDropdownOneOpen] = useState(false);
    const [isNestedDropdownTwoOpen, setIsNestedDropdownTwoOpen] = useState(false);

    const [isCopied, setIsCopied] = useState<boolean>(false);

    const [selectedTheme, setSelectedTheme] = useState<string>('Tech');
    const [selectedEntry, setSelectedEntry] = useState<string>('');
    const [optionsDropdown1, setOptionsDropdown1] = useState<string[]>([]);
    const [optionsDropdown2, setOptionsDropdown2] = useState<string[]>([]);
    const [selectedDefinition, setSelectedDefinition] = useState<string>("");
    const [promptTemplate, setPromptTemplate] = useState<string>("");


    useEffect(() => {
        const themes = Object.keys(gptConfig.suggestedPrompts);
        setOptionsDropdown1(themes);

        if (themes.length > 0) {
            const defaultTheme = themes[0];
            setSelectedTheme(defaultTheme);
            const selectedThemeObject = gptConfig.suggestedPrompts[defaultTheme];
            if (selectedThemeObject) {
                const keys = Object.keys(selectedThemeObject);
                setOptionsDropdown2(keys);
                if (keys.length > 0) {
                    const defaultKey = keys[0];
                    setSelectedEntry(defaultKey);
                    const defaultPrompt = selectedThemeObject[defaultKey];
                    if (defaultPrompt) {
                        setSelectedDefinition(defaultPrompt.definition);
                        setPromptTemplate(defaultPrompt.prompt_template);
                    }
                }
            }
        }
    }, [gptConfig.suggestedPrompts]);

    useEffect(() => {
        if (optionsDropdown2.length > 0) {
            const defaultDesc = optionsDropdown2[0];
            handleDescriptionSelection(defaultDesc);
        }
    }, [optionsDropdown2]);


    const handleThemeSelection = (theme: string) => {
        setSelectedTheme(theme);
        const selectedThemeObject = gptConfig.suggestedPrompts[theme]
        if (selectedThemeObject) {
            const keys = Object.keys(selectedThemeObject);
            setOptionsDropdown2(keys);
            setSelectedEntry('');
        }
    };

    const handleDescriptionSelection = (key: string) => {
        setSelectedEntry(key);
        const selectedThemeObject = gptConfig.suggestedPrompts[selectedTheme];
        if (selectedThemeObject) {
            const selectedPrompt = selectedThemeObject[key];
            if (selectedPrompt) {
                setSelectedDefinition(selectedPrompt.definition);
                setPromptTemplate(selectedPrompt.prompt_template);
            }
        }
    };

    const toggleNestedDropdownOne = () => {
        setIsNestedDropdownOneOpen(!isNestedDropdownOneOpen);
        if (isNestedDropdownTwoOpen) setIsNestedDropdownTwoOpen(false)

    }
    const toggleNestedDropdownTwo = () => {
        setIsNestedDropdownTwoOpen(!isNestedDropdownTwoOpen);
        if (isNestedDropdownOneOpen) setIsNestedDropdownOneOpen(false)
    }

    const copyTextToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(promptTemplate);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
            setIsCopied(false);
        }
    };

    const buttonRef = useRef<HTMLButtonElement>(null);

    return (
        <div className="w-full whitespace-nowrap rounded-xl text-left md:whitespace-normal">
            <div className={``}>
                {/* Nested Dropdowns */}
                <div className={`flex flex-col gap-2 z-20 transition-all ease-in-out duration-500`}>
                    {/* Nested Dropdown One */}
                    <div className="">
                        <button ref={buttonRef} onClick={toggleNestedDropdownOne} className="flex justify-between items-center text-left p-1.5 text-black bg-transparent rounded-xl shadow border border-gray-200 focus:outline-none cursor-pointer w-full">
                            <span className="text-black">{selectedTheme}&nbsp;</span>
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isNestedDropdownOneOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}></path>
                            </svg>
                        </button>
                        {isNestedDropdownOneOpen && (
                            <div className="absolute z-20 overflow-y-auto rounded-md shadow-lg bg-white" style={{ width: buttonRef.current?.offsetWidth }}>
                                <ul className="text-[#2E2E2E]">
                                    {optionsDropdown1.map((option, index) => (
                                        <li key={index} className="hover:bg-[#ececec] cursor-pointer px-4 py-2 border-b border-gray-200 text-sm list-none" onClick={() => {
                                            handleThemeSelection(option);
                                            toggleNestedDropdownOne()
                                        }}
                                        >
                                            {option}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    {/* Nested Dropdown Two */}
                    <div className="">
                        <button onClick={toggleNestedDropdownTwo} className="flex justify-between items-center text-left p-1.5 text-[#2E2E2E] bg-transparent rounded-xl shadow border border-gray-200 focus:outline-none cursor-pointer w-full">
                            <span className="text-black ">{selectedEntry}&nbsp;</span>
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isNestedDropdownTwoOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}></path>
                            </svg>
                        </button>
                        {isNestedDropdownTwoOpen && (
                            <div className="absolute z-20 mt-1 rounded-md shadow-lg bg-white"  style={{ width: buttonRef.current?.offsetWidth }}>
                                <ul className="text-black">
                                    {optionsDropdown2.map((option, index) => (
                                        <li key={index} className="hover:bg-[#ececec] cursor-pointer px-4 py-2 border-b border-gray-200 text-sm list-none" onClick={() => {
                                            handleDescriptionSelection(option);
                                            toggleNestedDropdownTwo()
                                        }}
                                        >
                                            {option}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-full h-fit">
                    <p className="py-2 text-black">
                        {selectedDefinition}
                    </p>
                </div>

                {/* Grey rectangle with text */}
                <div className=" p-2 bg-[#ececec] rounded relative max-h-[200px] overflow-y-scroll no-scrollbar">
                    {isCopied ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="absolute top-0 right-0 m-2 cursor-pointer">
                            <polyline points="20 6 9 17 4 12"></polyline> {/* Checked icon */}
                        </svg>
                    ) : (
                        <svg onClick={copyTextToClipboard} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="absolute top-0 right-0 m-2 cursor-pointer">
                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path> {/* Copy icon */}
                        </svg>
                    )}
                    <p className="text-black p-2 pt-5 text-sm text-justify ">
                        {promptTemplate}
                    </p>
                </div>
            </div>
        </div>
    )
}