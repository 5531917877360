interface SelectOptionsProps<T> {
    defaultValue: string;
    options: T[];
    renderOption: (option: T) => { value: string; label: string };
    selectedValue: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}
  
  export function SelectOptions<T>({
    defaultValue,
    options,
    renderOption,
    selectedValue,
    onChange,
  }: SelectOptionsProps<T>) {
    return (
      <div className="w-full">
        <select
          value={selectedValue}
          onChange={onChange}
          className="my-2 w-full p-2 border border-selectBorder text-selectText bg-selectBg rounded focus:ring focus:ring-focusRing focus:ring-opacity-75 focus:outline-none"
        >
          {defaultValue !== "" && <option value="">{defaultValue}</option>}
          {options.map((option, index) => {
            const { value, label } = renderOption(option);
            return (
              <option key={index} value={value}>
                {label}
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  interface SelectOptionsProps<T> {
    defaultValue: string;
    options: T[];
    renderOption: (option: T) => { value: string; label: string };
    selectedValue: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}
  
  export function SelectOptionsNew<T>({
    defaultValue,
    options,
    renderOption,
    selectedValue,
    onChange,
  }: SelectOptionsProps<T>) {
    return (
      <div className="select-options w-full">
        <select
          value={selectedValue}
          onChange={onChange}
          className="my-2 rounded-xl w-full p-2 border border-selectBorder bg-selectBg text-selectText"
        >
          {defaultValue === "-" && <option value="">{defaultValue}</option>}
          {options.map((option, index) => {
            const { value, label } = renderOption(option);
            return (
              <option key={index} value={value}>
                {label}
              </option>
            );
          })}
        </select>
      </div>
    );
  }