import { useState,useRef,useEffect } from "react";
import React from "react";
import { useActiveTabs } from "../../providers";
import { useTypingEffect } from "../../hooks/typingEffect";
import { useAuth } from "../../providers";
import { SVGIcon } from "../../assets/SVGIcon";
import { TextAnswerHomeSDHPage } from "../../components";
import { Tabs } from "../../assets";
import { SecureChat } from "../SecureChat";
import "./HomeSDH.css"
import { useSavingPromptProfile } from "../../hooks/savingPromptProfile";
import { config } from '../../config';
import { translations } from '../../translations';
import { useChatGPT } from "../ChatGPT/providers";
import { ChatGPT } from "../ChatGPT";
import { ChatModel, useChatGPTModel } from "../ChatGPT/hooks";


interface CardAIAppsProps {
    title: string;
    animate: boolean;
}


const CardAIApps: React.FC<CardAIAppsProps & { index: number }> = ({ title, animate, index }) => {
    const colorIndex = index % config.colors.bgColor.length;
    const [isHovered, setIsHovered] = useState(false);
  
  return (
    <div className={`cursor-pointer font-secondary h-full flex flex-row p-4 items-center bg-backgroundCardHome text-xl rounded-xl relative ${animate ? 'fadeInScaleEffect' : ''}`}
        style={{
            color: config.colors.textColor[colorIndex],
            borderColor: config.colors.borderColor[colorIndex],
            borderWidth: '1px',
            borderStyle: 'solid',
            transition: 'background-color 0.3s, color 0.3s',
        }}
        onMouseEnter={e => {
            e.currentTarget.style.backgroundColor = config.colors.bgColor[colorIndex];
            e.currentTarget.style.color = 'white';
            setIsHovered(true);
          }}
        onMouseLeave={e => {
            e.currentTarget.style.backgroundColor = 'transparent';
            e.currentTarget.style.color = config.colors.textColor[colorIndex];
            setIsHovered(false);
        }}>
      <p className="tracking-wider">{title}</p>
      <div className="absolute bottom-50 right-0 p-2">
        <SVGIcon size="25px" icon={isHovered ? "ARROW_WHITE_ICON" : "ARROW_BLACK_ICON"} />
      </div>
    </div>
  );
};



export const HomeSDH = () =>{
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
    const { tokenInfo,token } = useAuth();
    const email = tokenInfo?.email || ""
    const name = tokenInfo?.name.replace(/\./g, ' ') || ""
    const app = tokenInfo?.app || []
    const { openTab, returnFav,tabState, closeTab,} = useActiveTabs();
    const [input, setInput] = useState<string>("")
    const fav = returnFav([11,12,13,14])
    const favData = returnFav([100, 101])
    const favAiApsDoc = returnFav([4,7,9])
    const favAiApsPerf = returnFav([5,6])
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const [promptGPT, setPromptGPT] = useState<string[]>([
        "How does text generation AI work?",
        "What are AI's ethical concerns?",
        "What are effective project management techniques?",
        "How do we enhance customer satisfaction?"
    ])
    const [pluginGPT, setPluginGPT] = useState<string>('')
    const [redirectSuccess, setRedirectSuccess] = useState<boolean>(false)
    const isSecureChatAuthorised = app.find(elem => elem === 'secure_chat')
    const {getSavedPrompts,savedPrompts} = useSavingPromptProfile(token)
    const [combinedPrompts, setCombinedPrompts] = useState<string[]>(promptGPT);
    const {language} = useAuth();
    const translationTexts = translations['home_page'][language as keyof typeof translations['home_page']];
    const greetingText = `${translationTexts.ai_hello} **${name.toLocaleUpperCase()}** ${translationTexts.ai_help} 😊`;
    const {gptInput,chatGPTAsk,gptConfig,gptModel} = useChatGPT()


    useEffect(()=>{
        getSavedPrompts()
    },[tabState])

    useEffect(() => {
        const savedPromptTexts = savedPrompts.map(promptObj => promptObj.prompt);
        const newPrompts = [...savedPromptTexts, ...promptGPT].slice(0, 4);
        setCombinedPrompts(newPrompts);
    }, [savedPrompts]);

    const secureGPTTab : Tabs = {
        id: 10,
        appId: "secure_chat",
        title: "Secure Chat",
        description: ["Same ChatGPT as public model but on a private and secure environment."],
        icon: <SVGIcon size="20px" icon="SECURE_CHAT_ICON" />,
        app: () => <ChatGPT/>,
    }

    // const redirectToGPT = async(written: boolean,model: string) =>{
    //     if(!isSecureChatAuthorised) return
    //     if(written){
    //         closeTab(1)
    //         openTab(secureGPTTab)
    //         setInput('')
    //     }
    //     else{
    //         closeTab(1)
    //         const secureGPTTabPlugin : Tabs = {
    //             id: 1,
    //             appId: "secure_chat",
    //             title: "Secure Chat",
    //             description: ["Same ChatGPT as public model but on a private and secure environment."],
    //             icon: <SVGIcon size="20px" icon="SECURE_CHAT_ICON" />,
    //             app: () => <SecureChat appName={model} prompt={pluginGPT} immediatelySendPrompt={true}/>,
    //         }
    //         openTab(secureGPTTabPlugin)
    //         setInput('')
    //     }
    // }

    const redirectToGPT = async(written: boolean) =>{
        if(!isSecureChatAuthorised) return
        const secureGPTTabPlugin : Tabs = {
            id: 10,
            appId: "secure_chat",
            title: "Secure Chat",
            description: ["Same ChatGPT as public model but on a private and secure environment."],
            icon: <SVGIcon size="20px" icon="SECURE_CHAT_ICON" />,
            app: () => <ChatGPT/>,
        }
        if(written){
            closeTab(10)
            openTab(secureGPTTab)
            setInput('')
            gptInput.askGPTStream(
                chatGPTAsk.setErrorRequestChpt,
                gptConfig.selectedChatId,
                input,
                'gpt' as ChatModel,
                chatGPTAsk.setLoadingGPT,
                gptConfig,
            )
        }
        else{
            closeTab(10)
            let prompt = savedPrompts.find((elem) => elem.prompt === pluginGPT);
            if (!prompt) {
                prompt = {
                    userId: tokenInfo?.email || '',
                    prompt: pluginGPT,
                    model: 'gpt',
                    created_at: new Date().toISOString(),
                };
            }            
            gptModel.setSelectedChatModel(prompt?.model! as ChatModel)
            openTab(secureGPTTabPlugin)
            setInput('')
            gptInput.askGPTStream(
                chatGPTAsk.setErrorRequestChpt,
                gptConfig.selectedChatId,
                prompt?.prompt!,
                prompt?.model! as ChatModel,
                chatGPTAsk.setLoadingGPT,
                gptConfig,
            )
            setPluginGPT('')
        }
    }

    useEffect(()=>{
        if(pluginGPT.length > 0){
            const prompt = savedPrompts.find((elem)=> elem.prompt === pluginGPT)
            redirectToGPT(false)
        }
    },[pluginGPT])


    useEffect(() => {
        const comingLoginValue = localStorage.getItem('comingLogin')
        if (tabState.activeTabId === 0 && comingLoginValue) {
            setShouldAnimate(true);
            localStorage.removeItem('comingLogin');
        }
    }, [tabState]);

    useEffect(() => {
        if(tabState.activeTabId !== 0){
            setShouldAnimate(false)
        }
    }, [tabState]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        //onSendMessage(input);
        //setInput("");
      };
    
      const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Enter" && !event.shiftKey) {
          event.preventDefault();
          redirectToGPT(true)
        }
      };

      const renderNumbersAiApps = (tabsArr : Tabs[]) =>(
        tabsArr.map((tabs,id) => (
            <div className="w-full cursor-pointer h-24"  onClick={()=> openTab(tabs)}>
                <CardAIApps index={id} animate={shouldAnimate} title={tabs.title}/>
            </div>
        ))
      )

    return(
        <div className="flex w-full flex-col justify-center items-center">
            <div className="flex w-full h-[300px] rounded-bl-[250px] bg-welcomeBackground">
                <div className="flex flex-col w-full h-full justify-center items-center relative">
                    <div className="flex w-full justify-left p-3">
                        <SVGIcon size="45px" icon="STAR_ICON" />
                    </div>
                    <h1 className="text-center text-headerText text-7xl font-bold font-secondary p-3">
                        {translationTexts.welcome_message}
                        {'\u00A0'}{name.toLocaleUpperCase()}!<br/> 
                    </h1>
                    <p className="text-sm text-textPrimary text-center w-[50%]">  
                        {translationTexts.company_description}
                    </p>
                    <div className="flex w-full justify-end p-3">
                        <SVGIcon size="60px" icon="STAR_ICON" />
                    </div>
                </div>
            </div>
            <div className={`top-32 flex max-w-full w-11/12 justify-center flex-col lg:flex-row`}>
                <div className="flex-1 flex gap-7 items-center h-fit justify-between content-between flex-col pt-10 p-6">
                    {/* <div className="flex flex-row w-full h-full gap-3 rounded-xl max-[1300px]:flex-col relative"> 
                        <>
                        {favAiApsDoc.length === 0 ?  
                        <div className="text-sm w-full flex flex-row items-center justify-start border border-[#E6E6E6] p-3 rounded-md cursor-pointer">
                            <p className="w-full">No Apps to Display</p>
                        </div>   
                        :
                        renderNumbersAiApps(favAiApsDoc)
                        }
                        </>
                    </div> */}
                    <div className="flex flex-col w-full bg-homeGPTCardBg border border-homeGPTCardBorder max-[1300px]:w-full  max-[1600px]:h-fit gap-4 rounded-xl p-8 shadow-md">
                        <TextAnswerHomeSDHPage model="" type="IA_HOME" text={greetingText} typed={true} loading={false} />
                        <div className="w-full items-center justify-center flex">
                            <div className="grid grid-cols-2 flex flex-row w-full p-1 gap-3 items-center justify-start">
                                { 
                                    combinedPrompts.map((elem, index) => (
                                        <div key={index} onClick={() =>{setPluginGPT(elem)}} className="w-full h-[45px] border border-homeGPTCardBorder text-sm text-homeGPTCardText rounded-md p-2 cursor-pointer hover:text-black hover:bg-white transition-all ease-in-out">
                                            {elem.substring(0,55) + "..."}
                                        </div>
                                    ))
                                }
                            </div> 
                        </div>
                        <div className="relative">
                            <textarea
                                ref={textAreaRef}
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                onKeyDown={handleKeyDown}
                                className="form-control items-center placeholder-[#232323] h-12 block w-full p-3 pr-24 text-sm font-trenda text-textSecondary bg-white bg-clip-padding rounded-lg transition ease-in-out m-0 focus:outline-none overflow-hidden resize-none"
                                placeholder={translationTexts.placeholder_securechat}
                                rows={3}
                                style={{ maxHeight: "6rem", overflowY: "auto" }}
                            />
                            <span className="absolute top-4 right-2 text-sm text-gray-500">
                                <button type="submit" disabled={(input.length === 0)} onClick={() => redirectToGPT(true)} className="send-button">
                                    <SVGIcon size="20px" icon="PAPER_PLANE_ICON" />
                                </button> 
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}