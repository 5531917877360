import { useRef } from "react";
import { SVGIcon } from "../../assets";
import { messageTMM } from "../../hooks";
import { translations } from "../../translations";

interface DisplayPrompt {
    prompts: string[] | null;
    reloadPrompt?: () => Promise<void>
    ask: ((text: string,model: string) => Promise<void>) | undefined | (() => void);
    resetMessages: (model: string) => void;
    app: string;
    setInput: (text: string) => void;
    resetText? : string;
    resetDoc?: boolean;
    setResetDoc?: React.Dispatch<React.SetStateAction<boolean>>;
    language: string
}



export const PromptBubble: React.FC<DisplayPrompt> = ({ prompts, reloadPrompt, ask,resetMessages,app, setInput,resetText,resetDoc,setResetDoc,language }) => {
    if (!prompts) {
        prompts = [
            "What is the AI?",
            "What is the AI?",
        ]
    }
    const ref = useRef()
    const translationTexts = translations['secure_chat'][language as keyof typeof translations['secure_chat']];
    const translationTextsDoc = translations['multi_doc'][language as keyof typeof translations['multi_doc']];


    return (
        <div className="flex w-full justify-center items-center mb-2.5">
            <div className="flex w-11/12 justify-end items-center">
                <div className="flex w-full justify-start items-start gap-2">
                    <button onClick={() => resetMessages(app)} type="submit" className="pl-2 pr-2 pt-1 pb-1 cursor-pointer hover:shadow-md rounded-md text-xs text-buttonText border border-buttonBorder bg-buttonBg hover:text-red-500">
                        {resetText ? resetText : translationTexts.reset_chat}
                    </button>
                    {resetDoc && setResetDoc &&  <button onClick={() => setResetDoc(true)} type="submit" className="pl-2 pr-2 pt-1 pb-1 cursor-pointer hover:shadow-md rounded-md text-xs text-buttonText border border-buttonBorder bg-buttonBg hover:buttonHoverBorder hover:text-red-500">
                        {translationTextsDoc.delete_doc}
                    </button>}
                </div>
                {/* {app === 'SDH' &&
                <>
                {Array.isArray(prompts) && prompts?.map((e, i) => {
                    return (
                        <div onClick={(e)=> setInput(prompts![i])} key={i} className="w-10/12 flex justify-between py-0.5 px-2.5 ml-2 cursor-pointer hover:shadow-md rounded-md border border-text-primary-light bg-white">
                            <p className="font-medium text-text-primary-light text-xs">{e}</p>
                        </div>
                    )
                })}
                <div className="flex pl-2 cursor-pointer">
                    <div onClick={reloadPrompt}>
                        <SVGIcon icon="REFRESH_ICON" className=" fill-text-primary-light" />
                    </div>
                </div> 
                </>
                
                }   */}
            </div>
        </div>

    );
};
