import React from "react";
import { useState, useEffect, useRef } from "react";
import DropzoneLargeDoc from "./DropzoneLargeDoc";
import { Ask, TypeToIcon } from "../../components";
import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import { LoaderCircle } from "../../components";
import { useSessionId } from "../../hooks";
import { useAuth } from "../../providers";
import { SVGIcon } from "../../assets";
import "./LargeDoc.css"
import { ChatLogo } from "../../assets/SVGIcon/ChatLogo";
import { ChatInput } from "../SecureGPT/ChatInput";
import { AskMultiDoc,AnswerMultiDoc } from "./ChatConversationLargeDoc";
import { AskConversationProps } from "./ChatConversationLargeDoc";
import { AskQuestion } from "./ChatConversationLargeDoc";
import { translations } from "../../translations";

interface DocumentDropdownProps {
    summary: string;
    fileName: string;
    token: string;
    session_id: string;
    isOpen: boolean;
    toggleDropdown: (index: number) => void;
    index: number;
    resetTrigger : boolean;
    setResetTrigger : React.Dispatch<React.SetStateAction<boolean>>
    triggerResetDoc: boolean;
    setResetTriggerDoc : React.Dispatch<React.SetStateAction<boolean>>
    language: string
}

interface DocumentDropdownGeneralProps {
    summary: string;
    fileName?: string;
    token: string;
    session_id: string
    messages : AskConversationProps[]
    setMessages? : React.Dispatch<React.SetStateAction<AskConversationProps[]>>
    isOpen: boolean;
    toggleDropdown: (identifier: string) => void;
    language: string;

}

export const DocumentDropdown: React.FC<DocumentDropdownProps> = ({ summary, fileName,token,session_id, isOpen, toggleDropdown, index, resetTrigger, setResetTrigger,triggerResetDoc, setResetTriggerDoc,language}) => {
    const [messages, setMessages] = useState<AskConversationProps[]>([])
    const endOfMessagesRef = useRef<HTMLDivElement>(null); // Ref for the end of the messages
    const translationTexts = translations['multi_doc'][language as keyof typeof translations['multi_doc']];


    useEffect(()=>{
        setResetTrigger(false)
        setResetTriggerDoc(false)
    },[])

    useEffect(() => {
        if (summary && summary.length > 0 || resetTrigger) {
            setMessages([{ type: 'Ask', text: '' },{type:'Answer', text: summary}]);
        }
        else{
            setMessages([{type: 'Answer', text : ""}])
        }
    }, [summary,resetTrigger])

    useEffect(()=>{
        if(resetTrigger || triggerResetDoc){
            setMessages([]) 
            setResetTrigger(false)
            setResetTriggerDoc(false)
        } 
    },[resetTrigger,setResetTrigger,setResetTriggerDoc,triggerResetDoc])

    useEffect(() => {
        // Scroll to the last message on messages update or initial load
        if (endOfMessagesRef.current) {
          endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [messages.length]);

    return (
        <div className={`flex flex-col w-full bg-dropdownBg ${isOpen ? 'pb-3 pl-4 pr-4': ''} p-1.5 rounded-xl border border-dropdownBorder`}>
            <button onClick={()=> toggleDropdown(index)} className="gap-2 text-textSecondary flex justify-between items-center w-full text-left bg-transparent rounded-xl focus:outline-none ">
                <ChatLogo icon="USER_CHAT_ICON" />
                <span className="w-full text-sm">{fileName ? fileName : translationTexts.placeholder_chat_input}&nbsp;</span>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}></path>
                </svg>
            </button>
            <div className={`gap-2 flex flex-col shadow-md rounded-xl bg-gray-400 bg-opacity-55 relative scrollbar-hide  ${isOpen ? 'max-h-150 p-4 mt-3' : 'max-h-0 overflow-hidden'}`}>
                <div className={`overflow-auto flex flex-col ${messages.length > 3 ? "min-h-[300px]" : "min-h-[50px]"} scrollbar-hide`}   style={{ height: messages.length > 3 ? `calc(100vh - 550px)` : 'auto' }} >
                    {messages.map((messageObj, index)=>{
                        if(messageObj.type === 'Ask'){
                            return(
                                <React.Fragment key={index}>
                                    <div ref={endOfMessagesRef} />
                                    <AskMultiDoc language={language} text={messageObj.text}/>
                                </React.Fragment>
                            )
                        }
                        if(messageObj.type === 'Answer'){
                            return(
                                <React.Fragment key={index}>
                                    <AnswerMultiDoc language={language} text={messageObj.text}/>
                                </React.Fragment>
                            )
                        }
                    })}
                </div>
                <AskQuestion language={language} messages={messages!} setMessages={setMessages} token={token} session_id={session_id} fileName={fileName}/>
            </div>
        </div>
    )
}

export const DocumentDropdownGeneral: React.FC<DocumentDropdownGeneralProps> = ({ summary, fileName,messages, isOpen,toggleDropdown,language}) => {
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const endOfMessagesRef = useRef<HTMLDivElement>(null); // Ref for the end of the messages
    const translationTexts = translations['multi_doc'][language as keyof typeof translations['multi_doc']];



    const copyTextToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(summary);
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 1000);
        } catch (err) {
          console.error('Failed to copy text: ', err);
          setIsCopied(false);
        }
    };

    useEffect(() => {
        // Scroll to the last message on messages update or initial load
        if (endOfMessagesRef.current) {
          endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [messages.length]);

    return (
        <div className={`flex flex-col w-full bg-dropdownBg ${isOpen ? 'pb-3 pl-4 pr-4 bg-opacity-80': ''} p-1.5 rounded-xl border border-dropdownBorder`}>
            <button onClick={() => toggleDropdown('general')} className="gap-2 text-textSecondary flex justify-between items-center w-full p-1 text-left bg-transparent rounded-xl focus:outline-none ">
                <ChatLogo icon="USER_CHAT_ICON" />
                <span className="w-full text-sm">{fileName ? fileName : translationTexts.placeholder_chat_input_general}&nbsp;</span>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}></path>
                </svg>
            </button>
            {messages.length > 0 && 
            <div className={`w-90 gap-3 flex flex-col shadow-md rounded-xl bg-gray-400 bg-opacity-55 relative scrollbar-hide  ${isOpen ? 'max-h-150 p-4 mt-3' : 'max-h-0 overflow-hidden'}`}>
                <div className={`overflow-auto flex flex-col ${messages.length > 1 ? "min-h-[250px]" : "min-h-[50px]"} scrollbar-hide`}   style={{ height: messages.length > 1 ? `calc(100vh - 630px)` : 'auto' }} >
                    {messages.map((messageObj, index)=>{
                        if(messageObj.type === 'Ask'){
                            return(
                                <React.Fragment key={index}>
                                    <div ref={endOfMessagesRef} />
                                    <AskMultiDoc language={language} text={messageObj.text}/>
                                </React.Fragment>
                            )
                        }
                        if(messageObj.type === 'Answer'){
                            return(
                                <React.Fragment key={index}>
                                    <AnswerMultiDoc language={language} text={messageObj.text}/>
                                </React.Fragment>
                            )
                        }
                    })}
                    
                </div>
            </div>}
        </div>
    )
}


interface DocumentDisplayProps {
    fileName: string;
    onRemove: () => void; 
}

export const DocumentDisplay: React.FC<DocumentDisplayProps> = ({fileName,onRemove}) => {
    return (
        <div className="flex w-full">
            <button  className="cursor-default bg-buttonBg text-buttonText flex justify-between items-center w-full p-3 text-left rounded-lg shadow border border-buttonBorder focus:outline-none">
                <span className="w-full">{fileName}&nbsp;</span>
                <svg onClick={onRemove} className="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        </div>
    )
}
