import { Link } from "react-router-dom";
import { useTheme } from "../../providers";
import { SVGIcon, SVGIconsType } from "../../assets";
import {config} from "../../config";

export const SideBarLogo = (props: { isSidebarOpen: Boolean }) => {
  const { isSidebarOpen } = props;
  const { appTheme } = useTheme();
  const icon: SVGIconsType = isSidebarOpen
  ? (appTheme === "dark"
    ? config.logo.src
    : config.logo.src) as SVGIconsType
  : config.logo.whiteSrc as SVGIconsType;

  const src = (
    <SVGIcon size={isSidebarOpen ? "180px" : "40px"} icon={icon} />
  );

  return (
    <div className="flex justify-center items-center pt-6">
      <Link to="/">{src}</Link>
    </div>
  );
};
