import { useEffect, useRef, useState } from "react";


export function useTypingEffect(textToType: string, interKeyStrokeDurationInMs: number): [string, boolean]  {
  const [currentPosition, setCurrentPosition] = useState<number>(0);
  const currentPositionRef = useRef<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true); // New state to track loading status


  useEffect(() => {
    setIsLoading(true);
    const intervalId = setInterval(() => {
      setCurrentPosition((value) => value + 1);
      currentPositionRef.current += 1;
      if (currentPositionRef.current > textToType.length) {
        clearInterval(intervalId);
        setIsLoading(false); 
      }
    }, interKeyStrokeDurationInMs);


    return () => {
      clearInterval(intervalId);
      currentPositionRef.current = 0;
      setCurrentPosition(0);
      setIsLoading(false);
    };

  }, [interKeyStrokeDurationInMs, textToType]);


  return [textToType.substring(0, currentPosition), isLoading];
}
