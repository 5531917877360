import React, { useEffect, useState } from "react"
import { useTypingEffect } from "../../hooks/typingEffect"
import { SVGIcon } from "../../assets";
import "./drive.css"
import { ChatLogo } from "../../assets/SVGIcon/ChatLogo";
import { ModalCreateFolder } from "./ModalCreateFolder";
import { ModalCreatePrompt } from "./ModalCreatePrompt";
import { ModalEditPrompt } from "./ModalEditPrompt";
import { ModalCopyPrompt } from "./ModalCopyPrompt";
import { ModalDeleteFolder } from "./ModalDeleteFolder";
import { Prompt } from "./Prompt";
import { useAuth } from "../../providers";
import { usePromptDrive } from "../../hooks/promptDrive";
import { LoaderComponent } from "../../components";
import SearchBar from "./SearchBar";
import { tags } from "./ModalCreatePrompt";
import { CurvilinearComponent } from "../Folder";
import { translations } from "../../translations";

const promptFolders: string[] = [
    "Character Prompts",
    "Setting Ideas",
    "Plot Inspiration",
    "Dialogue Starters",
    "Genre Challenges",
    "Creative Writing Exercises",
    "Thematic Prompts",
    "Flash Fiction Ideas",
    "Poetry Prompts",
    "Visual Inspiration",
    "Creative Writing Exercises",
    "Thematic Prompts",
    "Flash Fiction Ideas",
    "Poetry Prompts",
    "Visual Inspiration"
];

export interface PromptData {
    id: string;
    title: string;
    tags: string[];
    promptContent: string;
    model: string;
    folder: string;
    note: string
    created_by?: string
    created_by_email?: string
    access_level: string;
}


export const PromptDrive = () => {
    const {token,tokenInfo,language} = useAuth()
    const app = tokenInfo?.app || []
    const defautFolder = 'All Prompts'
    const translationTexts = translations['promptopedia'][language as keyof typeof translations['promptopedia']];
    const [typedText] = useTypingEffect(translationTexts.welcome_message, 40)
    const [selectedFolder, setSelectedFolder] = useState<string | null>(defautFolder)
    const [showFolderModal, setShowFolderModal] = useState<boolean>(false)
    const [showAddPromptModal, setShowAddPromptModal] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [showCopyModal, setShowCopyModal] = useState<boolean>(false)
    const [showDeleteFolderModal, setShowDeleteFolderModal] = useState<boolean>(false)
    const [addFolderInput, setAddFolderInput] = useState<string>('')
    const [newPromptAdded, setNewPromptAdded] = useState<boolean>(false)
    const [folderDeleted, setFolderDeleted] = useState<boolean>(false)
    const [selectedPrompt, setSelectedPrompt] = useState<PromptData| null>(null)
    const [userRole, setUserRole]= useState<string | null>(null)
    const [filteredPrompts, setFilteredPrompts] = useState<PromptData[]>([]);
    const {getAllPrompts,createFolder,prompts,getAllFolders,getPromptsByFolder,setPrompts,folders,loadingAddPrompt,loadingUpdatePrompt,loadingDeletePrompt,loadingGetAllPrompts,loadingCreateFolder,loadingGetAllFolders,loadingGetPromptsByFolder,loaderDeleteFolder} = usePromptDrive(token)
    const [addFolderSuccess, setAddFolderSuccess] = useState<boolean>(false)
    const [loaderCreateFolder, setLoaderCreateFolder] = useState<boolean>(false)
    const [addFolderError, setAddFolderError] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string | null>(null);
    const [selectedTag, setSelectedTag] = useState<string | null>(null);


    useEffect(()=>{
        setFilteredPrompts(prompts.filter(prompt => {
            return (
                prompt.access_level === 'read_only' ||
                (prompt.access_level === 'only_me' && prompt.created_by_email === tokenInfo?.email)
            )
        }))
    },[prompts])

    useEffect(() => {
        let filteredPrompts = prompts.filter(prompt => {
            return (
                prompt.access_level === 'read_only' ||
                (prompt.access_level === 'only_me' && prompt.created_by_email === tokenInfo?.email)
            )
        });

        if (selectedTag && selectedTag !== '-' && selectedTag.length > 0) {
            filteredPrompts = filteredPrompts.filter(prompt => prompt.tags.includes(selectedTag));
        }
        if (searchText && searchText.length > 0) {
            filteredPrompts = filteredPrompts.filter(prompt => prompt.title.toLowerCase().includes(searchText.toLowerCase()));
        }
        if ((!selectedTag || selectedTag.length === 0) && (!searchText || searchText.length === 0)) {
            filteredPrompts = prompts;
        }

        setFilteredPrompts(filteredPrompts);
    }, [selectedTag, searchText]);

    useEffect(()=>{
        if(app && app.length > 0){
            const isAdmin = app.find(elem => elem ==='prompt_driveAdmin')
            if(isAdmin){
                setUserRole('admin')
            }
        }
    },[app])

    useEffect(()=>{
        if(selectedFolder === 'All Prompts') return
        if(selectedFolder) {
            getPromptsByFolder(selectedFolder)
            setSearchText('')
            setSelectedTag('-')
        } 
    },[selectedFolder])

    useEffect(()=>{
        if(folders.find(elem => elem.name.toLocaleLowerCase() === addFolderInput.toLocaleLowerCase())) setAddFolderError(true)
        else setAddFolderError(false)
    },[addFolderInput])
    

    const closeModalEdit = () =>{
        setShowEditModal(false)
        setSelectedPrompt(null)
    }

    const closeModalCopyPrompt = () =>{
        setShowCopyModal(false)
        setSelectedPrompt(null)
    }

    // useEffect(()=>{
    //     if(selectedPrompt) setShowEditModal(true)
    // },[selectedPrompt])

    const startEditModal = (data: PromptData) =>{
        setSelectedPrompt(data)
        setShowEditModal(true)
    }

    const startCopyModal = (data: PromptData) =>{
        setSelectedPrompt(data)
        setShowCopyModal(true)
    }

    useEffect(()=>{
        getAllFolders()
        getAllPrompts()
    },[])

    useEffect(()=>{
        if(newPromptAdded){
            setSelectedFolder(null)
            getAllPrompts()
            setNewPromptAdded(false)
        }
    },[newPromptAdded])

    useEffect(()=>{
        if(folderDeleted){
            setSelectedFolder(null)
            getAllFolders()
            getAllPrompts()
            setFolderDeleted(false)
        }
    },[folderDeleted])

    useEffect(()=>{
        if(addFolderSuccess) {
            getAllFolders() 
            setAddFolderSuccess(false)
        }
    },[addFolderSuccess])

    const addFolder = async() => {
        if(addFolderInput.length === 0) return
        setLoaderCreateFolder(true)
        await createFolder(addFolderInput)
        setShowFolderModal(false)
        setAddFolderInput('')
        setAddFolderSuccess(true)
        setLoaderCreateFolder(false)
    }

    return(
        <>
        {showFolderModal && <ModalCreateFolder language={language} addFolderError={addFolderError} setAddFolderInput={setAddFolderInput} setShowFolderModal={setShowFolderModal} addFolder={addFolder} loader={loaderCreateFolder}/>}
        {showDeleteFolderModal && <ModalDeleteFolder language={language} selectedFolderName={selectedFolder!} setShowFolderModal={setShowDeleteFolderModal} loader={loaderDeleteFolder} folders={folders} setFolderDeleted={setFolderDeleted} />}
        {showAddPromptModal && <ModalCreatePrompt prompts={prompts} folders={folders} setShowPromptModal={setShowAddPromptModal} setNewPromptAdded={setNewPromptAdded} loader={loadingAddPrompt}/>}
        {/* {showDeleteModal && <ModalDelete setShowDeletetModal={setShowDeleteModal} id={selectedPrompt?.id!}/>} */}
        {showEditModal && <ModalEditPrompt prompts={prompts} closeEditModal={closeModalEdit} setNewPromptAdded={setNewPromptAdded} folders={folders} data={selectedPrompt!} loader={loadingUpdatePrompt}/>}
        {showCopyModal && <ModalCopyPrompt language={language} closeCopyPromptModal={closeModalCopyPrompt} data={selectedPrompt!} />}
        <LoaderComponent loadingConfig={(loadingGetAllFolders || loadingGetAllPrompts)}>
            <div className="flex h-full flex-col items-center relative">
                <div className="flex flex-col w-full bg-welcomeBackground py-2 text-center">
                    <div className="flex flex-row w-full justify-center">
                        <p className="text-textPrimary font-secondary text-4xl">{typedText}</p>
                    </div>
                    <div className="flex gap-1 flex-col bg-transparent p-2 w-full rounded-lg justify-center items-center text-sm text-textPrimary text-center overflow-hidden">
                        <p className="w-[70%]">
                            {translationTexts.description_app}        
                        </p>
                    </div>
                </div>
                <div className="flex flex-col h-[120px] w-full justify-center p-5 border-b-2 border-t-2 relative">
                    <div className="cursor-pointer flex flex-row items-center gap-2 font-bold py-1" onClick={()=> setShowFolderModal(true)}>
                        <h1 className="text-textSecondary">{translationTexts.folder_text}</h1>
                        <button className="flex items-center justify-center text-md">+</button>
                    </div>
                    <div className="flex flex-row items-center p-3 overflow-x-scroll overflow-y-hidden no-scrollbar gap-3">
                        <div className="w-fit cursor-pointer flex text-sm items-center gap-2 p-3 hover:underline" onClick={()=> {getAllPrompts();setSelectedFolder('All Prompts')}}>
                                <div className="w-full"><SVGIcon size="25px" icon="FOLDERS_ICON"/></div>
                                <p className="w-full">{defautFolder.replace(/ /g, '\u00A0')}</p>
                        </div>
                        {!loadingGetAllFolders &&  folders.length > 0 && folders.map((elem,index)=>(
                            <div className="w-fit cursor-pointer flex text-sm items-center gap-2 p-3 hover:underline" onClick={()=> {setSelectedFolder(folders[index].name)}}>
                                <div className="w-full"><SVGIcon size="25px" icon="FOLDERS_ICON"/></div>
                                <p className="w-full">{elem.name.replace(/ /g, '\u00A0')}</p>
                                { elem.created_by === tokenInfo?.email && 
                                    <div className="p-1 absolute top-0 right-0 cursor-pointer transform transition-transform hover:rotate-180 relative" onClick={()=> setShowDeleteFolderModal(true)}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </div>}
                            </div>
                        ))}
                        {/* {!loadingGetAllFolders && folders.length === 0 && <h1 className="w-full text-center text-2xl font-thunder">No Folders To Display...</h1>} */}
                    </div>                
                </div>
                <div className="flex flex-col w-[90%] p-6 rounded-xl h-[800px] overflow-y-scroll overflow-x-hidden no-scrollbar gap-1 relative">
                    <div className="w-full items-center flex flex-row p-4 mb-5">
                        <div className="flex flex-col w-[80%] text-md">
                            <p>{translationTexts.selected_folder}</p>
                            <p className="text-textSecondary">{selectedFolder}</p>
                        </div>
                        <SearchBar language={language} tags={tags} setSearchText={setSearchText} searchText={searchText!} tag={selectedTag!} setTag={setSelectedTag} />
                        <div className="flex w-full justify-end">
                            <button className={`border px-3 py-1 border-buttonBorder hover:bg-buttonHoverBg hover:text-buttonHoverText text-buttonText rounded-md bg-buttonBg hover:shadow-xl ${folders.length === 0 ? 'bg-gray-600 cursor-not-allowed' : ''}`} onClick={()=>setShowAddPromptModal(true)} disabled={folders.length === 0}>{translationTexts.add_prompt_button}</button>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 pb-8">
                        {(loadingGetAllPrompts || loadingGetPromptsByFolder) && <h1 className="w-full text-center text-2xl font-secondary">Loading Prompts...</h1>}
                        {!(loadingGetAllPrompts || loadingGetPromptsByFolder) && filteredPrompts.map((promptData)=>(
                            <Prompt language={language} data={promptData} startCopyModal={startCopyModal} startEditModal={startEditModal} setNewPromptAdded={setNewPromptAdded} loader={loadingDeletePrompt} />
                        ))}
                        {filteredPrompts.length === 0 && !loadingGetAllPrompts && <h1 className="w-full text-center text-2xl font-secondary">{selectedFolder ? "Folder is empty..." : "No Prompts for Now..."}</h1>}
                    </div>
                </div>
            </div>
        </LoaderComponent>
        </>
        
    )
}