import { useAuth } from "../../providers";
import { PromptProfile, useSavingPromptProfile } from "../../hooks/savingPromptProfile";
import { useState, useEffect } from "react";
import { useChatGPT } from "../../pages/ChatGPT/providers";
import { SVGIcon } from "../../assets";


interface SavingPromptButtonProps {
  prompt: string;
  model?: string;
  savedPrompts: PromptProfile[];
}

export const SavingPromptButton: React.FC<SavingPromptButtonProps> = ({
  prompt,
  savedPrompts,
}) => {
  const { token } = useAuth();
  const { savePrompt } = useSavingPromptProfile(token);
  const [isSaved, setIsSaved] = useState(false);
  const { gptModel } = useChatGPT();
  const [showInfo, setShowInfo] = useState(false);

  const handleSave = () => {
    if (!isSaved) {
      savePrompt(prompt, gptModel.selectedChatModel);
      setIsSaved(true);
    }
  };

  const handleMouseEnter = () => {
    setShowInfo(true);
    setTimeout(() => {
      setShowInfo(false);
    }, 2000);
  };

  const handleMouseLeave = () => {
    setShowInfo(false);
  };

  useEffect(() => {
    const foundPrompt = savedPrompts.find((p) => p.prompt === prompt);
    setIsSaved(!!foundPrompt);
  }, [prompt, savedPrompts]);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="relative cursor-pointer  flex flex-row gap-3 text-sm text-[#0D3D62] rounded-lg justify-center items-center"
      onClick={handleSave}
    >
        <SVGIcon
            className={`transition-opacity transform duration-600 ${
            isSaved ? 'opacity-100 scale-110' : 'opacity-50 scale-100'
            }`}
            icon="PIN_FAV_ICON"
        />      
      {showInfo && (
        <div
          className="absolute  mb-2 right-7 w-max px-3 py-1 bg-gray-100 rounded-xl text-black text-xs rounded shadow-sm"
          style={{ zIndex: 9999 }}
        >
          Save in favorite
        </div>
      )}
    </div>
  );
};