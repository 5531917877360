import React, { useState } from "react";
import { usePromptDrive } from "../../hooks/promptDrive";
import { useAuth } from "../../providers";
import { PromptData } from "./PromptDrive";
import { translations } from "../../translations";

interface ModalDeleteProps{
    setShowDeletetModal: (value: boolean) => void;
    setNewPromptAdded: (value: boolean) => void;
    id: string;
    loader: boolean;
    language: string
}

export const ModalDelete:React.FC<ModalDeleteProps> = ({setShowDeletetModal,id,setNewPromptAdded,language}) => {
    const {token} = useAuth()
    const {deletePrompt} = usePromptDrive(token)
    const [loader,setLoader] = useState<boolean>(false)
    const translationTexts = translations['promptopedia'][language as keyof typeof translations['promptopedia']];
    
    const deleteSomePrompt = async() =>{
        setLoader(true)
        await deletePrompt(id)
        setShowDeletetModal(false)
        setNewPromptAdded(true)
        setLoader(false)
    }
    return (
       
            <div className="flex flex-row gap-4 w-full h-full items-center inset-0 border border-black bg-[#83cdff] bg-opacity-70 rounded-md px-3 py-4 relative">
                <h1 className="text-[#232323] w-full">{translationTexts.modal_delete_validation_message}</h1>
                <div className="flex flex-row gap-3">
                    <button
                        className="w-full bg-emerald-600 rounded-md px-4 px-1 text-white"
                        onClick={deleteSomePrompt}
                    >
                        {loader ? translationTexts.modal_delete_loader : translationTexts.modal_delete_yes}
                    </button>
                    <button
                        className="w-full bg-red-600 rounded-md px-4 py-1 text-white"
                        onClick={()=>setShowDeletetModal(false)}
                    >
                        {translationTexts.modal_delete_no}
                    </button>
                </div>
            </div>
    );
}