import { useEffect, useState } from "react";
import FileDropzone from "../../DocumentTranslator/FileDropzone";
import { SelectOptions } from "../../DocumentTranslator/SelectedOptions";
import { translateDocument, useDocumentUploader, useTranslationConfig } from "../../../hooks/documentTranslator";
import { useSessionId } from "../../../hooks";
import { useAuth, useCredit } from "../../../providers";
import { translations } from "../../../translations";
import { useChatGPTConfig } from "../hooks";

export const ChatGPTTranslator = ({ }) => {
    const [file, setFile] = useState<File | null>(null);
    const [method, setMethod] = useState<string>("");
    const [loaderTranslation, setLoaderTranslation] = useState<boolean>(false)
    const sessionId = useSessionId(`/api/translation/clear_session`);
    const { token,language } = useAuth()

    const {
        loading: configLoading,
        languages,
        docTypes,
        mapOpt,
        setMapOpt,
        setDocTypes,
    } = useTranslationConfig({ sessionId });
    const {
        loading: documentUploaderLoading,
        usage,
        error,
        errorCorupt,
        loaderEstimate,
    } = useDocumentUploader({ file, method, sessionId });

    const [fileName, setFileName] = useState<string>("");
    const [selectedLanguage, setSelectedLanguage] = useState<string>("");
    const [sourceLanguage, setSourceLanguage] = useState<string>("");
    const [availableOptions, setAvailableOptions] = useState<string[]>([]);
    const [price, setPrice] = useState<number | null>(null);
    const [errorTranslation, setErrorTranslation] = useState<boolean>(false)
    const [pageNumber, setPageNumber] = useState<number>(0)
    const { remainingCredit, setRemainingCredit } = useCredit()
    const translationTexts = translations['doc_translator'][language as keyof typeof translations['doc_translator']];

    useEffect(() => {
        if (mapOpt && Object.entries(mapOpt).length > 0 && typeof remainingCredit === 'number' && remainingCredit <= 0) {
            const filteredMapOpt: { [key: string]: string[] } = Object.keys(mapOpt)
                .filter(key => key === 'pdf' || key === 'pptx' || key === 'docx')
                .reduce((obj, key) => {
                    obj[key] = mapOpt[key]?.filter(value => value === 'GoogleTranslate') ?? [];
                    return obj;
                }, {} as { [key: string]: string[] });

            if (JSON.stringify(filteredMapOpt) !== JSON.stringify(mapOpt)) {
                setMapOpt(filteredMapOpt);
            }
        }
    }, [mapOpt, remainingCredit]);

    useEffect(() => {
        if (docTypes && docTypes.length > 0 && typeof remainingCredit === 'number' && remainingCredit <= 0) {
            const filteredDocTypes = docTypes.filter(type => type !== 'pdf');
            if (JSON.stringify(filteredDocTypes) !== JSON.stringify(docTypes)) {
                setDocTypes(filteredDocTypes);
            }
        }
    }, [docTypes])

    useEffect(() => {
        if (usage) {
            setPrice(usage['usage']);
            if (method != "LLMTranslate") {
                setPageNumber(usage['pages'])
            } else {
                setPageNumber(0)
            }
        }
    }, [usage]);

    useEffect(() => {
        setMethod("");
        setPrice(null);
        setPageNumber(0)
    }, [file]);


    useEffect(() => {
        if ((method == "TranslationHub" && pageNumber > 20) || pageNumber > 40) setErrorTranslation(true)
        else setErrorTranslation(false)
    }, [pageNumber, method])

    const initiateTranslation = async () => {
        if (typeof remainingCredit === 'number' && remainingCredit <= 0 && method !== 'GoogleTranslate') return;
        if (method === "TranslationHub" && fileName.endsWith(".docx") && pageNumber === 1) {
            alert('1 page docx files are not allowed for the TranslationHub method.')
            return;
        }
        setLoaderTranslation(true);
    
        try {
            await translateDocument({ file, sessionId, selectedLanguage, method, token, sourceLanguage });
    
            if (price && remainingCredit) {
                const newCredit = remainingCredit - price;
                setRemainingCredit(newCredit);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoaderTranslation(false);
        }
    
        try {
            await fetch(`/api/translation/clear_session/${sessionId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        } catch (error) {
            console.error('Erreur lors du nettoyage de la session', error);
        }
    };    

    return (
        <div className="py-2 w-full">
            <FileDropzone
            setFile={setFile}
            setFileName={setFileName}
            setAvailableOptions={setAvailableOptions}
            mapOpt={mapOpt}
            fileName={fileName}
            docTypes={docTypes}
            language={language}
          />
          {fileName != "" && availableOptions.length > 0 && (
            <SelectOptions
              defaultValue={translationTexts.select_method}
              options={availableOptions}
              renderOption={(method) => ({ value: method, label: method })}
              selectedValue={method}
              onChange={(e) => setMethod(e.target.value)}
            />

          )}
          {
            method == "GoogleTranslate" &&
            <SelectOptions
              defaultValue={translationTexts.select_original_language}
              options={Object.entries(languages)}
              renderOption={(language) => ({
                value: language[0],
                label: language[1],
              })}
              selectedValue={sourceLanguage}
              onChange={(e) => setSourceLanguage(e.target.value)}
            />
          }
          {method != "" &&
            <SelectOptions
              defaultValue={translationTexts.select_language}
              options={Object.entries(languages)}
              renderOption={(language) => ({
                value: language[0],
                label: language[1],
              })}
              selectedValue={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
            />
          }
          {method != "" && selectedLanguage != "" && fileName != "" &&
            <button
              className={`mt-4 w-full bg-buttonBg hover:bg-buttonHoverBg border border-buttonBorder hover:text-buttonHoverText text-buttonText py-2 px-4 rounded`}
              onClick={initiateTranslation}
              disabled={loaderTranslation || errorTranslation}
            >
                {errorCorupt 
                ? errorCorupt 
                : (errorTranslation 
                    ? translationTexts.error_toomuch_pages 
                    : (loaderTranslation 
                        ? "Loading..." 
                        : (loaderEstimate 
                            ? "Price estimation in progress..." 
                            : `${translationTexts.submit_button} ${price != null ? " | " + Math.round(price) + " " + translationTexts.credit_text : ""}`)))
                }
            </button>
          }
        </div>
    )
}