import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "../../../providers";
import { useSessionId } from "../../../hooks";
import { ChatGPTGestureHook, ChatGPTInputHook, ChatGPTModalHook, ChatGPTModelHook, messageGPT, SecuredGPTConfig, useChatGPTGesture, useChatGPTInput, useChatGPTModal, useChatGPTModel, useChatGPTConfig } from "../hooks";

export type ModalStatus = 'CLOSE' | 'MODEL' | 'PARAMS' | 'DOCUMENT_OPT' | 'TRANSLATOR' | 'SEARCH_PROMPT';

interface CreditContextType {
  gptConfig: SecuredGPTConfig;
  gptModel: ChatGPTModelHook;
  gptInput: ChatGPTInputHook;
  chatGPTAsk: any;
  gptGesture: ChatGPTGestureHook;
  modalStatus: ModalStatus;
  setModalStatus: (status: ModalStatus) => void;
}

const ChatGPTContext = createContext<CreditContextType | undefined>(undefined);


export const ChatGPTProvider = ({ children }: React.PropsWithChildren) => {
  const gptConfig = useChatGPTConfig();
  const gptInput = useChatGPTInput();
  const gptModel = useChatGPTModel()
  const gptGesture = useChatGPTGesture()

  const [modalStatus, setModalStatus] = useState<ModalStatus>('CLOSE');

  const [loadingGPT, setLoadingGPT] = useState<boolean>(false); //loader
  const [errorRequestGPT, setErrorRequestChpt] = useState<boolean>(false)

  const chatGPTAsk = {
    loadingGPT,
    setLoadingGPT,
    errorRequestGPT,
    setErrorRequestChpt
  }

  return (
    <ChatGPTContext.Provider value={{
      modalStatus,
      setModalStatus,
      gptConfig,
      gptModel,
      gptInput,
      chatGPTAsk,
      gptGesture
    }}>
      {children}
    </ChatGPTContext.Provider>
  );
};

export const useChatGPT = () => {
  const context = useContext(ChatGPTContext);
  if (!context) {
    throw new Error('useChatGPT must be used within a ChatGPTProvider');
  }
  return context;
};