import { SVGIcon } from "../../assets";

export const CloseSidebarButton = (props: {
  isSidebarOpen: boolean;
  toggleSidebar: (newValue?: boolean | undefined) => void;
}) => {
  const { isSidebarOpen, toggleSidebar } = props;

  return (
    <div
      className={`absolute z-40 right-0 ${isSidebarOpen ? 'top-[11%] py-0.5' : 'py-11' }`}
    >
      <button
        className="flex"
        onClick={() => {
          toggleSidebar();
        }}
      >
        {isSidebarOpen ? (
          <SVGIcon icon="CLOSE_SIDEBAR_ICON" size="30px" />
        ) : (
          <SVGIcon icon="OPEN_SIDEBAR_ICON" size="20px" />
        )}
      </button>
    </div>
  );
};
