import React from 'react';

interface ProgressBarProps {
  credit: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ credit }) => {
  const maxCredit = 5000;
  const progress = Math.min(credit, maxCredit) / maxCredit * 100;

  return (
    <div className="w-[70%] mt-1 bg-gray-200 rounded-full h-0.5">
      <div
        className="bg-[#339900] h-0.5 rounded-full"
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};