import { useEffect, useRef, useState } from "react";
import { useChatGPT } from "../providers";
import { ChatModel, ConversationMessage, DataHistory, messageGPT, modelName, SessionData } from "../hooks";
import { AnswerProps, LoaderTextAnswer, TextAnswer, TextAnswerError, Ask } from "./Answer";
import React from "react";
import { TypeToIcon } from "../../../components";
import { useSavingPromptProfile } from "../../../hooks/savingPromptProfile";
import { useAuth } from "../../../providers";

function getIcon(type: ChatModel | null): keyof typeof TypeToIcon {
    if (!type)
        return 'IA'
    const upperType = type.toUpperCase() as keyof typeof TypeToIcon;
    return upperType;
}

function convertConversationMessages(messages: ConversationMessage[]): messageGPT[] {
    return messages.map(message => {


        let msg_type: string;
        let model_type: keyof typeof TypeToIcon;
        let text: string;
        let loading: boolean = false;

        if ('user' in message) {
            if (typeof message.user === 'string') {
                msg_type = "Ask";
                text = message.user;
                model_type = getIcon(message.model)
                loading = false
            } else {
                throw new Error("Invalid message: user text must be a string");
            }
        } else if ('assistant' in message) {
            if (typeof message.assistant === 'string') {
                msg_type = "TextAnswer";
                text = message.assistant;
                model_type = getIcon(message.model)
                loading = message.loading == undefined ? false : message.loading
            } else {
                throw new Error("Invalid message: assistant text must be a string");
            }
        } else if ('error' in message) {
            if (typeof message.error === 'string') {
                msg_type = "TextAnswerError";
                text = message.error;
                model_type = getIcon(message.model)
                loading = false
            } else {
                throw new Error("Invalid message: error text must be a string");
            }
        } else {
            throw new Error("Invalid message: must contain either user, assistant or error text");
        }

        return {
            type: msg_type,
            props: {
                type: model_type,
                text,
                typed: false,
                loading
            }
        };
    });
}

function getHistoryById(data: DataHistory, session_id: string): messageGPT[] | null {
    for (const key in data) {
        if (data[key].session_id === session_id) {
            return convertConversationMessages(data[key].conversation);
        }
    }
    return null;  // Retourne null si aucun objet avec le session_id spécifié n'est trouvé
}



export const ChatGPTTalk = ({ }: {}) => {
    const [lastTextAnswerIndex, setLastTextAnswerIndex] = useState<number | null>(null);
    const {token} = useAuth()
    const {
        gptModel,
        chatGPTAsk,
        gptConfig,
        gptGesture
    } = useChatGPT()

    const h = getHistoryById(gptConfig.securedGPTHistory, gptConfig.selectedChatId)
    const {savedPrompts,getSavedPrompts} = useSavingPromptProfile(token)

    //const messages: messageGPT[] = chatGPTAsk.conversations[gptModel.selectedChatModel]
    //const prevMessagesCountRef = useRef<number>(messages.length);
    // Ref for the end of the messages

    /*  useEffect(() => {
          // Find the last TextAnswer message index
          const lastTextAnswer = messages.map((msg, index) => ({ type: msg.type, index })).reverse().find(msg => msg.type === 'TextAnswer');
          if (lastTextAnswer) {
              setLastTextAnswerIndex(lastTextAnswer.index);
          }
  
          // Reset prevMessagesCountRef after the effect runs
          return () => {
              prevMessagesCountRef.current = messages.length;
          };
      }, [messages]);
  
      useEffect(() => {
          // Scroll to the last message on messages update or initial load
          if (endOfMessagesRef.current) {
              endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
          }
      }, [messages.length]);
  
  */
    useEffect(() => {
        gptGesture.scrollToEndOfMessages()
        getSavedPrompts()
    }, [gptConfig.selectedChatId]);

    return (
        // <div className='bg-white w-11/12 p-4 rounded-xl min-h-[300px]' style={{ height: `calc(100vh - 275px)` }}>
        //   <div className="overflow-auto p-1 max-h-full scrollbar-hide" >
        <>
            {h && h.map((messageObj, index) => {
                const isLastTextAnswer = messageObj.type === 'TextAnswer' && index === lastTextAnswerIndex;
                //const isNewMessageAdded = messages.length > prevMessagesCountRef.current;

                let commonProps = {
                    ...messageObj.props,
                    typed: isLastTextAnswer && messageObj.props.text.length <= 200,
                };

                if (messageObj.type === 'TextAnswer') {
                    const textAnswerProps: AnswerProps = {
                        ...commonProps,
                        loading: messageObj.props.loading ? messageObj.props.loading : false,
                    };
                    return (
                        <React.Fragment key={index}>
                            <TextAnswer {...textAnswerProps} />
                        </React.Fragment>
                    );
                }
                else if (messageObj.type === 'TextAnswerError') {
                    return (
                        <React.Fragment key={index}>
                            <TextAnswerError type={modelName[gptModel.selectedChatModel]} />
                        </React.Fragment>
                    )

                } else {
                    return (
                        <React.Fragment key={index}>
                            <Ask {...commonProps} savedPrompts={savedPrompts} />
                        </React.Fragment>
                    );
                }
            })}

            {chatGPTAsk.loadingCPT && <LoaderTextAnswer type={modelName[gptModel.selectedChatModel]} />}
            <div ref={gptGesture.endOfMessagesRef} />
        </>
    )
}