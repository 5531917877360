import React, { useEffect, useState } from "react";
import { useAuth } from "../providers";


export interface PromptProfile {
    userId: string
    prompt: string;
    model: string
    created_at: string
}

export const useSavingPromptProfile = (token: string | null ) => {
    const {tokenInfo} = useAuth()
    const [loaderSavePrompt, setLoaderSavePrompt] = useState(false)
    const [loaderGetPrompt, setLoaderGetPrompt] = useState(false)
    const [savedPrompts, setSavedPrompts] = useState<PromptProfile[]>([])

    const savePrompt = async(prompt: string, model: string) => {
        setLoaderSavePrompt(true)
        try{
            const response = await fetch("/api/prompt_profile/save_prompt",{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: "include",
                body: JSON.stringify({model,prompt}),
            })
            if(response.ok){
                const responseData = await response.json()
            }
        }
        catch(error){
            console.log(error)
            return
        }
        setLoaderSavePrompt(false)
    }

    const getSavedPrompts = async() =>{
        setLoaderGetPrompt(true)
        try{
            const response = await fetch("/api/prompt_profile/profile_prompts",{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: "include"
            })
            if (response.ok) {
                const responseData = await response.json();
                setSavedPrompts(responseData)
            }
        }
        catch(error){
            console.log(error)
            return
        }
        setLoaderGetPrompt(false)
    }

    return {
        getSavedPrompts, savePrompt, savedPrompts, loaderGetPrompt, loaderSavePrompt
    }
}