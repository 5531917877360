import React, { useState } from "react";
import { useChatGPT } from "../providers";

export const ChatGPTContext = ({}) =>{
    const { gptConfig } = useChatGPT()
    const [inputValue, setInputValue] = useState(gptConfig.contextGPT);
    const [loaderSubmit, setLoaderSubmit] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = async() => {
        setLoaderSubmit(true)
        await gptConfig.setContext(inputValue)
        setLoaderSubmit(false)
    };

    return(
        <div className="w-full whitespace-nowrap rounded-xl text-left md:whitespace-normal">
                <div className="w-full">
                        <textarea
                                className=" mb-2 p-2 rounded-md shadow-lg bg-[#ececec] w-full focus:outline-none"
                                value={inputValue}
                                onChange={handleInputChange}
                                style={{ height: '100px', minHeight: '100px', overflowY: 'auto'}}
                            />
                    <button onClick={handleSubmit} className="mb-4 px-4 py-2 bg-white text-black rounded-lg border border-gray-300 hover:text-emerald-500 hover:border-emerald-500 focus:outline-none">{loaderSubmit ? "Setting up context..." : "Submit"}</button>   
                </div>
        </div>
    )
}