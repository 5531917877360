import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { color, Theme } from "./color";
import { AppThemeContextType } from "./ThemeContext.Types";

export const ThemeContext = createContext<AppThemeContextType | undefined>(
  undefined
);

export const changeTheme = (appTheme: Theme): Theme =>
  appTheme === "dark" ? "light" : "dark";

export const useTheme = () => {
  const theme = useContext(ThemeContext);
  if (!theme) throw Error("useColor must be used inside ThemeProvider");
  return theme;
};

export const ThemeProvider = ({ children }: React.PropsWithChildren) => {
  // Using a state initializer function to only read localStorage on the initial render.
  const [appTheme, setTheme] = useState<Theme>(() => {
    const storedTheme = localStorage.getItem("theme") as Theme;
    return storedTheme || "light"; // Fallback to "dark" theme if no theme is stored.
  });

  // Function to toggle the theme and persist this preference.
  const toggleTheme = useCallback(() => {
    const newTheme = changeTheme(appTheme);
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme); // Persist the new theme in localStorage.
  }, [appTheme]);

  // Prepare the context value with useMemo to avoid unnecessary re-renders.
  const contextValue = useMemo(
    () => ({
      appTheme,
      color: color[appTheme],
      toggleTheme,
    }),
    [appTheme, toggleTheme]
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};
