import { SetStateAction, useEffect, useRef, useState } from "react";
import { CloseSidebarButton } from "../../components";
import { useActiveTabs, useAuth, useCredit } from "../../providers";
import { SideBarActiveTabs } from "./SideBarActiveTabs";
import { SideBarLogo } from "./SideBarLogo";
import { SideBarMenu } from "./SideBarMenu";
import { SVGIcon } from "../../assets/SVGIcon";
import "./SideBar.css"
import { ScreenCapture } from "../../components/ScreenCapture";
import { ProgressBar } from "./ProgressBar";
import { translations } from "../../translations";
import { SideBarHistoryTabs } from "./SideBarHistoryTabs";
import { NewChatBtn } from "../../pages/ChatGPT/components/NewChatBtn";


interface InfoModalProps {
  isVisible: boolean;
  text?: string;
  isSideBarOpen: boolean;
  logout: () => void;
  updateGlobalLanguage: (language: string) => Promise<void | null>;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const InfoModal: React.FC<InfoModalProps> = ({ isVisible, text, isSideBarOpen,logout,updateGlobalLanguage,setModalVisible }) => {
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<string | null>(null);
  const translationTexts = translations['home_page'][currentLanguage as keyof typeof translations['home_page']];
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language_global');
    setCurrentLanguage(storedLanguage);

    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setIsLanguageMenuOpen(false);
        setModalVisible(false)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!isVisible || !isSideBarOpen) return null;
  
  const toggleLanguageMenu = () => {
    setIsLanguageMenuOpen(!isLanguageMenuOpen);
  };

  const handleLanguageChange = (languageCode: string) => {
    //localStorage.setItem('language_global', languageCode);
    setCurrentLanguage(languageCode);
    updateGlobalLanguage(languageCode);
  };

  return (
    <div ref={modalRef} className={`absolute w-[80%] bottom-0 p-2 bg-white shadow-lg rounded text-sm border ${isVisible ? 'fade-in' : ''}`}>
      <div onClick={logout} className={`w-[85%] text-md flex flex-row gap-1 overflow-hidden items-center p-2 cursor-pointer`}  >
             <SVGIcon size="25px" icon="LOGOUT_ICON" /> 
             <p className="text-[#D55F5A]">{translationTexts.logout_text}</p>
      </div>
      {!isLanguageMenuOpen && 
        <div onClick={toggleLanguageMenu} className={`w-[85%] text-md flex flex-row gap-1 overflow-hidden items-center p-2 cursor-pointer fade-in`}>
          <SVGIcon size="25px" icon="TRADUCTION_ICON" />
          <p className="text-[#000000]">{translationTexts.languages_text}</p>
          <SVGIcon size="25px" icon="ARROW_BLACK_ICON" />
        </div>
      }
      {isLanguageMenuOpen && (
        <div className={`flex flex-row gap-1.5 relative fade-in`}>
          <div className={`cursor-pointer flex items-center mb-1 px-1 ${currentLanguage === 'EN' ? 'border-2 border-green-500 rounded-xl' : ''}`} onClick={() => handleLanguageChange('EN')}>
            <SVGIcon size="25px" icon="ENGLAND_FLAG_ICON" />
          </div>
          <div className={`cursor-pointer flex items-center mb-1 px-1 ${currentLanguage === 'FR' ? 'border-2 border-green-500 rounded-xl' : ''}`} onClick={() => handleLanguageChange('FR')}>
            <SVGIcon size="25px" icon="FRENCH_FLAG_ICON" />
          </div>
          <div className={`cursor-pointer flex items-center mb-1 px-1 ${currentLanguage === 'ES' ? 'border-2 border-green-500 rounded-xl' : ''}`} onClick={() => handleLanguageChange('ES')}>
            <SVGIcon size="25px" icon="SPAIN_FLAG_ICON" />
          </div>
          <div className={`cursor-pointer flex items-center justify-end w-full mb-1 px-1`} onClick={toggleLanguageMenu}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="black"
                strokeWidth={2}
            >
              <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
      )}
      
    </div>
  );
};

const getInitials = (name: string): string => {
  const parts = name.split(' ');
  return parts
      .slice(0, 2)
      .map((part) => part[0])
      .join('')
      .toUpperCase();
};


export const SideBar = (props: {
  isSidebarOpen: boolean;
  toggleSidebar: (newValue?: boolean | undefined) => void;
  layoutSize: { open: string; close: string };
}) => {
  const { isSidebarOpen, toggleSidebar, layoutSize } = props;
  const sidebarClass = isSidebarOpen ? layoutSize.open : layoutSize.close;
  const { logout, tokenInfo, updateGlobalLanguage,language} = useAuth();
  const email = tokenInfo?.email || ""
  const name = tokenInfo?.name || ""
  const { remainingCredit } = useCredit()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const text = 'Explanation about credits here.Explanation about credits here.Explanation about credits here.Explanation about credits here.Explanation about credits here.Explanation about credits here.Explanation about credits here.Explanation about credits here. '
  const { closeAllTabs, tabState } = useActiveTabs()
  const translationTexts = translations['home_page'][language as keyof typeof translations['home_page']];

  




  return (
    <aside
      className={`${sidebarClass} h-full fixed top-0 left-0 z-10 shadow-lg bg-navBarTopBg`}
    >
      <CloseSidebarButton
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <div className={`flex flex-col h-full justify-between no-scrollbar text-sm  overflow-hidden overflow-scroll no-scrollbar`}>
        <div className="flex-shrink-0">
          {" "}
          {/* Adjust height as needed */}
          <SideBarLogo isSidebarOpen={isSidebarOpen} />
          <hr className="w-48 my-5 max-w-full mx-auto border-0 rounded dark:bg-gray-700" />
        </div>
        <div className="flex-shrink-0">
          <SideBarMenu isSidebarOpen={isSidebarOpen} />
          <hr className="w-48 my-5 max-w-full  border-0 rounded dark:bg-gray-700" />
        </div>
        { isSidebarOpen && tabState.activeTabId === 10 && 
            <div className="flex-grow pl-6 p-2 w-full bg-navBarBottomBg flex flex-col gap-0.5 rounded-tl-[140px]">
                {/* <SideBarActiveTabs isSidebarOpen={isSidebarOpen} /> */}
              <>
                <div className="flex justify-between items-center py-4 px-10">
                  <h3 className="text-sm text-white text-ellipsis overflow-hidden text-text-secondary-light">{translationTexts['chat_history']}</h3>
                  <NewChatBtn />
                </div>
                <hr className="mx-5 h-0.5 mt-[-14px] max-w-full bg-white border-0 rounded" />
                <div className="max-h-60 overflow-y-auto no-scrollbar">
                  <SideBarHistoryTabs isSidebarOpen={isSidebarOpen} />
                </div>
              </>
            </div>
        }
        {/* {isSidebarOpen && <ScreenCapture />} */}
        <div className={`flex flex-col items-center justify-end gap-0.5 relative bg-navBarBottomBg px-5 pb-3 ${isSidebarOpen && tabState.activeTabId === 10 ? '' : 'rounded-tl-[140px]'} h-full`}>
          <h4 className={`text-center font-bold ${isSidebarOpen ? "text-md" : "text-xs"} ${remainingCredit && remainingCredit > 0 ? "text-[#666666]" : "text-[#f27405]"}`}>{isSidebarOpen ? "Credits: " : ''} </h4>
          <div className="flex flex-row gap-2 items-center">          
            {typeof remainingCredit === 'number' && <p className={`text-[#339900] font-light  ${isSidebarOpen ? "text-xl" : 'text-md'}`}>{Math.round(remainingCredit)}</p>}
           {isSidebarOpen ? <SVGIcon size="25px" icon="CREDIT_ICON" /> : <SVGIcon size="18px" icon="CREDIT_ICON" />  } 
          </div>
          {isSidebarOpen && <ProgressBar credit={Math.round(remainingCredit!) || 0}/>}
          <InfoModal isVisible={isModalVisible} isSideBarOpen={isSidebarOpen} logout={logout} updateGlobalLanguage={updateGlobalLanguage} setModalVisible={setIsModalVisible} />
        </div>
        <div className="flex flex-col gap-2 w-full justify-center items-center bg-navBarBottomBg">
          {/* {isSidebarOpen ?
            <div className="flex flex-row items-center jusitfy-left gap-3 p-2 w-[80%] bg-transparent border ring-[#F6F6F6] rounded cursor-pointer">
              <SVGIcon size="38px" icon="ENGLISH_ICON" />
              <p className="text-[#0D3D62] w-full text-sm font-medium">English</p>
              <SVGIcon size="25px" icon="LANGUAGE_ARROW_ICON" />
            </div>
            :
            <SVGIcon size="38px" icon="ENGLISH_ICON" />
          } */}
          {!isSidebarOpen && <div onClick={logout} className={`w-[85%] flex flex-row gap-1 overflow-hidden items-center ${isSidebarOpen ? 'justify-center' : 'justify-center'} pb-5 p-2`}  >
             <SVGIcon size="25px" icon="LOGOUT_ICON" /> 
          </div>}
          {isSidebarOpen && 
            <div className="p-2">
                <button className="border p-1 w-8 h-8 rounded-full flex items-center justify-center bg-white" 
                  onClick={() => setIsModalVisible(!isModalVisible)}
                >{getInitials(name)}</button>
            </div>
          }
        </div>
      </div>
    </aside>
  );
};
