import { SVGIcon, Section } from "../../assets";
import { SideBarTabs } from "./SideBarTabs";

export const SideBarSectionTabs = ({
  section,
  isSidebarOpen,
}: {
  section: Section;
  isSidebarOpen: Boolean;
}) => {
  return (
    <div
      className={`${!isSidebarOpen ?
        `absolute rounded-md shadow-lg bg-primary-light top-0 left-[70px] z-20 border p-2 w-44` :
        "flex flex-col pt-2"
        }`}
    >
      <ul className="rounded-lg">
        {section.tabs.map((tab, tabIndex) => (
          <li key={tabIndex} className={`flex items-center whitespace-nowrap ${!isSidebarOpen ? "" :"py-1"}`}>
            <div className={`flex items-center w-full  ${isSidebarOpen && "max-h-8 pl-4"}`}>
              {isSidebarOpen &&
                <div className="relative flex items-center h-full">
                  <SVGIcon icon="MENU_LINE_ICON" className={`${tabIndex + 1 == section.tabs.length ? "bottom-[4px]" : ""} absolute max-h-16`} />
                  <SVGIcon icon="MENU_RADIUS_ICON" size="20px" className="absolute left-[7.5px] z-20" />
                </div>
              }

              <div className={`${isSidebarOpen && "pl-[24px]"} w-full`}>
                <SideBarTabs
                  tabs={tab}
                  isSidebarOpen={isSidebarOpen}
                  isBelongToSection={true}
                />
              </div>
            </div>

          </li>
        ))}
      </ul>
    </div>
  );
};