import { Tabs } from "../../assets";
import { useActiveTabs } from "../../providers";
import { useCredit } from "../../providers";
import { forbiddenApps } from "../../constants/constant";
import { translations } from "../../translations";
import { TranslationsType } from "./SideBarActiveTabs";
import { TranslationKeys } from "./SideBarActiveTabs";
import { useAuth } from "../../providers";
import { useEffect, useRef } from "react";
import { useHover } from "../../hooks";
import { SVGIcon } from "../../assets";
import { DotIcon } from "../../pages/ChatGPT/components";


export const SideBarTabs = ({
  tabs,
  isSidebarOpen,
  isBelongToSection,
}: {
  tabs: Tabs;
  isSidebarOpen: Boolean;
  isBelongToSection: Boolean;
}) => {
  const { openTab, tabState, closeTab } = useActiveTabs();
  const {remainingCredit} = useCredit()
  const {language} = useAuth()
  const appNamesTranslations = translations as TranslationsType;
  const translationTexts = appNamesTranslations['app_names'][language as keyof typeof translations['app_names']];
  const divRef = useRef(null);
  const { isHover } = useHover(divRef);


  const classic =
    `${isBelongToSection ? "w-full" : ""} flex items-center w-full  rounded-lg hover:bg-welcomeBackground`;
  const sidebarOpt = isSidebarOpen ? "w-full" : "justify-left";
  const hoverOpt =
    "hover:opacity-100";
  const tabsBtnClassName = `${classic} ${sidebarOpt} ${hoverOpt} ${typeof remainingCredit === 'number' && remainingCredit <= 0 && forbiddenApps.includes(tabs.appId) ? 'cursor-not-allowed': ''}`;

  return (
    <div ref={divRef}
      className={`flex relative text-white hover:text-white ${
        !isSidebarOpen && !isBelongToSection && "justify-center"
      } ${isSidebarOpen && "justify-between w-full"} ${typeof remainingCredit === 'number' && remainingCredit <= 0 && forbiddenApps.includes(tabs.appId) ? 'bg-gray-200 rounded-lg': ''}`}
    >
      <div className="flex items-center flex-row">
        <button disabled={typeof remainingCredit === 'number' && remainingCredit <= 0  && forbiddenApps.includes(tabs.appId)} onClick={() => openTab(tabs)} className={tabsBtnClassName}>
          {!isBelongToSection && <span>{tabs.icon}</span>}
          {(isSidebarOpen || isBelongToSection) && (
            <span className={`text-sm	 ${!isBelongToSection ? "pl-3" : "pl-1"}`}>{tabs.id === 0 ? translationTexts[tabs.appId as TranslationKeys].replace(/ /g, '\u00A0') : tabs.title}</span>
          )}
        </button>
        {
          tabs.id !== 0 && tabState.tabs.some(tab => tab.id === tabs.id) && (
            <button
              key={tabs.id}
              onClick={() => closeTab(tabs.id)}
              className={`absolute right-0
              ${tabs.id !== tabState.activeTabId && !isHover && "opacity-50"}
              ${!isSidebarOpen && !isBelongToSection ? "" : "pr-2"}
              `}
            >
              {isHover ? <SVGIcon icon="CLOSE_ICON" size="16px" /> : <DotIcon />}
            </button>
          )}
      </div>
      
    </div>
  );
};
