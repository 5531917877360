// SVGIcon.tsx
import React from "react";
import { SVGIcons } from "./icons";

export type SVGIconsType = keyof typeof SVGIcons;

interface SVGIconProps {
  icon: SVGIconsType;
  size?: string;
  className?: string;
  wrapper?: string;
}

export const SVGIcon: React.FC<SVGIconProps> = ({
  icon,
  size = "24px",
  className,
  wrapper
}) => {
  const IconComponent = SVGIcons[icon];

  if (wrapper) {
    return (
      <div className={wrapper}>
        <IconComponent style={{ width: size }} className={className} />
      </div>
    )
  } else {
    return (
      <IconComponent style={{ width: size }} className={className} />
    )
  }

};
