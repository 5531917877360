import { useChatGPT } from "../providers";
import { useAuth } from "../../../providers";
import { translations } from "../../../translations";

export const ChatGPTModalChoiceModal = ({ }: {}) => {
    const { gptModel,setModalStatus } = useChatGPT()
    const {language} = useAuth()
    const langKey = language as keyof typeof translations['secure_chat'];

    const getTranslation = (key: keyof typeof translations['secure_chat_new']['EN']) => {
        const langKey = language as keyof typeof translations['secure_chat_new'];
        return translations['secure_chat_new'][langKey][key];
    }; 


    return (
        <div className="">
            <div className="flex items-center w-full m-1.5 p-2.5 text-sm cursor-pointer focus-visible:outline-0 radix-disabled:pointer-events-none radix-disabled:opacity-50 group relative focus-visible:bg-[#f5f5f5] rounded-md my-0 px-3 mx-2 radix-state-open:bg-[#f5f5f5] gap-2.5 py-3 !pr-3">
                <span className="text-sm text-gray-400">{translations['secure_chat_new'][langKey]['model_text']}</span>
            </div>
            {
                gptModel.chatModelOpt.map((e:any, i:any) => {
                    const title = e.title;
                    const description = getTranslation(e.title as keyof typeof translations['secure_chat_new']['EN']);
                    const isCurrentApp = e.id == gptModel.selectedChatModel;
                    const bgColorClass = isCurrentApp ? `text-textSecondary` : 'text-textSecondary hover:bg-gray-200/50';
                    const transitionClass = isCurrentApp ? 'transition-all duration-300 ease-in-out' : '';
                    const hoverClass = !isCurrentApp ? 'hover:bg-gray-50' : '';

                    return (
                        <div onClick={() => { if (!isCurrentApp) gptModel.setSelectedChatModel(e.id);setModalStatus('CLOSE') }}
                            className={`flex items-center w-full text-sm cursor-pointer focus-visible:outline-0 focus-visible:bg-[#f5f5f5] rounded-md gap-2.5 py-3 px-6 ${bgColorClass} ${transitionClass} ${hoverClass}`} >
                            <div className="flex grow items-center justify-between gap-2">
                                <div>
                                    <div className="flex items-center gap-3">
                                        {e.icon}
                                        <div>                                                
                                            {title}
                                            <div className={`${isCurrentApp ? 'text-[#7d7d7d]': 'text-[#7d7d7d]'} text-xs`}>{description}</div>
                                        </div>
                                    </div>
                                </div>
                                <span className="flex h-7 w-7 flex-shrink-0 items-center justify-center">
                                    {
                                        gptModel.selectedChatModel == e.id &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24" className="icon-md"><path fill="currentColor" fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12m14.076-4.068a1 1 0 0 1 .242 1.393l-4.75 6.75a1 1 0 0 1-1.558.098l-2.5-2.75a1 1 0 0 1 1.48-1.346l1.66 1.827 4.032-5.73a1 1 0 0 1 1.394-.242" clip-rule="evenodd"></path></svg>
                                    }
                                </span>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}