import React, { useEffect, useState } from 'react';
import { translations } from '../../translations';

interface SearchBarProps {
    tags: string[];
    setSearchText: (value: string | null) => void;
    searchText: string;
    setTag: (value: string | null) => void;
    tag: string;
    language: string;
}

const SearchBar: React.FC<SearchBarProps> = ({ tags, setSearchText,searchText,setTag,tag,language }) => {
    const [searchType, setSearchType] = useState<'title' | 'tags'>('title');
    const translationTexts = translations['promptopedia'][language as keyof typeof translations['promptopedia']];


    useEffect(()=>{
        if(searchType === 'title') setTag(null)
        if(searchType === 'tags') setSearchText(null)
    },[searchType])

    return (
        <div className="flex w-full justify-center items-center gap-2">
            {translationTexts.search_text}
            <select
                className="border border-selectBorder text-selectText bg-selectBg rounded py-2 focus:outline-none"
                value={searchType}
                onChange={(e) => setSearchType(e.target.value as 'title' | 'tags')}
            >  
                <option value="title">{translationTexts.title_search}</option>
                <option value="tags">{translationTexts.tag_search}</option>
            </select>
            {searchType === 'title' ? (
                <input
                    type="text"
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                    }}
                    className="border border-inputBorder text-inputText placeholder-inputText placeholder-opacity-70 rounded px-4 py-2 flex-grow focus:outline-none"
                    placeholder={translationTexts.placeholder_search}
                />
            ) : (
                <select 
                    defaultValue={'-'}
                    value={tag}
                    onChange={e => setTag(e.target.value)}
                    className="border border-selectBorder text-selectText bg-selectBg rounded px-4 py-2 focus:outline-none"
                >
                    {tags.map(tag => (
                        <option key={tag} value={tag}>{tag}</option>
                    ))}
                </select>
            )}
        </div>
    );
}

export default SearchBar;
