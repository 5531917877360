import { FaMoon, FaSun } from "react-icons/fa";
import { useTheme } from "../../providers";

export const SwitchThemeButton = () => {
  const { appTheme, toggleTheme } = useTheme();

  return (
    <button
      className={`p-2 rounded flex items-center justify-center ${
        appTheme === "dark"
          ? "text-text-primary-dark hover:bg-secondary-dark hover:text-text-secondary-dark"
          : "text-text-primary-light hover:bg-secondary-light hover:text-text-secondary-light"
      } `}
      aria-label="Toggle theme"
      onClick={toggleTheme}
    >
      {appTheme === "dark" ? <FaMoon size={20} /> : <FaSun size={20} />}
    </button>
  );
};
