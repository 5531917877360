import { useState } from "react";
import { ModelGeminiIcon, ModelGPT4Icon, ModelGPTClassicIcon, ModelMistralIcon } from "../components";

export type ChatModel = 'gpt' | 'gpt4' | 'mistral' | 'gemini';

type ChatAppData = {
    id: ChatModel;
    title: string;
    icon: JSX.Element;
    description: string;
    bgColor: string;
}

export interface ChatGPTModelHook {
    chatModelOpt: ChatAppData[];
    selectedChatModel: ChatModel;
    setSelectedChatModel: React.Dispatch<React.SetStateAction<ChatModel>>;
}

export const useChatGPTModel = (): ChatGPTModelHook => {
    const chatModelOpt: ChatAppData[] = [
        {
            id: 'gpt',
            title: "GPT 3.5",
            icon: <ModelGPTClassicIcon />,
            description: 'Plus rapide pour les tâches au quotidien',
            bgColor: "bg-[#6FA99B]",
        },
        {
            id: 'gpt4',
            title: "GPT 4",
            icon: <ModelGPT4Icon />,
            description: 'Idéal pour les tâches complexes',
            bgColor: "bg-gradient-to-br from-[#160c06] to-gray-600"
        },
        {
            id: 'mistral',
            title: "Mistral",
            icon: <ModelMistralIcon />,
            description: 'Efficace et précis pour des analyses pointues',
            bgColor: "bg-gradient-to-br from-[#eb5829] to-[#ee792f]",
        },
        {
            id: 'gemini',
            title: "Gemini",
            icon: <ModelGeminiIcon />,
            description: 'Polyvalent et performant pour des solutions innovantes',
            bgColor: "bg-gradient-to-br from-[#836fc5] to-[#4f87d2]",
        }
    ];

    const [selectedChatModel, setSelectedChatModel] = useState<ChatModel>('gpt')


    return { chatModelOpt, selectedChatModel, setSelectedChatModel };
};