import { useEffect, useRef } from "react";
import { Section } from "../../assets";
import { useHover } from "../../hooks";
import { useToggle } from "@uidotdev/usehooks";
import { SideBarSectionBtn } from "./SideBarSectionBtn";
import { SideBarSectionTabs } from "./SideBarSectionTabs";

export const SideBarSection = ({
  section,
  isSidebarOpen,
}: {
  section: Section;
  isSidebarOpen: Boolean;
}) => {
  const divRef = useRef(null);
  const { isHover } = useHover(divRef);
  const [isSectionOpen, toggleIsSectionOpen] = useToggle(false);
  const showSection = (!isSidebarOpen && isHover) || isSectionOpen;

  useEffect(() => {
    if (isSectionOpen) {
      toggleIsSectionOpen(false);
    }
  }, [isSidebarOpen]);

  return (
    <div
      className={`${!isSidebarOpen && "flex justify-center"} ${
        isSidebarOpen && "justify-between"
      }`}
      ref={divRef}
    >
      <SideBarSectionBtn
        section={section}
        isSidebarOpen={isSidebarOpen}
        isHover={isHover}
        isSectionOpen={isSectionOpen}
        toggleIsSectionOpen={toggleIsSectionOpen}
      />
      {showSection && (
        <SideBarSectionTabs section={section} isSidebarOpen={isSidebarOpen} />
      )}
    </div>
  );
};
