import { useAuth } from "../../../providers";
import { useChatGPT } from "../providers";
import { translations } from "../../../translations";
import { SettingsIcon, SearchIcon } from "./Icon";

export const ChatGPTTop = ({ }: {}) => {
    const { tokenInfo,language } = useAuth();
    const firstLetterName = tokenInfo?.name.substring(0, 1).toUpperCase() || "P"

    const {
        modalStatus,
        setModalStatus,
        gptModel
    } = useChatGPT();

    const item = gptModel.chatModelOpt.find((chat: any) => chat.id === gptModel.selectedChatModel);
    const langKey = language as keyof typeof translations['secure_chat'];

    return (
        <div className="sticky top-0 p-3 mb-1.5 flex items-center justify-between z-10 h-14 bg-white">
            <div className="absolute start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2"></div>
            <div className={`flex`} onClick={()=>{setModalStatus("MODEL")}}>
                <div className={`text-[#7d7d7d] group flex cursor-pointer items-center gap-1 rounded-lg py-1.5 px-3 hover:bg-gray-100 overflow-hidden ${modalStatus == "MODEL" && "bg-gray-100"}`}>
                    <div className="text-black font-medium text-lg whitespace-nowrap">
                        {item?.title}
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="black" viewBox="0 0 24 24"><path fill="black" fill-rule="evenodd" d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414" clip-rule="evenodd"></path></svg>
                </div>
            </div>
            <div className="flex gap-3">
                <button className="flex items-center justify-center gap-1 bg-transparent border border-black border-opacity-50 rounded-xl px-2 py-0.5 flex items-center justify-center text-lg rounded-full text-black" onClick={()=>{setModalStatus("SEARCH_PROMPT")}}>
                    <SearchIcon/>           
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="black" viewBox="0 0 24 24"><path fill="black" fill-rule="evenodd" d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414" clip-rule="evenodd"></path></svg>
                </button>
                <button className="flex items-center justify-center gap-1 bg-transparent border border-black border-opacity-50 rounded-xl px-2 py-0.5 flex items-center justify-center text-lg rounded-full text-black" onClick={()=>{setModalStatus("PARAMS")}}>
                    <SettingsIcon/>              
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="black" viewBox="0 0 24 24"><path fill="black" fill-rule="evenodd" d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414" clip-rule="evenodd"></path></svg>
                </button>
            </div>
        </div>
    )
}
