export const config = {
  "fonts": {
    "primary": {
      "name": "Arial, sans-serif"
    },
    "secondary": {
      "name": "MyFont, sans-serif",
      "path": "/src/fonts/MyFont.ttf"
    }
  },
  "logo": {
    "src": "LOGO_ICON",
    "alt": "Logo",
    "whiteSrc": "FOLDED_NAV_ICON"
  },
  "colors": {
    "backgroundGlobal": "background-light",
    "headerText": "white",
    "textPrimary": "white",
    "textSecondary": "black",
    "navBarTopBg": "#333333",
    "navBarBottomBg": "#191919",
    "buttonBg": "black",
    "buttonBorder": "#232323",
    "buttonHoverBorder": "#232323",
    "buttonText": "white",
    "buttonHoverBg": "white",
    "buttonHoverText": "black",
    "focusRing": "#232323",
    "selectBg": "white",
    "selectBorder": "#232323",
    "dropdownBg": "white",
    "dropdownBorder": "#232323",
    "dropdownText": "black",
    "inputBg": "white",
    "inputBorder": "#232323",
    "inputText": "#232323",
    "dropdownHoverText": "blue",
    "loaderBackgroundColor": "#232323",
    "welcomeBackground": "#2100a5",
    "backgroundCardHome": "transparent",
    "bgColor": [
      "#fe5e08",
      "#006df0",
      "#2100a5",
      "#232323"
    ],
    "textColor": [
      "#fe5e08",
      "#006df0",
      "#2100a5",
      "black"
    ],
    "borderColor": [
      "#fe5e08",
      "#006df0",
      "#2100a5",
      "black"
    ],
    "homeGPTCardBg": "#232323",
    "homeGPTCardBorder": "white",
    "homeGPTCardText": "white"
  },
  "texts": {
    "companyName": "EUROPEAN DIGITAL GROUP",
    "homeText": "European Digital Group est le spécialiste de la transformation digitale des entreprises. Grâce à nos experts, nous maîtrisons chaque levier du digital pour vous offrir un accompagnement sur-mesure et accélérer votre croissance."
  }
};