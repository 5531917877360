import { useState,useEffect } from "react"
import { usePromptDrive } from "../../../hooks/promptDrive"
import { useAuth } from "../../../providers"
import { PromptData } from "../../PromptDrive/PromptDrive"
import { translations } from "../../../translations"
import { ChatLogo, ChatLogoType } from "../../../assets/SVGIcon/ChatLogo"
import { useChatGPT } from "../providers"

export const ChatGPTModalSearchPrompts = ({ }: {}) => {
    const [searchInput, setSearchInput] = useState<string | null>(null)
    const {token,language,tokenInfo} = useAuth()
    const {prompts,getAllPrompts} = usePromptDrive(token)
    const [filteredPrompts, setFilteredPrompts] = useState<PromptData[]>([]);



    

    useEffect(()=>{
        getAllPrompts()
    },[])

    useEffect(() => {
        if(searchInput?.length === 0) {
            setFilteredPrompts([])
            return
        }
        let filteredPrompts = prompts.filter(prompt => {
            return (
                prompt.access_level === 'read_only' ||
                (prompt.access_level === 'only_me' && prompt.created_by_email === tokenInfo?.email)
            )
        });
        if (searchInput && searchInput.length > 0) {
            filteredPrompts = filteredPrompts.filter(prompt => (prompt.title.toLowerCase().includes(searchInput.toLowerCase()) || prompt.promptContent.toLowerCase().includes(searchInput.toLowerCase())));
        }

        setFilteredPrompts(filteredPrompts);
    }, [searchInput]);


    return (
            <div className="mx-auto flex flex-col flex-1 gap-3 text-base md:gap-4 lg:gap-4 md:max-w-3xl lg:max-w-[45rem] xl:max-w-[100rem]">
                <div className="flex flex-col items-center">
                    <div className="flex items-end w-full p-2 bg-gray-100 rounded-[26px]">
                        <textarea
                            placeholder='Search for a prompt in the promptopedia...'
                            className="flex-1 px-2 mb-1 bg-transparent border-none outline-none max-h-52 overflow-y-auto focus:ring-0 focus-visible:ring-0 m-0 resize-none border-0"
                            value={searchInput!}
                            onChange={(e) => setSearchInput(e.target.value)}
                            rows={1}
                        />
                    </div>
                </div>
                {filteredPrompts.length > 0 && 
                <div className="flex flex-col gap-5 h-full">
                    {filteredPrompts.map((promptElem:PromptData) => (
                        <PromptSearchGPT data={promptElem} language={language}/>
                    ))
                }
                </div>
                }
            </div>
    )
}


interface PromptProps {
    data: PromptData;
    language: string;
}

export const PromptSearchGPT:React.FC<PromptProps> = ({data,language}) => {
    const [iconKey, setIconKey] = useState((data.model.toUpperCase() + "_CHAT_ICON") as ChatLogoType);
    const translationTexts = translations['promptopedia'][language as keyof typeof translations['promptopedia']];
    const {tokenInfo} = useAuth()
    const email = tokenInfo?.email
    const [isCopied, setIsCopied] = useState(false)
    const {setModalStatus } = useChatGPT()


    useEffect(() => {
        setIconKey((data.model.toUpperCase() + "_CHAT_ICON") as ChatLogoType);
    }, [data.model]);

    const copyTextToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(data.promptContent);
          setIsCopied(true);
          setTimeout(() => {setIsCopied(false);setModalStatus('CLOSE')}, 1000);
        } catch (err) {
          console.error('Failed to copy text: ', err);
          setIsCopied(false);
        }
    };
    

    return(
        <div className={`flex flex-row w-full p-2.5 bg-gray-100 rounded-[26px] items-center gap-3 relative border border-dropdownBorder overflow-hidden overflow-x-scroll no-scrollbar`}>
            <> 
            <div className="flex flex-row">
                <ChatLogo icon={iconKey}/>
            </div>
            <div className="flex w-full flex-row items-center">
                <p className="text-textSecondary text-md p-1">{data.title}:</p>
                <p className="text-textSecondary text-md p-1">{data.promptContent.substring(0,45)}...</p>
            </div>
            <div className="flex flex-row gap-3 w-fit">
                <button className="border px-4 py-1.5 w-fit border-welcomeBackground text-buttonText rounded-md bg-buttonBg hover:shadow-xl" onClick={()=>copyTextToClipboard()}>{isCopied ? 'Copied': translationTexts.copy_button}</button>
            </div>
            </>
        </div>
    )
}