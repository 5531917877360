interface FavCardProps {
  title: string;
  content: string[];
  icon: JSX.Element;
}

export const FavCard: React.FC<FavCardProps> = ({ title, content, icon }) => {
  return (
    <div className="bg-white shadow-md rounded-lg py-2.5 px-3 h-full">
      <div className="flex items-center">
        <div className="bg-[#F6F6F6] p-1 rounded-md text-text-primary-light flex-shrink-0">
          {icon}
        </div>
        <div className="ml-3">
          <h2 className="text-text-primary-light font-medium text-xl">
            {title}
          </h2>
        </div>
      </div>
      {content.map((text, index)=>(
        <p key={index} className="text-text-primary-light text-sm pt-2.5">{text}</p>
      ))}
    </div>
  );
};
