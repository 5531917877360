import { useChatGPT } from "../providers";
import { WriteIcon } from "./Icon";
import { v4 as uuidv4 } from 'uuid';

export const NewChatBtn = ({ }: {}) => {

    const {
        gptConfig
    } = useChatGPT();

    return (
        <div onClick={() => {

            const emptySession = Object.values(gptConfig.securedGPTHistory).find(
                session => session.conversation.length === 0
            );
            
            if (emptySession) {
                const existingSessionId = emptySession.session_id;
                gptConfig.setSelectedChatId(existingSessionId)
            } else {
                const newSessionId = uuidv4();
                const date = new Date();
                const isoString = date.toISOString();
                const formattedDate = isoString.replace(/\.\d{3}Z$/, '+00:00');
                gptConfig.setSecuredGPTHistory(prevHistory => ({
                    ...prevHistory,
                    [newSessionId]: {
                        session_id: newSessionId,
                        title: "New chat...",
                        data_name: null,
                        data_type: 'text',
                        conversation: [],
                        timestamp: formattedDate
                    }
                }));
                gptConfig.setSelectedChatId(newSessionId)
            }
        }}
            className="text-text-primary-light group flex cursor-pointer items-center rounded-lg py-1.5 px-2">
            <WriteIcon />
        </div>
    )
}
