import { NoCreditModal } from "../../components/NoCreditModal";
import { useActiveTabs, useCredit } from "../../providers";

export const MainTemplate = () => {
  const { tabState } = useActiveTabs();
  const {remainingCredit} = useCredit()

  return (
    <main className="flex-1 overflow-y-auto relative">
      {typeof remainingCredit === 'number' && remainingCredit <= 0 && <NoCreditModal/>}
      {" "}
      {tabState.tabs.map((tab) => (
        <div
          key={tab.id}
          style={{
            display: tabState.activeTabId === tab.id ? "block" : "none",
          }}
          className="flex-1 flex h-full max-w-full"
        >
          {tab.app()}
        </div>
      ))}
    </main>
  );
};
