import React from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from 'xlsx';
import { useState } from "react";
import { translations } from "../../translations";

interface DropzoneExcelProps {
    setFile: React.Dispatch<React.SetStateAction<File | null>>;
    setFileName: React.Dispatch<React.SetStateAction<string>>;
    fileName: string;
    onExcelParsed: (data: any[]) => void;
    setExcelUploadedSucces: React.Dispatch<React.SetStateAction<boolean>>
    setTotalRows: React.Dispatch<React.SetStateAction<string>>
    setMaxTotalRows: React.Dispatch<React.SetStateAction<number>>
    language: string;
}



const parseExcelFile = (file: File): Promise<XLSX.WorkSheet> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      resolve(worksheet);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsBinaryString(file);
  });
};


export const DropzoneExcel:React.FC<DropzoneExcelProps> = ({setFile,setFileName,fileName,onExcelParsed,setExcelUploadedSucces,setMaxTotalRows,setTotalRows,language}) => {
    // const [excelData, setExcelData] = useState<Array<any>>([]);
    const translationTexts = translations['sentiment_analysis'][language as keyof typeof translations['sentiment_analysis']];
    const onDrop = async (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            try {
                const file = acceptedFiles[0];
                setFile(file);
                setFileName(file.name);
                const worksheet = await parseExcelFile(file); 
                const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                onExcelParsed(json);
                const numberOfRows = json.length;
                setTotalRows(String(numberOfRows));
                setMaxTotalRows(numberOfRows);
                setExcelUploadedSucces(true);
            } catch (error) {
                console.error('Error parsing file:', error);
                setExcelUploadedSucces(false);
            }
        }
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        },
    });

    return (
        <div
          {...getRootProps()}
          className={`dropzone border-dashed border-2 border-black ${
            fileName && "border-text-primary-light"
          } rounded p-4 text-center cursor-pointer`}
        >
            <input {...getInputProps()} />
            <p>
              {translationTexts.dropzone_file}
            </p>
            <p className="text-sm text-textSecondary mt-2">
              {translationTexts.accepted_file}
            </p>
            {fileName && (
                <p className="text-sm text-textSecondary mt-2">
                {translationTexts.selected_file} {fileName}
                </p>
            )}
        </div>
      );
}
