// ChatLogo.tsx
import React from "react";
import { ChatLogoIcon } from "./icons";

export type ChatLogoType = keyof typeof ChatLogoIcon;

interface ChatLogoProps {
  icon: ChatLogoType;
  className?: string;
}

export const ChatLogo: React.FC<ChatLogoProps> = ({ icon, className }) => {
  const IconComponent = ChatLogoIcon[icon];

  return (
    <IconComponent className={className} />
  );
};
