import React, { useEffect, useState } from "react";
import { usePromptDrive } from "../../hooks/promptDrive";
import { useAuth } from "../../providers";
import { v4 as uuidv4 } from 'uuid';
import { PromptData } from "./PromptDrive";
import { translations } from "../../translations";
import { Folder } from "../../hooks/promptDrive";

export const platformOptions =  [
    { label: "GPT 3.5", value: "gpt" },
    { label: "GPT 4", value: "gpt4" },
    { label: "Mistral", value: "mistral" },
    { label: "Gemini", value: "gemini" },
]

export const tags = [
    "-",
    "CommunicationSkills",
    "EmailMarketing",
    "DigitalCommunication",
    "MarketingStrategy",
    "ContentMarketing",
    "BrandCommunication",
    "SocialMediaMarketing",
    "AdvertisingCampaigns",
    "CustomerEngagement",
    "PRStrategy"
];

interface ModaleCreatePrompt {
    folders: Folder[];
    setShowPromptModal: (value: boolean) => void;
    setNewPromptAdded: (value: boolean) => void;
    loader: boolean;
    prompts: PromptData[];
}

export const ModalCreatePrompt: React.FC<ModaleCreatePrompt> = ({folders, setShowPromptModal, setNewPromptAdded,prompts}) =>{
    const {token,tokenInfo,language} = useAuth()
    const email = tokenInfo?.email || ''
    const name = tokenInfo?.name || ''
    const [title,setTilte] = useState<string>('')
    const [content,setContent] = useState<string>('')
    const [platform,setPlatform] = useState<string>('mistral')
    const [folder, setFolder] = useState<string>(folders[0].name || '')
    const [tagsPrompt, setTagsPrompt] = useState<string[]>([])
    const [notes, setNotes] = useState<string>('')
    const [titleError, setTitleError] = useState<boolean>(false)
    const [contentError, setContentError] = useState<boolean>(false)
    const [accessLevel, setAccessLevel] = useState<string>('read_only')
    const {addPrompt} = usePromptDrive(token)
    const [loader,setLoader] = useState<boolean>(false)
    const translationTexts = translations['promptopedia'][language as keyof typeof translations['promptopedia']];




    const handleChangeTag= (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedTag = e.target.value;
        if(tagsPrompt.includes(selectedTag)) return;
        setTagsPrompt([...tagsPrompt,selectedTag]);
    }

    const removeTag = (tag: string) => {
        if(tagsPrompt.length === 1) return //here choose if authortise no tag
        setTagsPrompt(currentThemes => currentThemes.filter(theme => theme !== tag));
    }

    useEffect(()=>{
        if(prompts.some(prompt => prompt.title.toLocaleLowerCase() === title.toLocaleLowerCase())) setTitleError(true)
        else setTitleError(false)
    },[title,prompts])

    useEffect(()=>{
        if(content.length === 0) setContentError(false)
        else setContentError(false)
    },[content])

    const handleSubmitPrompt = async() =>{
        setLoader(true)
        const prompt = {
            id: uuidv4(),
            title,
            promptContent: content,
            tags: tagsPrompt,
            model: platform,
            folder,
            note: notes,
            created_by: name,
            access_level: accessLevel,
            created_by_email: email,
        }
        await addPrompt(prompt)
        //console.log('done')
        setNewPromptAdded(true)
        setShowPromptModal(false)
        setLoader(false)
    }


    return(
        <div className="absolute h-full backdrop-blur-md inset-0 z-9999 flex justify-center items-center" style={{ zIndex: 9 }}>
            <div className="flex flex-col gap-4 w-[80%] h-[90%] items-start inset-0 border border-dropdownBorder bg-welcomeBackground bg-opacity-70 rounded-xl p-8 relative overflow-y-scroll no-scrollbar">
                <div className="p-1 absolute top-0 right-1 cursor-pointer transform transition-transform hover:rotate-180" onClick={()=> setShowPromptModal(false)}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="white"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </div>
                <div className="flex flex-col w-full gap-1">
                    <label className="text-textPrimary">{translationTexts.modal_title}</label>
                    <input className="w-full p-2 rounded-md bg-inputBg border border-inputBorder placeholder-inputText focus:outline-none" value={title} onChange={(e)=>setTilte(e.target.value)}/>
                    {titleError && <label className="text-red-600 text-xs font-bold pt-1">{translationTexts.modal_error_prompt}</label>}
                </div>                    
                <div className="flex flex-col items-start gap-1 w-full">
                    <label className="text-textPrimary">{translationTexts.modal_prompt}</label>
                    <textarea
                        className="mb-2 p-2 rounded-md shadow-lg bg-inputBg border border-inputBorder w-full focus:outline-none"
                        value={content}
                        onChange={(e)=>setContent(e.target.value)}
                        style={{ height: '100px', minHeight: '100px', overflowY: 'auto'}}
                    />
                    <label className="text-textPrimary text-xs font-bold">{translationTexts.modal_prompt_explanation}</label>
                </div>
                <div className="flex flex-col gap-1 w-full">
                    <label className="text-textPrimary">{translationTexts.language_model}</label>
                    <select
                        value={platform}
                        onChange={(e)=>setPlatform(e.target.value)}
                        className="w-full p-2 rounded-md text-sm bg-selectBg text-selectText mb-1 focus:outline-none"
                    >
                        {platformOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    <label className="text-textPrimary">{translationTexts.folder_text}</label>
                    <select
                        value={folder}
                        onChange={(e)=>setFolder(e.target.value)}
                        className="w-full p-2 rounded-md text-sm bg-selectBg text-selectText mb-1 focus:outline-none"
                    > 
                        {folders.map((option) => (
                            <option key={option.name} value={option.name}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                    <label className="text-textPrimary">{translationTexts.modal_tags}</label>
                    <select
                        value={tagsPrompt[-1]}
                        onChange={handleChangeTag}
                        className="w-full p-2 rounded-md text-sm bg-selectBg text-selectText mb-1 focus:outline-none"
                    > 
                        {tags.filter(elem=> elem !== '-').map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <div className="flex flex-wrap gap-2 mt-2 text-xs">
                            {tagsPrompt.map((selectedTag, index) => (
                                <span key={index} className="px-2 py-1 bg-gray-200 rounded-full cursor-pointer" onClick={() => removeTag(selectedTag)}>
                                    {selectedTag} &times;
                                </span>
                            ))}
                    </div>
                </div>
                <div className="flex flex-col w-full gap-2">
                    <label className="text-textPrimary">{translationTexts.modal_notes}</label>
                    <textarea
                        className=" mb-2 p-2 rounded-md shadow-lg bg-inputBg w-full focus:outline-none"
                        value={notes}
                        onChange={(e)=> setNotes(e.target.value)}
                        style={{ height: '100px', minHeight: '100px', overflowY: 'auto'}}
                    />
                </div>
                <div className="flex flex-col w-full gap-2">
                    <label className="text-textPrimary">{translationTexts.modal_access}</label>
                    <div className="flex items-center gap-4">
                        <label className="flex items-center">
                            <input type="radio" name="access" checked={accessLevel === 'read_only'} onChange={() => { setAccessLevel('read_only') }} className="form-radio" />
                            <span className="ml-2 text-textPrimary">{translationTexts.modal_read_only}</span>
                        </label>
                        <label className="flex items-center">
                            <input type="radio" name="access" checked={accessLevel === "only_me"} onChange={() => { setAccessLevel('only_me') }} className="form-radio" />
                            <span className="ml-2 text-textPrimary">{translationTexts.modal_only_me}</span>
                        </label>
                    </div>
                </div>
                <button onClick={handleSubmitPrompt} className={`w-full bg-buttonBg text-buttonText rounded-md p-1 hover:bg-buttonHoverBg hover:text-buttonHoverText ${loader || title.length === 0 || accessLevel.length === 0 || content.length === 0 || folder.length === 0 || platform.length === 0 || titleError ? 'cursor-not-allowed' : ''}`} disabled={loader || title.length === 0 || content.length === 0 || folder.length === 0 || platform.length === 0 || titleError}>
                    {loader ? translationTexts.modal_loader: translationTexts.modal_save}
                </button>
            </div>
        </div>
    )
}