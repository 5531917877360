import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { jwtDecode } from "jwt-decode";

interface JwtPayload {
  email: string;
  app: string[];
  name: string;
}

type AuthContextType = {
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
  token: string | null;
  setIsAuthenticated: (status: boolean) => void;
  updateToken: () => void;
  verifyTokens: () => Promise<void>;
  fetchJWT: () => Promise<void>;
  loading: boolean;
  tokenInfo: JwtPayload | null
  getGlobalLanguage: () => Promise<void | null>;
  updateGlobalLanguage: (language: string) => Promise<void | null>;
  language: string;
  setLanguage: (language: string) => void;
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tokenInfo, setTokenInfo] = useState<JwtPayload | null>(null)
  const [language, setLanguage] = useState<string>(() => localStorage.getItem('language_global') || 'EN');

  const login = useCallback(() => {
    const isLocal = window.location.hostname === 'localhost';

    window.location.href = isLocal ? 'http://localhost:8000/auth/token' : '/auth/token';
  }, []);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode<JwtPayload>(token);
      const app = decoded.app
      console.log(app)
      // app.push("GenAI/NewsletterReport")
      const info: JwtPayload = {
        email: decoded.email,
        app: app,
        name: decoded.name
      }
      setTokenInfo(info)
    } else {
    }
  }, [token]);

  useEffect(() => {
    localStorage.setItem('language_global', language);
  }, [language]);

  async function fetchJWT() {
    try {
      const response = await fetch('/auth/get-jwt', {
        method: 'GET',
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Réponse réseau non ok.');
      const data = await response.json();
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("refresh_token", data.refresh_token);
      localStorage.setItem('comingLogin', 'login')


      setToken(data.access_token);
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  }

  async function getGlobalLanguage() {
    try{
      const response = await fetch('/auth/get-language',{
        method:'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      if (!response.ok) throw new Error('Réponse réseau non ok.');
      const data = await response.json();
      setLanguage(data.language);
    }
    catch(error){
      return null
    }
  }

  async function updateGlobalLanguage(language: string) {
    try{
        const response = await fetch(`/auth/update-language/${language}`, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) throw new Error('Réponse réseau non ok.');
        setLanguage(language);
    }
    catch(error){
      return null
    }
  }

  const logout = async () => {
    try {
      const response = await fetch('/auth/logout', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        console.error('Failed to logout');
      }
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      setIsAuthenticated(false);
      setToken(null);
    } catch (error) {
      console.error('Error occurred during logout:', error);
    }
  }

  const checkAccessTokenValidity = async () => {
    const accessToken = localStorage.getItem('access_token');
    const response = await fetch('/auth/valid_access', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: accessToken })
    });
    //if (response.status === 401) {
    //  await logout()
    //  return;
    //}
    const data = await response.json();
    return data.valid;
  };

  const checkRefreshTokenValidity = async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    const response = await fetch('/auth/valid_refresh', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: refreshToken })
    });
    //if (response.status === 401) {
    //  await logout()
    //  return;
    //}
    const data = await response.json();
    return data.valid;
  };

  const verifyTokens = async () => {
    if (isAuthenticated && token) {
      const isRefreshTokenValid = await checkRefreshTokenValidity();
      if (!isRefreshTokenValid) {
        logout();
        return;
      }

      const isAccessTokenValid = await checkAccessTokenValidity();
      if (!isAccessTokenValid) {
        updateToken();
      }
    }
  };

  const updateToken = async () => {
    const refresh_token = localStorage.getItem('refresh_token');
    const response = await fetch('/auth/refresh', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: refresh_token })
    });
    if (!response.ok) {
      console.error(`HTTP error! Status: ${response.status}`);
      logout()
    }
    const responseData = await response.json();
    const newAccessToken = responseData.access_token

    localStorage.setItem("access_token", newAccessToken);
    setToken(newAccessToken);
  };

  useEffect(() => {
    const storedAccessToken = localStorage.getItem('access_token');
    const storedRefreshToken = localStorage.getItem('refresh_token');
    if (storedAccessToken && storedRefreshToken) {
      setToken(storedAccessToken);
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, token, setIsAuthenticated, updateToken, verifyTokens, fetchJWT, loading, tokenInfo, getGlobalLanguage,updateGlobalLanguage,language,setLanguage }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      "useAuth doit être utilisé à l’intérieur d’un AuthProvider"
    );
  }
  return context;
};