import { ReactNode, useEffect, useState } from "react";
import { SVGIcon, SVGIconsType } from "../../assets";
import LoaderImage from "./EDG-texte-fond-transparent.png"
import {config} from "../../config"

export const LoaderBlue = () => <div className="lds-ellipsis-blue"><div></div><div></div><div></div><div></div></div>
export const Loader = () => <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
export const Check = () => <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 124 124">
    <polyline points="73.56 48.63 57.88 72.69 49.38 62" fill="none" stroke="hsl(0, 0%, 100%)" stroke-width="6px" stroke-linecap="round" />
</svg>
export const LoaderScreen = ({ iconType }: { iconType: string }) => {
    const icon: SVGIconsType = config.logo.src as SVGIconsType
   

    return (
        <div className='flex justify-center items-center w-full h-screen overflow-hidden inset-0 bg-loaderBackgroundColor'>
            <div className='relative w-full'>
                {/* <SVGIcon icon="EDG_WHITE_ICON" size='80px' className='invert brightness-0 '/> */}
                <div className="flex flex-col items-center justify-center">
                    <SVGIcon size={"290px"} icon={icon} className="scale-[1.4]" />
                    {iconType == "check" ? <Check /> : <Loader />}
                </div>
            </div>
        </div>
    );
};

interface LoaderComponentProps {
    loadingConfig: boolean;
    children: ReactNode;
}

export const LoaderComponent = ({ loadingConfig, children }: LoaderComponentProps) => {
    const [showLoader, setShowLoader] = useState(true);
    const [showCheckmark, setShowCheckmark] = useState(false);

    useEffect(() => {
        if (!loadingConfig) {
            setShowCheckmark(true);
            const timer = setTimeout(() => {
                setShowCheckmark(false);
                setShowLoader(false);
            }, 300);
            return () => clearTimeout(timer);
        }
    }, [loadingConfig]);

    if (showLoader) {
        return (
            <div className="flex h-screen justify-center items-center">
                {showCheckmark ? <LoaderScreen iconType="check" /> : <LoaderScreen iconType="loader" />}
            </div>
        );
    }

    return (
        <>
            {children}
        </>
    );
};

interface LoaderCircle {
    text?: string;
    height?: number;
    width?: number;
    uploadSuccess? : boolean;
}

export const LoaderCircle: React.FC<LoaderCircle> = ({ text, height, width, uploadSuccess }) => {
    return (
        <div className="flex justify-center items-center px-2">
            <div className="flex flex-col gap-3 justify-center items-center">
                <h1 className="text-blue-500 font-semibold text-xl">{text}</h1>
                {
                    uploadSuccess ? (
                        // Display a checkmark (V) when uploadSuccess is true
                        <svg className={`h-${height ? height : '10'} w-${width ? width : '10'} text-green-500`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.2858 6.00002L9.00001 17.2858L3.7143 12L2.30109 13.4142L9.00001 20.1131L21.7 7.41422L20.2858 6.00002Z" fill="currentColor"/>
                        </svg>
                    ) : (
                        // Otherwise, display the loading spinner
                        <svg className={`animate-spin h-${height ? height : '10'} w-${width ? width : '10'} text-gray-600`} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    )
                }
            </div>
        </div>
    )
}