import { useTheme } from "../../providers";
import { MainTemplate } from "../../templates";

export const Main = (props: {
  isSidebarOpen: boolean;
  layoutSize: { open: string; close: string };
}) => {
  const { isSidebarOpen, layoutSize } = props;
  const { appTheme } = useTheme();

  return (
    <main
      className={`flex h-full max-w-full overflow-hidden flex-col flex-1 relative ${
        isSidebarOpen ? layoutSize.open : layoutSize.close
      }`}
    >
      
      <MainTemplate />
      {/* <div className="box">
  <div className="header">
    <div className="part_one"></div>
    <div className="part_two"></div>
  </div>
  <div className="content">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </div>
</div> */}
    </main>
  );
};
