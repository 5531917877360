/*
      <rightModal.Modal position="right-3 top-14" ><ChatGPTModalParams /></rightModal.Modal>
*/

import { useEffect, useRef } from "react";
import { useChatGPT } from "../providers";
import { ChatGPTModalChoiceModal } from "./ChatGPTModalModelChoice";
import { ChatGPTModalParameters } from "./ChatGPTModalParameters";
import { ChatGPTModalDocument } from "./ChatGPTModalDocument";
import { ChatGPTMiddleModalDocuments } from "./ChatGPTMiddleModalDocuments";
import { ChatGPTModalSearchPrompts } from "./ChatGPTModalSearchPrompt";

export const ChatGPTModal = ({ }) => {
    const { modalStatus, setModalStatus, gptInput } = useChatGPT()
    const modalRef = useRef<HTMLDivElement | null>(null);
  
  
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            setModalStatus("CLOSE");
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  

    if (modalStatus == "CLOSE") {
        return <></>
    } else if (modalStatus == "MODEL") {
        return (

            <>
                <div className={`absolute z-20 w-full h-full`}></div>
                <div className={`absolute z-30 left-3 top-14 bg-opacity-25`}>
                    <div ref={modalRef} className={`left-3 top-14 bg-white rounded-2xl shadow-lg border p-2`}>
                        <ChatGPTModalChoiceModal />
                    </div>
                </div>
            </>
        );
    } else if (modalStatus == "PARAMS") {
        return (
            <>
                <div className={`absolute z-20 w-full h-full`}></div>
                <div style={{background: "rgba(0, 0, 0, 0.25)"}} className={`absolute z-30 inset-0 flex justify-center items-center bg-opacity-25`}>
                    <div  ref={modalRef} className={`inset-0 flex justify-center items-center bg-white rounded-2xl shadow-lg border p-2`}>
                        <ChatGPTModalParameters />
                    </div>
                </div>
            </>
        )
    }  else if (modalStatus == "DOCUMENT_OPT") {
        return (
            <>
                <div className={`absolute z-20 w-full h-full`}></div>
                <div style={{ left: `${gptInput.chatGPTInputPosition.x}px`, bottom: `${gptInput.chatGPTInputPosition.height}px`, }} className={`absolute z-30 bg-opacity-25`}>
                    <div ref={modalRef} className={` bg-white rounded-2xl shadow-lg border p-2`}>
                        <ChatGPTModalDocument />
                    </div>
                </div>
            </>
        )
    }  else if (modalStatus == "TRANSLATOR") {
        return (
            <>
                <div className={`absolute z-20 w-full h-full`}></div>
                <div style={{background: "rgba(0, 0, 0, 0.25)"}} className={`absolute z-30 inset-0 flex justify-center items-center bg-opacity-25`}>
                    <div  ref={modalRef} className={`inset-0 flex justify-center items-center bg-white rounded-2xl shadow-lg border p-2`}>
                        <ChatGPTMiddleModalDocuments />
                    </div>
                </div>
            </>
        )
    } else if (modalStatus == "SEARCH_PROMPT") {
        return (
            <>
                <div className={`absolute z-20 w-full h-full`}></div>
                <div style={{background: "rgba(0, 0, 0, 0.25)"}} className={`absolute backdrop-blur-sm z-30 inset-0 flex justify-center items-start bg-opacity-25`}>
                    <div  ref={modalRef} className={`inset-0 w-[60%] max-h-[400px] overflow-y-scroll no-scrollbar flex justify-center items-start mt-[15%] bg-white rounded-2xl shadow-lg border p-2`}>
                       <ChatGPTModalSearchPrompts/>
                    </div>
                </div>
            </>
        )
    }
     else {
        return <></>
    }
};