import React, { useEffect, useRef, useState } from "react";
import { useChatGPT } from "../providers";

export const  ChatGPTContextTemplate = ({}) => {
    const { gptConfig } = useChatGPT()
    const [isNestedDropdownOneOpen, setIsNestedDropdownOneOpen] = useState(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [selectedTheme, setSelectedTheme] = useState<string>('');
    const [selectedValue, setSelectedValue] = useState<string>('');


    const toggleNestedDropdownOne = () => {
        setIsNestedDropdownOneOpen(!isNestedDropdownOneOpen);
    }

    useEffect(() => {
        const keys = Object.keys(gptConfig.contextGPTTemplate);
        if (keys.length > 0) {
            const firstKey = keys[0];
            setSelectedTheme(firstKey);
            setSelectedValue(gptConfig.contextGPTTemplate[firstKey]);
        }
    }, [gptConfig.contextGPTTemplate]);

    const copyTextToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(selectedValue);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
            setIsCopied(false);
        }
    };

    return(
        <div className="w-full whitespace-nowrap rounded-xl text-left md:whitespace-normal">
            <div className={`flex flex-col gap-3`}>
                <div className={`flex flex-col gap-2 z-20 transition-all ease-in-out duration-500`}>
                    <div className="">
                        <button ref={buttonRef} onClick={toggleNestedDropdownOne} className="flex justify-between items-center text-left p-1.5 text-black bg-transparent rounded-xl shadow border border-gray-200 focus:outline-none cursor-pointer w-full">
                            <span className="text-black">{selectedTheme}&nbsp;</span>
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isNestedDropdownOneOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}></path>
                            </svg>
                        </button>
                        {isNestedDropdownOneOpen && (
                            <div className="absolute z-20 overflow-y-auto max-h-[200px] no-scrollbar rounded-md shadow-lg bg-white" style={{ width: buttonRef.current?.offsetWidth }}>
                                <ul className="text-[#2E2E2E]">
                                {Object.keys(gptConfig.contextGPTTemplate).filter((key) => key !== selectedTheme).map((key, index) => (
                                        <li
                                            key={index}
                                            className="hover:bg-[#ececec] cursor-pointer px-4 py-2 border-b border-gray-200 text-sm list-none"
                                            onClick={() => {
                                                setSelectedTheme(key);
                                                setSelectedValue(gptConfig.contextGPTTemplate[key]);
                                                toggleNestedDropdownOne();
                                            }}
                                        >
                                            {key}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className=" p-2 bg-[#ececec] rounded relative">
                    {isCopied ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="absolute top-0 right-0 m-2 cursor-pointer">
                            <polyline points="20 6 9 17 4 12"></polyline> {/* Checked icon */}
                        </svg>
                    ) : (
                        <svg onClick={copyTextToClipboard} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="absolute top-0 right-0 m-2 cursor-pointer">
                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path> {/* Copy icon */}
                        </svg>
                    )}
                    <p className="text-black p-2 pt-5 text-sm text-justify ">
                            {selectedValue}
                    </p>
                </div>
            </div>
        </div>
    )
}