// src/utils/loadFonts.ts
export const loadFonts = (fonts: { [key: string]: { name: string, path?: string } }) => {
  Object.values(fonts).forEach(font => {
    if (font.path) {
      const fontFace = new FontFace(font.name, `url(${font.path})`);
      fontFace.load().then(() => {
        document.fonts.add(fontFace);
        console.log(`Font ${font.name} loaded successfully.`);
      }).catch(err => {
        console.error(`Failed to load font ${font.name}:`, err);
      });
    } else {
      console.log(`Font ${font.name} does not have a path and is assumed to be a web-safe font.`);
    }
  });
};
