import React from "react";
import { useDropzone } from "react-dropzone";
import { PDFDocument } from 'pdf-lib';
import { translations } from "../../translations";
interface FileDropzoneProps {
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
  setAvailableOptions: React.Dispatch<React.SetStateAction<string[]>>;
  mapOpt: { [key: string]: string[] };
  fileName: string;
  docTypes: string[];
  language: string
}
 
const FileDropzone: React.FC<FileDropzoneProps> = ({
  setFile,
  setFileName,
  setAvailableOptions,
  mapOpt,
  fileName,
  docTypes,
  language
}) => {
  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      let file = acceptedFiles[0];
      const fileNameParts = file.name.split('.');
      const fileExtension = fileNameParts.pop()?.toLowerCase();
      const baseFileName = fileNameParts.join('.').replaceAll('.', '-');
      const modifiedFileName = `${baseFileName}.${fileExtension}`;
      file = new File([file], modifiedFileName, {type: file.type});
  
      setFile(file);
      setFileName(modifiedFileName);

      if (fileExtension && mapOpt[fileExtension]) {
        setAvailableOptions(mapOpt[fileExtension]);
      } else {
        setAvailableOptions([]);
      }
    }
  };  

  const translationTexts = translations['doc_translator'][language as keyof typeof translations['doc_translator']];
 
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [".pptx"],
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"]
    },
    onDrop,
    maxFiles: 1,
  });
 
  return (
    <div
      {...getRootProps()}
      className={`dropzone border-dashed border-2 border-buttonBorder s${fileName && "border-text-primary-light"
        } rounded p-4 text-center cursor-pointer w-full mb-2`}
    >
      <input {...getInputProps()} className="border border-inputBorder" />
      <p className="text-[#232323]">
      {translationTexts.dropzone_text}
      </p>
      <p className="text-sm text-textSecondary mt-2">
        {translationTexts.dropzone_accepted_file} {docTypes.map((type) => type.toUpperCase()).join(", ")}
      </p>
      {fileName && (
        <p className="text-sm text-textSecondary mt-2">
          {translationTexts.dropzone_selected_file} {fileName.substring(0,25) + "..."}
        </p>
      )}
    </div>
  );
};
 
export default FileDropzone;
