import { useState, useEffect } from "react";
import FileDropzone from "./FileDropzone";
import {
  translateDocument,
  useDocumentUploader,
  useSessionId,
  useTranslationConfig,
} from "../../hooks";
import { SelectOptions } from "./SelectedOptions";
import { useAuth, useCredit } from "../../providers";
import { LoaderComponent } from "../../components";
import { forbiddenApps } from "../../constants/constant";
import { useTypingEffect } from "../../hooks/typingEffect";
import './Translator.css'
import { translations } from "../../translations";

export const DocumentTranslator = () => {
  const [file, setFile] = useState<File | null>(null);
  const [method, setMethod] = useState<string>("");
  const [loaderTranslation, setLoaderTransaltion] = useState<boolean>(false)
  const sessionId = useSessionId(`/api/translation/clear_session`);
  const { token,language } = useAuth()

  const {
    loading: configLoading,
    languages,
    docTypes,
    mapOpt,
    setMapOpt,
    setDocTypes,
  } = useTranslationConfig({ sessionId });
  const {
    loading: documentUploaderLoading,
    usage,
    error,
  } = useDocumentUploader({ file, method, sessionId });

  const [fileName, setFileName] = useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [sourceLanguage, setSourceLanguage] = useState<string>("");
  const [availableOptions, setAvailableOptions] = useState<string[]>([]);
  const [price, setPrice] = useState<number | null>(null);
  const { remainingCredit, setRemainingCredit } = useCredit()
  const [errorTranslation, setErrorTranslation] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const translationTexts = translations['doc_translator'][language as keyof typeof translations['doc_translator']];
  const [typedText] = useTypingEffect(translationTexts.welcome_message, 40)

  useEffect(() => {
    if (mapOpt && Object.entries(mapOpt).length > 0 && typeof remainingCredit === 'number' && remainingCredit <= 0) {
        const filteredMapOpt: { [key: string]: string[] } = Object.keys(mapOpt)
            .filter(key => key === 'pdf' || key === 'pptx' || key === 'docx')
            .reduce((obj, key) => {
                obj[key] = mapOpt[key]?.filter(value => value === 'GoogleTranslate') ?? [];
                return obj;
            }, {} as { [key: string]: string[] });

            if (JSON.stringify(filteredMapOpt) !== JSON.stringify(mapOpt)) {
              setMapOpt(filteredMapOpt);
            }
    }
  }, [mapOpt, remainingCredit]);

  useEffect(()=>{
    if (docTypes && docTypes.length > 0 && typeof remainingCredit === 'number' && remainingCredit <= 0) {
      const filteredDocTypes = docTypes.filter(type => type !== 'pdf');
      if (JSON.stringify(filteredDocTypes) !== JSON.stringify(docTypes)) {
        setDocTypes(filteredDocTypes);
      }
    }
  },[docTypes])

  useEffect(() => {
    if (usage) {
      setPrice(usage['usage']);
      if (method != "ChatGPT") {
        setPageNumber(usage['pages'])
      } else {
        setPageNumber(0)
      }
    }
  }, [usage]);

  useEffect(() => {
    setMethod("");
    setPrice(null);
    setPageNumber(0)
  }, [file]);

  
  useEffect(() => {
    if ((method == "TranslationHub" && pageNumber > 20) || pageNumber > 40) setErrorTranslation(true)
    else setErrorTranslation(false)
  }, [pageNumber, method])

  const initiateTranslation = async () => {
    if(typeof remainingCredit === 'number' && remainingCredit <= 0 && method !== 'GoogleTranslate') return
    setLoaderTransaltion(true)
    try {
      await translateDocument({ file, sessionId, selectedLanguage, method, token, sourceLanguage });
      if (price && remainingCredit) {
        const newCredit = remainingCredit - price
        setRemainingCredit(newCredit)
      }
    } catch (error) {
      console.log(error)
    }
    setLoaderTransaltion(false)
  };
  return (
    <LoaderComponent loadingConfig={configLoading}>
    <div className="flex flex-col h-screen items-center">
        <div className="flex flex-col text-md text-[#0D3D62] px-10 pb-7 gap-4">
          <div className="w-full flex" >
            <div className="flex flex-col w-full bg-welcomeBackground rounded-bl-[250px] rounded-br-[250px] p-5">
              <div className="flex flex-row w-full justify-center">
                <p className="text-textPrimary font-secondary text-4xl">{typedText}</p>
              </div>
              <div className="flex gap-1 flex-col bg-transparent p-6 w-full rounded-lg justify-center items-center text-sm text-textPrimary text-center overflow-hidden">
                <p className="w-[70%]">
                  {translationTexts.description_app}
                </p>
                <br />
                <p className="w-[70%]">
                  {translationTexts.description_app2}                
                </p>
                <p className="w-[70%]">
                  <span className="font-bold">{translationTexts.description_app3}</span>  {translationTexts.description_app4}
                </p>
                <p className="w-[70%]">
                  <span className="font-bold">{translationTexts.description_app5}</span> {translationTexts.description_app6}
                </p>
                {/* <p className="">
                  If your document exceeds the 20-page limit, please don't hesitate to contact the Smart Data Hub team. We'll be happy to assist you with your request.
                </p> */}
                {/* <p className="">
                  Thank you for your continued support and understanding. Feel free to reach out if you have any questions or feedback.
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center w-[60%] border border-buttonBorder p-4 rounded-lg shadow-lg hover:shadow-2xl">
          <FileDropzone
            setFile={setFile}
            setFileName={setFileName}
            setAvailableOptions={setAvailableOptions}
            mapOpt={mapOpt}
            fileName={fileName}
            docTypes={docTypes}
            language={language}
          />
          {fileName != "" && availableOptions.length > 0 && (
            <SelectOptions
              defaultValue={translationTexts.select_method}
              options={availableOptions}
              renderOption={(method) => ({ value: method, label: method })}
              selectedValue={method}
              onChange={(e) => setMethod(e.target.value)}
            />

          )}
          {
            method == "GoogleTranslate" &&
            <SelectOptions
              defaultValue={translationTexts.select_original_language}
              options={Object.entries(languages)}
              renderOption={(language) => ({
                value: language[0],
                label: language[1],
              })}
              selectedValue={sourceLanguage}
              onChange={(e) => setSourceLanguage(e.target.value)}
            />
          }
          {method != "" &&
            <SelectOptions
              defaultValue={translationTexts.select_language}
              options={Object.entries(languages)}
              renderOption={(language) => ({
                value: language[0],
                label: language[1],
              })}
              selectedValue={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
            />
          }
          {method != "" && selectedLanguage != "" && fileName != "" &&
            <button
              className={`mt-4 w-[70%] bg-buttonBg hover:bg-buttonHoverBg border border-buttonBorder hover:text-buttonHoverText text-buttonText py-2 px-4 rounded`}
              onClick={initiateTranslation}
              disabled={loaderTranslation || errorTranslation}
            >
              {errorTranslation ? translationTexts.error_toomuch_pages: (loaderTranslation ? "Loading..." : `${translationTexts.submit_button} ${price != null ? " | " + Math.round(price) + " " + translationTexts.credit_text : ""}`)}
            </button>
          }
        </div>
    </div>
    </LoaderComponent>
  );
};