import React, { useState } from "react";
import { Folder, usePromptDrive } from "../../hooks/promptDrive";
import { useAuth } from "../../providers";
import { translations } from "../../translations";

interface ModalDeleteFolderProps {
    setShowFolderModal: (value: boolean) => void;
    setFolderDeleted: (value: boolean) => void;
    loader: boolean;
    folders: Folder[];
    selectedFolderName: string;
    language: string;
}

export const ModalDeleteFolder: React.FC<ModalDeleteFolderProps> = ({setShowFolderModal,folders,setFolderDeleted,selectedFolderName,language }) => {
    const [selectedFolder, setSelectedFolder] = useState(selectedFolderName || folders[0].name)
    const {token,tokenInfo} = useAuth()
    const {deleteFolder} = usePromptDrive(token)
    const [loader,setLoader] = useState<boolean>(false)
    const translationTexts = translations['promptopedia'][language as keyof typeof translations['promptopedia']];


    const deleteFolderAndPrompts = async()=>{
        setLoader(true)
        await deleteFolder(selectedFolder)
        setShowFolderModal(false)
        setFolderDeleted(true)
        setLoader(false)
    }
 
    return (
        <div className="absolute backdrop-blur-md inset-0 z-9999 flex justify-center items-center" style={{ zIndex: 9 }}>
            <div className="flex flex-col gap-4 w-[50%] h-fit items-center inset-0 border border-dropdownBorder bg-welcomeBackground bg-opacity-70 rounded-xl p-8 relative">
                <div 
                    className="p-1 absolute top-0 right-1 cursor-pointer transform transition-transform hover:rotate-180"
                    onClick={() => setShowFolderModal(false)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="white"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </div>
                <label className="text-textPrimary">{translationTexts.folder_text}</label>
                    <select
                        value={selectedFolder!}
                        onChange={(e)=>setSelectedFolder(e.target.value)}
                        className="w-full p-2 rounded-md text-sm bg-selectBg text-selectText mb-1 focus:outline-none"
                    >
                        {folders.filter((elem) => elem.created_by === tokenInfo?.email).map((option) => (
                            <option key={option.name} value={option.name}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                    <label className="text-textPrimary">{translationTexts.modal_delete_folder_warning}</label>
                <button
                    className="w-full bg-buttonBg text-buttonText rounded-md p-1 hover:bg-buttonHoverBg hover:text-buttonHoverText"
                    onClick={() => deleteFolderAndPrompts()}
                >
                    {loader ? translationTexts.modal_delete_folder_loader : translationTexts.modal_delete_folder_text}
                </button>
            </div>
        </div>
    );
}